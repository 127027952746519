import PropTypes from 'prop-types';
import { Page } from 'framework7-react';
import React, { useRef, useState } from 'react';

import { I18n } from 'Locales';
import { Fab } from 'Components';
import { mockFunction } from 'Helpers';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { ICON_COLOR, PAGE_NAMES, REGIONS } from 'Constants';
import { CameraCheck, Logout, Reupload, ThankYouGirl } from 'Icons';

import './style.scss';

const handleFileUpload = (uploadResultPicture, id, e) => {
  const { files } = e.target;
  if (!files?.length) return;
  uploadResultPicture({ file: files[0], id });
};

const handleExitFlow = ({ lastTransaction, updateApplicationProps }) => {
  updateApplicationProps({ lastTransaction: { ...lastTransaction, submittedByBa: false } });
  NavigationService.navigate({ name: PAGE_NAMES.CAMPAIGNS });
};

const BaThankYou = ({
  isSubmittedOffline = false,
  lastTransaction = {},
  lastTransactionId = '',
  pictureUploaded = false,
  signed = false,
  submittedByBa = false,
  updateApplicationProps = mockFunction,
  uploadResultPicture = mockFunction,
  user = {}
}) => {
  const inputFile = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const IconType = pictureUploaded ? (
    <CameraCheck fillColor={ICON_COLOR.WHITE} />
  ) : (
    <Reupload fillColor={ICON_COLOR.WHITE} />
  );

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <Page id="ba-thank-you">
      <ColumnView flex={1} height={'100%'}>
        <ColumnView className="align-center" marginTop={60} height={'100%'}>
          {isLoading ? <div className="loader" /> : <img src={ThankYouGirl} onError={setIsLoading.bind(null, true)} />}
          <div className="pt-24 pb-16">
            <span className="title">{I18n.t('flow:congratulations')}</span>
          </div>
          <ColumnView>
            <RowView gap={6} flexWrap={'wrap'} width={'65%'}>
              <span className="transaction-id">{lastTransactionId}</span>
              <span className="description">
                {I18n.t(`flow:${isSubmittedOffline ? 'successfullySavedOffline' : 'successfullySaved'}`)}
              </span>
            </RowView>
            {!signed && !submittedByBa && (
              <ColumnView>
                <span className="waiting-for-donor">{I18n.t('flow:waitingForDonorCompletion')}</span>
              </ColumnView>
            )}
          </ColumnView>
        </ColumnView>
      </ColumnView>

      <input
        type="file"
        ref={inputFile}
        className="input"
        onChange={handleFileUpload.bind(null, uploadResultPicture, lastTransactionId)}
        // we accept pdf as a hack to let user to upload a new photo(not from galery)
        accept="image/*, application/pdf"
      />

      {REGIONS.UK.includes(user.country) && (
        <Fab disabled={false} onClick={onButtonClick} position={'left-bottom'} slot={'fixed'}>
          {IconType}
        </Fab>
      )}

      <Fab
        disabled={false}
        onClick={handleExitFlow.bind(null, { lastTransaction, updateApplicationProps })}
        position={'right-bottom'}
        slot={'fixed'}>
        <Logout fillColor={ICON_COLOR.WHITE} />
      </Fab>
    </Page>
  );
};

BaThankYou.propTypes = {
  isSubmittedOffline: PropTypes.bool,
  lastTransaction: PropTypes.object,
  lastTransactionId: PropTypes.string,
  pictureUploaded: PropTypes.bool,
  signed: PropTypes.bool,
  submittedByBa: PropTypes.bool,
  updateApplicationProps: PropTypes.func,
  uploadResultPicture: PropTypes.func,
  user: PropTypes.object
};

export default BaThankYou;
