import axios from 'axios';

const extraHeaders = {};

const defaultConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

const api = axios.create(defaultConfig);

const Api = {
  API_CALL: 'API_CALL',
  API_ERROR: 'API_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',

  auth_token: token => {
    if (token) {
      extraHeaders['Authorization'] = `Token ${token}`;
    } else {
      delete extraHeaders['Authorization'];
    }
  },

  catchError: () => {}, //error

  testMinVersion() {
    // {headers}
    // if (headers && headers['x-min-version-code']) {
    //   if (
    //     parseInt(headers['x-min-version-code']) >
    //     parseInt(Config.APP_VERSION_CODE)
    //   ) {
    //     const { application } = store().store.getState();
    //     const lastRoute =
    //       application.stackRoute[application.stackRoute.length - 1];
    //     if (lastRoute !== 'UpgradeApp') {
    //       NavigationService.navigate({ routeName: 'UpgradeApp' });
    //     }
    //   }
    // }
  },

  get: url => {
    const request = api.get(url, { headers: extraHeaders });
    request.catch(Api.catchError);
    return request;
  },

  post: (url, data) => {
    const headers = { ...defaultConfig.headers, ...extraHeaders };

    if (data instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }

    const request = api.post(url, data, { headers: headers });
    request.catch(Api.catchError);
    return request;
  },

  put: (url, data) => {
    const request = api.put(url, data, { headers: extraHeaders });
    request.catch(Api.catchError);
    return request;
  },

  patch: (url, data) => {
    const request = api.patch(url, data, { headers: extraHeaders });
    request.catch(Api.catchError);
    return request;
  },

  delete: url => {
    const request = api.delete(url, { headers: extraHeaders });
    request.catch(Api.catchError);
    return request;
  }
};

export default Api;
