import { connect } from 'react-redux';

import { Creators as transientActions } from 'Reducers/transient';
import { Creators as applicationActions } from 'Reducers/application';

import SignUp from './view';

export default connect(
  state => ({
    application: state.application,
    transient: state.transient
  }),
  {
    activateAccount: applicationActions.activateAccount,
    postConfirmationToken: applicationActions.postConfirmationToken,
    updateAplicationProps: applicationActions.updateProps,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations
  }
)(SignUp);
