import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { createStore, compose, applyMiddleware } from 'redux';

import sagas from 'Sagas';
import { apiMiddleware } from 'Core';
import { storeConfig } from './config';
import rootReducer from './rootReducer';
import { Creators as StartupActions } from 'Reducers/application';

const loggerMiddleware = createLogger({
  collapsed: true
});

const sagaMiddleware = createSagaMiddleware();

const enhancer = compose(applyMiddleware(apiMiddleware, sagaMiddleware, loggerMiddleware));

const persistedReducer = persistReducer(storeConfig, rootReducer);
const store = createStore(persistedReducer, enhancer);
const persistor = persistStore(store, {}, () => {
  //rehydrationComplete();
  //store.dispatch();
  store.dispatch(StartupActions.persisted());
});

sagaMiddleware.run(sagas);

export default () => ({ store, persistor });
