import Bank from './Bank';
import General from './General';
import Contact from './Contact';
import Identity from './Identity';
import Documents from './Documents';

export { Bank, Contact, Documents, General, Identity };

export default {
  Bank,
  Contact,
  Documents,
  General,
  Identity
};
