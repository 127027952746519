import { colors } from 'Theme';

export default {
  blankImage: {
    backgroundColor: colors.standard,
    border: '1px solid black',
    borderRadius: '6px'
  },
  centerImage: {
    display: 'flex',
    margin: '6px auto'
  },
  redBorder: {
    borderColor: colors.error
  },
  singatureSheetStyle: {
    height: 'auto',
    '--f7-sheet-bg-color': '#fff'
  }
};
