import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Block, List, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { Button, TextField } from 'Components';
import { NavigationService } from 'Services';
import { RowView, ColumnView } from 'Containers';
import { handleOnChangeWithValidations, mockFunction, validations } from 'Helpers';

const navigateToFinishPage = () => {
  NavigationService.navigate({
    name: 'Finish'
  });
};

const updateUserBirthday = ({ updateUser, date_of_birth }) => {
  const newUser = {
    data: {
      attributes: {
        date_of_birth
      }
    }
  };
  updateUser(newUser, navigateToFinishPage);
};

const Birthday = ({ transient, updateTransientPropWithValidations, updateUser }) => {
  const { date_of_birth = '', date_of_birth_has_error, date_of_birth_error_message } = transient;
  const disableButton = date_of_birth_has_error || !date_of_birth;

  return (
    <Page>
      <ColumnView height={'100%'}>
        <ColumnView className="align-center" marginTop={60}>
          <RowView paddingHorizontal={16}>
            <div className="swiper-title">{I18n.t('general:birthday')}</div>
          </RowView>
          <ColumnView>
            <List form className="w-full">
              <TextField
                className="custom-date-input"
                label={I18n.t('inputs:dateOfBirth.label')}
                type="date"
                max={moment().format('YYYY-MM-DD')}
                defaultValue={date_of_birth}
                onChange={handleOnChangeWithValidations.bind(
                  null,
                  updateTransientPropWithValidations,
                  'date_of_birth',
                  [validations.isRequired, validations.maxDateValidation]
                )}
                errorMessageForce={date_of_birth_has_error}
                errorMessage={date_of_birth_error_message}
                autofocus
                validate
                validateOnBlur
              />
            </List>
          </ColumnView>
        </ColumnView>
        <ColumnView marginTop={'auto'} marginBottom={90}>
          <Block className="w-full">
            <Button.Primary
              className="text-md"
              width={'100%'}
              disabled={disableButton}
              onClick={updateUserBirthday.bind(null, {
                updateUser,
                date_of_birth
              })}>
              {I18n.t('general:next')}
            </Button.Primary>
          </Block>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

Birthday.propTypes = {
  transient: PropTypes.object,
  updateUser: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func
};

Birthday.defaultProps = {
  transient: {},
  updateUser: mockFunction,
  updateTransientPropWithValidations: mockFunction
};

export default Birthday;
