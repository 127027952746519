import { createActions, createReducer } from 'reduxsauce';

import { updateProp } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    updateProp: ['key', 'value'],
    alert: ['body', 'client', 'kind', 'persist', 'title', 'buttons', 'rest']
  },
  { prefix: 'growl/' }
);

const initialState = {
  body: '',
  buttons: [],
  client: null,
  kind: '',
  title: '',
  visible: false,
  rest: null
};

export const alert = (state, action) => ({
  ...state,
  body: action.body || '',
  buttons: action.buttons || [],
  client: action.client,
  kind: action.kind || 'info',
  title: action.title || '',
  visible: true,
  rest: action.rest || null
});

export default createReducer(initialState, {
  [Types.UPDATE_PROP]: updateProp,
  [Types.ALERT]: alert
});
