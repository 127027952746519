import { NavigationService } from 'Services';
import { Creators as ApplicationCreators } from 'Reducers/application';

export const onOffline = () => {
  NavigationService.dispatch(
    ApplicationCreators.updateProps({
      isOffline: true,
      offlineSince: Date.now()
    })
  );
};
