import React from 'react';
import PropTypes from 'prop-types';

const Home = ({ fillColor = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 15 16">
    <path
      fill={fillColor}
      stroke="#747474"
      strokeWidth="0.2"
      //eslint-disable-next-line no-useless-escape, max-len
      d="M1.167 14.692h3.692V9.615c0-.229.077-.42.232-.575a.781.781 0 0 1 .575-.232h3c.23 0 .421.077.576.232a.781.781 0 0 1 .232.575v5.077h3.692V6a.578.578 0 0 0-.067-.279.75.75 0 0 0-.183-.221L7.532 1.442a.537.537 0 0 0-.365-.134.537.537 0 0 0-.366.134L1.416 5.5a.75.75 0 0 0-.182.221.579.579 0 0 0-.067.279v8.692Zm-1 0V6a1.604 1.604 0 0 1 .646-1.292L6.197.63c.282-.216.604-.323.966-.323.363 0 .687.107.973.323l5.384 4.077A1.604 1.604 0 0 1 14.166 6v8.692a.96.96 0 0 1-.299.701.96.96 0 0 1-.7.3H9.281a.782.782 0 0 1-.576-.233.781.781 0 0 1-.232-.575V9.808H5.86v5.077c0 .228-.078.42-.232.575a.782.782 0 0 1-.576.232H1.166a.96.96 0 0 1-.7-.299.96.96 0 0 1-.3-.7Z"
    />
  </svg>
);

export default Home;

Home.propTypes = {
  fillColor: PropTypes.string
};
