import { connect } from 'react-redux';

import { Creators as GrowlActions } from 'Reducers/growl';
import { Creators as FlowActions } from 'Reducers/flow';

import Growl from './view';

export default connect(
  state => ({
    ...state.growl
  }),
  {
    updateProp: GrowlActions.updateProp,
    saveFlowForOffline: FlowActions.saveFlowForOffline,
    initSubmitFlow: FlowActions.initSubmitFlow
  }
)(Growl);
