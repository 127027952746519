//TODO improve this
import { Api } from 'Core';
import { dataFormatter } from 'Services';
import { Types as ApplicationTypes } from 'Reducers/application';

const tokenSetActions = [
  ApplicationTypes.BACKEND_LOGIN_SUCCESSFUL,
  ApplicationTypes.DONOR_LOGIN_SUCCESSFUL,
  ApplicationTypes.ACTIVATE_ACCOUNT_SUCCESS
];

export default function apiMidleware() {
  return next => action => {
    const { type, actions = {}, promise, ...rest } = action;

    if (type !== Api.API_CALL) {
      return next(action);
    }

    const { success, fail } = actions;

    return promise
      .then(payload => {
        let payloadData = {};
        switch (payload.status) {
          case 200:
            payloadData = payload.data;
            break;
          default:
        }
        payload.data = payloadData;

        if (success && tokenSetActions.includes(success.type)) {
          Api.auth_token(payload.data?.data?.attributes?.auth_token || payload.data?.auth_token);
        }

        if (success && success.type) {
          const { meta, type, ...successRest } = success;
          if (payload.data !== '' && payload.data.data) {
            // jsonapi
            const result = dataFormatter.deserialize(payload.data);
            next({ ...rest, ...successRest, payload: result, meta, type });
          } else {
            next({
              ...rest,
              ...successRest,
              payload: payload.data,
              meta,
              type
            });
          }
        }
      })
      .catch(error => {
        if (!fail || !fail.type) {
          // HANDLE DEFAULT API ERROR
          const handleDefaultAPIError = [0, 401, 403, 404, 422, 500, 'TIMEOUT'];
          if (handleDefaultAPIError.includes(error.status)) {
            next({
              ...rest,
              type: Api.API_ERROR,
              error: {
                ...error,
                responseType: Api.NETWORK_ERROR
              },
              ...fail
            });
          }
        } else {
          const { type: failType, ...failExtraPayload } = fail;
          // HANDLE CUSTOM ERROR IF APPLICABLE
          const response = error?.xhr?.response ?? {};
          next({
            ...rest,
            type: failType,
            error: {
              ...error,
              messages: response.errors,
              title: response.message,
              responseType: Api.NETWORK_ERROR
            },
            ...failExtraPayload
          });
        }
      });
  };
}
