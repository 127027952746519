/*
justifyContent  - vertical alignment / primary axis
alignItems      - horizontal alignment / secondary axis
*/

import React from 'react';
import PropTypes from 'prop-types';

import style from './style';

const ColumnRowView = props => {
  let composedStyles = {
    alignItems: props.alignItems,
    alignSelf: props.alignSelf,
    backgroundColor: props.backgroundColor,
    display: props.display,
    flex: props.flex,
    flexBasis: props.flexBasis,
    flexDirection: props.flexDirection,
    flexGrow: props.flexGrow,
    flexWrap: props.flexWrap,
    gap: props.gap,
    height: props.height,
    justifyContent: props.justifyContent,
    marginBottom: props.marginBottom || props.marginVertical || 0,
    marginLeft: props.marginLeft || props.marginHorizontal || 0,
    marginRight: props.marginRight || props.marginHorizontal || 0,
    marginTop: props.marginTop || props.marginVertical || 0,
    maxHeight: props.maxHeight,
    maxWidth: props.maxWidth,
    minHeight: props.minHeight,
    minWidth: props.minWidth,
    paddingBottom: props.paddingBottom || props.paddingVertical || 0,
    paddingLeft: props.paddingLeft || props.paddingHorizontal || 0,
    paddingRight: props.paddingRight || props.paddingHorizontal || 0,
    paddingTop: props.paddingTop || props.paddingVertical || 0,
    width: props.width
  };
  if (props.shadow) {
    composedStyles = {
      ...composedStyles,
      ...style.boxShadow
    };
  }

  return (
    <div id={props.id} onClick={props.onClick} style={composedStyles}>
      {props.children}
    </div>
  );
};

ColumnRowView.propTypes = {
  alignItems: PropTypes.string,
  alignSelf: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  display: PropTypes.string,
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flexBasis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flexDirection: PropTypes.string,
  flexGrow: PropTypes.number,
  flexWrap: PropTypes.string,
  gap: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  justifyContent: PropTypes.string,
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginHorizontal: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginVertical: PropTypes.number,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  paddingBottom: PropTypes.number,
  paddingHorizontal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingVertical: PropTypes.number,
  shadow: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

ColumnRowView.defaultProps = {
  alignItems: 'center',
  alignSelf: 'center',
  backgroundColor: 'transparent',
  children: null,
  display: 'flex',
  flex: 1,
  flexBasis: 'auto',
  flexDirection: 'column',
  flexGrow: 0,
  flexWrap: 'initial',
  gap: 0,
  height: 'auto',
  id: undefined,
  justifyContent: 'center',
  marginBottom: undefined,
  marginHorizontal: undefined,
  marginLeft: undefined,
  marginRight: undefined,
  marginTop: undefined,
  marginVertical: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  minHeight: undefined,
  minWidth: undefined,
  onClick: undefined,
  paddingBottom: undefined,
  paddingHorizontal: undefined,
  paddingLeft: undefined,
  paddingRight: undefined,
  paddingTop: undefined,
  paddingVertical: undefined,
  shadow: false,
  width: '100%'
};

export default ColumnRowView;
