import React from 'react';
import PropTypes from 'prop-types';

import { BaThankYou, DonorThankYou } from './subviews';

const thankYouType = {
  donor: <DonorThankYou />,
  agent: <BaThankYou />
};

const ThankYou = ({ mode = '' }) => {
  const ThankYouPage = thankYouType[mode] || <BaThankYou />;

  return ThankYouPage;
};

ThankYou.propTypes = {
  mode: PropTypes.string
};

export default ThankYou;
