import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const badgeColorState = {
  blue: 'badge-blue',
  gray: 'badge-gray'
};

const BadgeIcon = ({ badgeColor, children }) => (
  <div className={`badge-icon ${badgeColorState[badgeColor]}`}>{children}</div>
);
BadgeIcon.propTypes = {
  badgeColor: PropTypes.string,
  children: PropTypes.any
};

BadgeIcon.defaultProps = {
  badgeColor: 'gray',
  children: null
};

export default BadgeIcon;
