import Config from 'Config';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './style.scss';

const DynamicLogo = props => {
  const { height, name, logo } = props;
  const [hasError, setHasError] = useState(false);

  return hasError ? (
    <h3>{name?.toUpperCase()}</h3>
  ) : (
    <img
      onError={setHasError.bind(null, true)}
      //TODO remove CLIENT_LOGO_ENDPOINT after the client add logo for their campaigns
      src={logo !== '' ? logo : `${Config.CLIENT_LOGO_ENDPOINT}${name}${Config.CLIENT_LOGO_EXTENSION}`}
      className="img-logo"
      height={height}
    />
  );
};

DynamicLogo.propTypes = {
  height: PropTypes.number,
  logo: PropTypes.string,
  name: PropTypes.string
};

DynamicLogo.defaultProps = {
  height: 40,
  logo: '',
  name: ''
};

export default DynamicLogo;
