import { connect } from 'react-redux';

import * as Selectors from 'Selectors';
import { Creators as applicationActions } from 'Reducers/application';

import renderDonorThankYou from './view';

export default connect(
  state => ({
    fields: Selectors.thankYouPageFields(state),
    mode: state.application.mode
  }),
  {
    logout: applicationActions.logout
  }
)(renderDonorThankYou);
