import React from 'react';
import PropTypes from 'prop-types';
import { App as F7App, f7ready } from 'framework7-react';

import Config from 'Config';
import { routes } from 'Core';
import { I18n } from 'Locales';
import { initF7 } from 'Services';
import { Growl } from 'Components';
import { APP_MODES } from 'Constants';
import { checkSearchParams } from 'Helpers';
import { ColumnView, FullView, SafeAreaView } from 'Containers';
import { AppBottomBar, MainView, SplashScreen } from './subviews';

import style from './style';

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      f7params: {
        name: Config.APP_NAME,
        theme: Config.APP_THEME,
        routes: routes,
        id: Config.APP_ID
      }
    };
  }

  componentDidMount = () => {
    f7ready(this.initialiseF7);
  };

  componentDidUpdate = () => {
    const { locale } = checkSearchParams(window.location.search);
    I18n.changeLanguage(this.props.application.mode === APP_MODES.DONOR ? locale : this.props.application.locale);
  };

  initialiseF7 = f7 => {
    const { dispatch, initFrameworkSeven } = this.props;
    initF7(f7);
    dispatch(initFrameworkSeven());
  };

  render = () => {
    const { application, country = '' } = this.props;

    const countryUrls = {
      BE: Config.be,
      FR: Config.fr,
      IE: Config.ie,
      UK: Config.uk
    };

    if (Config.redirectToAgentV1 && countryUrls[country] && application.mode === APP_MODES.AGENT) {
      window.location.replace(`${countryUrls[country]}`);
    }

    return (
      <F7App {...this.state.f7params}>
        <FullView id={Config.APP_ID} style={style.appWrapper}>
          <SafeAreaView>
            <ColumnView height={'100%'}>
              <MainView />
              <Growl />
              <AppBottomBar />
            </ColumnView>
          </SafeAreaView>
        </FullView>
        {(!application.initialised || application.orientation === 'landscape') && (
          <FullView id={'SplashScreen'} style={style.splashScreen}>
            <SplashScreen orientation={application.orientation} />
          </FullView>
        )}
      </F7App>
    );
  };
}

App.propTypes = {
  application: PropTypes.object,
  clearSession: PropTypes.func,
  country: PropTypes.string,
  dispatch: PropTypes.func,
  initFrameworkSeven: PropTypes.func,
  locale: PropTypes.string,
  logout: PropTypes.func
};

export default App;
