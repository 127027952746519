import React from 'react';
import moment from 'moment';

import { I18n } from 'Locales';
import { Button } from 'Components';
import { RowView } from 'Containers';
import { capitalize, ibanMaskConfig } from 'Helpers';

const isDisabled = (config, readOnly) => (config ? config : readOnly);

const getFormattedValue = (config, value, transient) => {
  if (value === undefined) return '';
  if (config.iBanMaskValue) return ibanMaskConfig(value);
  if (config.fieldName === 'regions') return renderMultipleValues(config.selectTranslationKey, value);

  const translatedValue = config.translateValue
    ? I18n.t(`profile:contactTab.${transient?.[config.fieldName]}`) || ''
    : value;
  return config.capitalizeValue ? capitalize(translatedValue) || '' : translatedValue;
};

const renderSelectOptions = (userOptions, selectTranslationKey, fieldName) => {
  const firstLetterUpperCase = `select${fieldName?.charAt(0).toUpperCase()}${fieldName?.slice(1)}`;
  const defaultOption = fieldName
    ? I18n.t(`${selectTranslationKey}.${firstLetterUpperCase}`)
    : I18n.t('general:select');
  const options = userOptions.map((option, index) => (
    <option key={option.id || index} value={option}>
      {I18n.t(`${selectTranslationKey}.${option}`)}
    </option>
  ));

  options.unshift(
    <option key="default" value="" disabled selected={true}>
      {defaultOption}
    </option>
  );

  return options;
};

const renderMultipleValues = (selectTranslationKey, value = []) =>
  value?.map(value => I18n.t(`${selectTranslationKey}.${value}`)).join(', ');

const getDateRange = config => {
  const { dateRange } = config;

  if (dateRange && (dateRange.max === 'today' || dateRange.min === 'today')) {
    const today = moment().format('YYYY-MM-DD');
    return {
      min: dateRange.min === 'today' ? today : dateRange.min,
      max: dateRange.max === 'today' ? today : dateRange.max
    };
  }
  return dateRange && (dateRange.max || dateRange.min)
    ? {
        min: dateRange.min ? moment(dateRange.min).format('YYYY-MM-DD') : undefined,
        max: dateRange.max ? moment(dateRange.min).format('YYYY-MM-DD') : undefined
      }
    : undefined;
};

const updateUserChanges = (transient, updateTransientProps, updateUser) => {
  const filteredTransient = Object.keys(transient).reduce((acc, key) => {
    const errorFlagKey = `${key}_has_error`;
    if (!transient[errorFlagKey]) {
      acc[key] = transient[key];
    } else {
      updateTransientProps({ [errorFlagKey]: false });
    }
    return acc;
  }, {});

  const newUser = {
    data: {
      attributes: {
        ...filteredTransient,
        nationality: transient.nationality?.nationality,
        country_of_birth: transient.country_of_birth?.short_code,
        country_of_citizenship: transient.country_of_citizenship?.short_code
      }
    }
  };
  updateUser(newUser);
};

const getIconColor = (fieldHasError, hasFieldValue) => (fieldHasError ? 'red' : hasFieldValue ? 'blue' : 'gray');

const isRejectedField = (rejectedDetails, field) => rejectedDetails?.includes(field);

const renderProfileNavButtons = (isFirstPage, isLastPage, prevPage, nextPage, updateProp) => (
  <RowView gap={24} paddingHorizontal={20} paddingTop={20} paddingBottom={16}>
    {!isFirstPage && (
      <Button.Outline width={'100%'} onClick={updateProp.bind(null, 'currentPage', prevPage)}>
        {I18n.t('general:back')}
      </Button.Outline>
    )}
    {!isLastPage && (
      <Button.Primary width={'100%'} onClick={updateProp.bind(null, 'currentPage', nextPage)}>
        {I18n.t('general:next')}
      </Button.Primary>
    )}
  </RowView>
);

const handleChangeBankAccount = (validateIban, key, e) => {
  validateIban(key, e.target.value);
};

export {
  getDateRange,
  getFormattedValue,
  getIconColor,
  handleChangeBankAccount,
  isDisabled,
  isRejectedField,
  renderProfileNavButtons,
  renderMultipleValues,
  renderSelectOptions,
  updateUserChanges
};
