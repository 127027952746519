import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { TextField } from 'Components';
import { ColumnView, RowView } from 'Containers';
import { FormattedLabel, isSmallDevice } from 'Helpers';

import './style.scss';
import { bankComponentOnlineFields, getFieldProp, getIconColor } from '../../../shared';

const updateValues = (setValues, value, key, event) => {
  setValues(value, { [key]: event.target.value?.trimStart() });
};

class BankAccountUK extends Component {
  constructor(props) {
    super(props);
    this.accountNumberProp = getFieldProp(this.props.fields, 'accountNumber');
    this.bankNameProp = getFieldProp(this.props.fields, 'bankName');
    this.holderNameProp = getFieldProp(this.props.fields, 'holderName');
    this.sortCodeProp = getFieldProp(this.props.fields, 'sortCode');
  }

  componentDidUpdate = prevProps => {
    const { forceUpdate, value: bankAccountValues } = this.props;
    if (forceUpdate !== prevProps.forceUpdate) {
      for (const bankComponentOnlineField of bankComponentOnlineFields) {
        if (bankAccountValues[bankComponentOnlineField]?.length > 0) {
          this.setValues(bankAccountValues, {
            [bankComponentOnlineField]: bankAccountValues[bankComponentOnlineField]
          });
          return true;
        }
      }
    }
  };

  setValues = (oldProps, individualKeyValue) => {
    const initialState = {
      bankName: '',
      holderName: '',
      accountNumber: '',
      sortCode: '',
      validationStatus: '',
      validationMessage: ''
    };

    const newState = Object.assign(initialState, oldProps, individualKeyValue);

    this.props.updateValue({ value: newState, updatingKey: Object.keys(individualKeyValue)[0] });
  };

  render = () => {
    const { accountNumberProp, bankNameProp, holderNameProp, sortCodeProp, props } = this;
    const { required, shouldShowError, value } = props;

    let bankAccountValues = value;
    if (!bankAccountValues) {
      bankAccountValues = {
        bankName: '',
        holderName: '',
        accountNumber: '',
        sortCode: '',
        validationStatus: '',
        validationMessage: ''
      };
    }

    const { bankName, holderName, accountNumber, sortCode } = bankAccountValues;

    return (
      <ColumnView>
        <RowView>
          <TextField
            label={FormattedLabel({ required, name: I18n.t('flow:components.bankAccount.bankName') })}
            type={'text'}
            placeholder={bankNameProp?.placeholder}
            className={'input-style'}
            value={bankName}
            onChange={updateValues.bind(null, this.setValues, value, 'bankName')}
            icon="material:check"
            iconColor={getIconColor(false, bankName)}
          />
        </RowView>
        <RowView>
          <TextField
            label={FormattedLabel({ required, name: I18n.t('flow:components.bankAccount.holderName') })}
            type={'text'}
            placeholder={holderNameProp?.placeholder}
            className={'input-style'}
            value={holderName}
            onChange={updateValues.bind(null, this.setValues, value, 'holderName')}
            errorMessageForce={shouldShowError}
            errorMessage={I18n.t('flow:generalError')}
            required
            icon="material:check"
            iconColor={getIconColor(shouldShowError, holderName)}
          />
        </RowView>

        <RowView flexWrap={isSmallDevice ? 'wrap' : 'nowrap'}>
          <TextField
            label={FormattedLabel({ required, name: I18n.t('flow:components.bankAccount.accountNumber') })}
            type={'number'}
            placeholder={accountNumberProp?.placeholder}
            className={'input-style'}
            value={accountNumber}
            onChange={updateValues.bind(null, this.setValues, value, 'accountNumber')}
            onBlur={updateValues.bind(null, this.setValues, value, 'accountNumber')}
            validateOnBlur={true}
            errorMessageForce={shouldShowError}
            errorMessage={I18n.t('flow:generalError')}
            icon="material:check"
            iconColor={getIconColor(shouldShowError, accountNumber)}
          />

          <TextField
            label={FormattedLabel({ required, name: I18n.t('flow:components.bankAccount.sortCode') })}
            type={'number'}
            placeholder={sortCodeProp?.placeholder}
            className={'input-style'}
            value={sortCode}
            onChange={updateValues.bind(null, this.setValues, value, 'sortCode')}
            onBlur={updateValues.bind(null, this.setValues, value, 'sortCode')}
            validateOnBlur={true}
            errorMessageForce={shouldShowError}
            errorMessage={I18n.t('flow:generalError')}
            icon="material:check"
            iconColor={getIconColor(shouldShowError, sortCode)}
          />
        </RowView>
      </ColumnView>
    );
  };
}

BankAccountUK.propTypes = {
  fields: PropTypes.array,
  forceUpdate: PropTypes.number,
  required: PropTypes.bool,
  shouldShowError: PropTypes.bool,
  updateValue: PropTypes.func,
  value: PropTypes.any
};

export default BankAccountUK;
