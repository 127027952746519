import React from 'react';
import PropTypes from 'prop-types';

const Assignment = ({ fillColor = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <mask id="a" width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse" masktype="alpha">
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fillColor}
        stroke="#747474"
        strokeWidth="0.2"
        //eslint-disable-next-line no-useless-escape, max-len
        d="M2.401 3.401c-.333.334-.501.74-.501 1.214v12.77c0 .473.168.88.501 1.214l.071-.071-.07.07c.333.334.74.502 1.213.502h12.77c.473 0 .88-.168 1.214-.501l-.071-.071.07.07c.334-.333.502-.74.502-1.213V4.615c0-.473-.168-.88-.501-1.214l-.071.071.07-.07a1.657 1.657 0 0 0-1.213-.502h-4.582c.07-.493-.06-.944-.388-1.346C11.06 1.118 10.585.9 10 .9c-.573 0-1.041.219-1.396.654l.078.063-.078-.063c-.327.402-.463.852-.405 1.346H3.615c-.473 0-.88.168-1.214.501ZM5.4 15.27v.1H11.6V14.17H5.4v1.1Zm0-3.769v.1h9.2V10.4H5.4v1.1Zm0-3.77v.1h9.2V6.63H5.4v1.1ZM16.385 17.9H3.615a.489.489 0 0 1-.352-.163.489.489 0 0 1-.163-.352V4.615c0-.122.05-.239.163-.352a.489.489 0 0 1 .352-.163h12.77c.122 0 .239.05.352.163.113.113.163.23.163.352v12.77a.49.49 0 0 1-.163.352.49.49 0 0 1-.352.163ZM10.467 3.16a.629.629 0 0 1-.467.182.629.629 0 0 1-.65-.65.629.629 0 0 1 .65-.65.629.629 0 0 1 .65.65.629.629 0 0 1-.183.467Z"
      />
    </g>
  </svg>
);

export default Assignment;

Assignment.propTypes = {
  fillColor: PropTypes.string
};
