import React from 'react';
import PropTypes from 'prop-types';
import { Block, List, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { DataByteLogo } from 'Icons';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { Button, TextField, StrengthBar } from 'Components';
import { checkSearchParams, handleOnChangeWithValidations, mockFunction, validations } from 'Helpers';

import './style.scss';

const getIconColor = (fieldHasError, hasFieldValue) => (fieldHasError ? 'red' : hasFieldValue ? 'blue' : 'gray');

const updateUserPassword = ({ resetPassword, transient }) => {
  resetPassword({
    user: {
      password: transient.password,
      password_confirmation: transient.password_confirmation,
      reset_password_token: checkSearchParams(window.location.search).resetPasswordToken
    }
  });
};
const ResetPassword = ({ resetPassword, transient, updateTransientPropWithValidations }) => {
  const isPasswordMatching = {
    isValid: value => value === transient.password,
    message: I18n.t('validations:passwordDontMatch'),
    messageKey: 'validations:passwordDontMatch'
  };

  const hasError =
    transient?.password_has_error ||
    transient?.password_confirmation_has_error ||
    transient?.password !== transient?.password_confirmation ||
    !transient?.password ||
    !transient?.password_confirmation;

  return (
    <Page noToolbar noNavbar noSwipeback id={'reset-password'}>
      <ColumnView flex={1} minHeight={'100%'}>
        <ColumnView className="align-center" marginTop={60}>
          <img src={DataByteLogo} width={'35%'} height={'35%'} />
          <RowView>
            <List form className="w-full">
              <TextField
                label={I18n.t('settings:newPassword')}
                type="password"
                value={transient?.password || ''}
                onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'password', [
                  validations?.isRequired,
                  validations?.passwordValidation
                ])}
                errorMessageForce={transient?.password_has_error}
                errorMessage={I18n.t(transient?.password_error_message_key)}
                icon="material:check"
                iconColor={getIconColor(transient?.password_has_error, transient?.password)}
              />
              <TextField
                label={I18n.t('settings:confirmPassword')}
                type="password"
                value={transient?.password_confirmation || ''}
                onChange={handleOnChangeWithValidations.bind(
                  null,
                  updateTransientPropWithValidations,
                  'password_confirmation',
                  [validations?.isRequired, validations?.passwordValidation, isPasswordMatching]
                )}
                icon="material:check"
                iconColor={getIconColor(transient?.password_confirmation_has_error, transient?.password_confirmation)}
                errorMessageForce={transient?.password_confirmation_has_error}
                errorMessage={I18n.t(transient?.password_confirmation_error_message_key)}
              />
              <Block className="mt-0 mb-0">
                <StrengthBar password={transient?.password} />
                <div className={'password-requirement'}>{I18n.t('settings:passwordRequirement')}</div>
              </Block>
            </List>
          </RowView>
        </ColumnView>
        <ColumnView marginTop={'auto'}>
          <Block className="w-full mb-90">
            <Button.Primary
              disabled={hasError}
              onClick={updateUserPassword.bind(null, {
                resetPassword,
                transient
              })}>
              {I18n.t('general:changePassword')}
            </Button.Primary>
            <Button.Outline
              className="mt-16"
              onClick={NavigationService.navigate.bind(null, {
                name: 'Login'
              })}>
              {I18n.t('general:cancel')}
            </Button.Outline>
          </Block>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

ResetPassword.propTypes = {
  lastKnownAvatar: PropTypes.string,
  resetPassword: PropTypes.func,
  transient: PropTypes.object,
  updateTransientPropWithValidations: PropTypes.func
};

ResetPassword.defaultProps = {
  resetPassword: mockFunction,
  transient: {},
  updateTransientPropWithValidations: mockFunction
};

export default ResetPassword;
