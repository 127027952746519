import React from 'react';
import PropTypes from 'prop-types';
import { Searchbar as F7Searchbar } from 'framework7-react';

import { mockFunction } from 'Helpers';

const Searchbar = ({ onChange, onClickClear }) => <F7Searchbar onChange={onChange} onClickClear={onClickClear} />;

Searchbar.propTypes = {
  onChange: PropTypes.func,
  onClickClear: PropTypes.func
};

Searchbar.defaultProps = {
  onChange: mockFunction,
  onClickClear: mockFunction
};

export default Searchbar;
