import React from 'react';
import PropTypes from 'prop-types';

import { checkSearchParams } from 'Helpers';

const Terms = ({ language, user }) => {
  const { locale: userLocaleFromSearch, mc_name, mc_email } = checkSearchParams(window.location.search);
  const { mc_email: mc_email_tc_params, mc_name: mc_name_tc_params } = checkSearchParams(user.tc_params);

  const resolvedUserLocale = user.locale !== language ? language : userLocaleFromSearch;

  return (
    <div className="padding-agreements">
      {(resolvedUserLocale === 'en' || user.locale === 'en') && (
        <div>
          <h3>Media Release Form</h3>
          <p>
            Company Name (Data Controller): {mc_name || mc_name_tc_params} <b> the Company </b>
          </p>
          <p>
            Contact DPO with queries: <b>{mc_email || mc_email_tc_params}</b>
          </p>
          <p>
            I hereby consent to the Company using my image from video recordings, and/or photographs taken by the
            Company or its agents for the purposes of marketing and publicity. I understand that any such images may be
            published for these purposes on social media channels, online or elsewhere the Company.
          </p>
          <p>By consenting:</p>
          <ol>
            <li>
              I understand that such media and digital files may be altered and I waive the right of approval of any
              finished product.
            </li>
            <li>
              I understand that these images will be the copyright of the {mc_name || mc_name_tc_params} and any other
              intellectual property which arises in respect of the images will belong to the Company.
            </li>
            <li>
              I hereby agree to irrevocably assign all property rights in my photographs and/or recordings to the
              Company.
            </li>
            <li>
              I understand that I will not receive nor be entitled to compensation of any kind for the use such images.
            </li>
          </ol>
          <p>
            You are not obliged to sign this consent form as part of your engagement. You may withdraw your consent at
            any time by contacting the Company at the above email address and the Company will endeavour to stop using
            your images as soon as possible. You also have the right to lodge a complaint with the Supervising Authority
            at any time in respect of this matter if you wish.
          </p>
        </div>
      )}
      {(resolvedUserLocale === 'fr' || user.locale === 'fr') && (
        <div>
          <h3>Formulaire d'autorisation de diffusion</h3>
          <p>
            Nom de la société (Contrôleur de données): {mc_name || mc_name_tc_params} <b> la société </b>
          </p>
          <p>
            Contactez le DPO pour toute question: <b>{mc_email || mc_email_tc_params}</b>
          </p>
          <p>
            Je consens par la présente à ce que la Société utilise mon image provenant d'enregistrements vidéo et/ou de
            photographies prises par la Société ou ses agents à des fins marketing et de publicité. Je comprends que de
            telles images peuvent être publiées à ces fins sur les canaux de médias sociaux, en ligne ou ailleurs dans
            la Société.
          </p>
          <p>En consentant:</p>
          <ol>
            <li>
              Je comprends que ces supports et fichiers numériques peuvent être modifiés et je renonce au droit
              d'approbation de tout produit fini.
            </li>
            <li>
              Je comprends que ces images seront la propriété intellectuelle de la Société et que toute autre propriété
              intellectuelle qui en découle appartiendra à la Société.
            </li>
            <li>
              J'accepte par la présente de céder irrévocablement à la Société tous les droits de propriété sur mes
              photographies et/ou enregistrements.
            </li>
            <li>
              Je comprends que je ne recevrai ni n'aurai droit à aucune compensation de quelque nature que ce soit pour
              l'utilisation de telles images.
            </li>
          </ol>
          <p>
            Vous n'êtes pas obligé de signer ce formulaire de consentement dans le cadre de votre engagement. Vous
            pouvez retirer votre consentement en tout temps en communiquant avec la Société à l'adresse électronique
            ci-dessus et la société s'efforcera de cesser d'utiliser vos images dès que possible. Vous avez également le
            droit de déposer une plainte auprès de l'autorité de surveillance à tout moment à ce sujet si vous le
            souhaitez.
          </p>
        </div>
      )}
      {(resolvedUserLocale === 'nl' || user.locale === 'nl') && (
        <div>
          <h3>Media Formulier</h3>
          <p>
            Bedrijfsnaam (Gegevensbeheerder:) {mc_name || mc_name_tc_params} <b> Het bedrijf </b>
          </p>
          <p>
            Bij vragen, neem contact op met de: <b>{mc_email || mc_email_tc_params}</b>
          </p>
          <p>
            Ik geef hierbij toestemming aan het Bedrijf om mijn beeltenis van video-opnamen, en/of foto's die door het
            Bedrijf of zijn agenten zijn genomen, te gebruiken voor marketing- en publiciteitsdoeleinden. Ik begrijp dat
            dergelijke beelden voor deze doeleinden gepubliceerd kunnen worden op sociale mediakanalen, online of elders
            in het Bedrijf. Ik geef hierbij toestemming aan het Bedrijf om mijn beeltenis van video-opnamen, en/of
            foto's die door het Bedrijf of zijn agenten zijn genomen, te gebruiken voor marketing- en
            publiciteitsdoeleinden. Ik begrijp dat dergelijke beelden voor deze doeleinden gepubliceerd kunnen worden op
            sociale mediakanalen, online of elders in het Bedrijf.
          </p>
          <p>Door toestemming:</p>
          <ol>
            <li>
              Ik begrijp dat dergelijke media en digitale bestanden kunnen worden gewijzigd en ik zie af van het recht
              op goedkeuring van het eindproduct.
            </li>
            <li>
              Ik begrijp dat deze beelden het auteursrecht van het bedrijf zullen zijn en dat alle andere intellectuele
              eigendom die met betrekking tot de beelden ontstaat, aan het bedrijf toebehoort.
            </li>
            <li>
              Ik stem er hierbij mee in alle eigendomsrechten van mijn foto's en/of opnames onherroepelijk aan het
              bedrijf over te dragen.
            </li>
            <li>
              Ik begrijp dat ik geen vergoeding van welke aard dan ook zal ontvangen of recht zal hebben op een
              vergoeding voor het gebruik van dergelijke beelden.
            </li>
          </ol>
          <p>
            U bent niet verplicht om dit toestemmingsformulier te ondertekenen als onderdeel van uw opdracht. U kunt uw
            toestemming te allen tijde intrekken door contact op te nemen met het Bedrijf op bovenstaand e-mailadres en
            het Bedrijf zal ernaar streven het gebruik van uw beelden zo snel mogelijk te stoppen. U hebt ook het recht
            om op elk moment een klacht in te dienen bij de Toezichthoudende Autoriteit met betrekking tot deze zaak,
            indien u dat wenst.
          </p>
        </div>
      )}
    </div>
  );
};

Terms.propTypes = {
  language: PropTypes.string,
  mc_email: PropTypes.string,
  mc_name: PropTypes.string,
  user: PropTypes.object
};

Terms.defaultProps = {
  language: '',
  mc_email: '',
  mc_name: '',
  user: {}
};

export default Terms;
