const swUrl = './installServiceWorker.js';

const listen = callback => {
  navigator.serviceWorker.addEventListener('message', function (e) {
    if (callback) {
      callback(e);
    }
  });
};
const register = () => {
  window.addEventListener('load', registerValidSW);
};

const registerValidSW = () => {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      // eslint-disable-next-line
      console.log('registration successful', registration);
    })
    .catch(error => {
      // eslint-disable-next-line
      console.error('Error during service worker registration:', error);
    });
};

const removeRegisteredServiceWorkers = registration => {
  registration.unregister();
};

const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(removeRegisteredServiceWorkers);
  }
};

export default { listen, register, unregister };
