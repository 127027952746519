import { put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import { I18n } from 'Locales';
import { COUNTRIES_ADDRESS } from 'Constants';
import { Campaign, PostCodes } from 'Repositories';
import { Types as GrowlTypes } from 'Reducers/growl';
import { Types as TransientTypes } from 'Reducers/transient';
import { Types as PostCodesTypes } from 'Reducers/postCodes';
import { toggledCampaigns as toggledCampaignsSelector } from 'Selectors';
import { user as userSelector, Types as UserTypes } from 'Reducers/user';
import { checkedInPostCodes as checkedInPostCodesSelector } from 'Reducers/checkIn';
import { Types as CampaignTypes, campaigns as campaignsSelector } from 'Reducers/campaign';
import { postCodesAddressesList as postCodesAddressesListSelector } from 'Reducers/postCodes';

// EXPORTED
export const get = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: CampaignTypes.SET_CAMPAIGNS_AND_DEFAULT },
      fail: { type: CampaignTypes.GET_ERROR }
    },
    promise: Campaign.get()
  });
};

export const setCampaignsAndDefault = function* ({ payload }) {
  const user = yield select(userSelector);
  const postCodesList = yield select(postCodesAddressesListSelector);
  const currentCheckInPostcodes = yield select(checkedInPostCodesSelector);

  let selectedCampaignId = null;

  yield put({ type: CampaignTypes.SET, payload, userId: user.id });

  if (user.campaign) {
    selectedCampaignId = user.campaign.id;
  } else if (payload.length > 0) {
    selectedCampaignId = payload[0].id;
  }

  if (selectedCampaignId) {
    const selectedCampaign = payload.find(campaign => campaign.id === selectedCampaignId);
    yield put({
      type: CampaignTypes.SET_SELECTED_CAMPAIGN,
      userId: user.id,
      payload: selectedCampaign
    });

    yield put({ type: UserTypes.SET_CAMPAIGN, campaign: selectedCampaign, displayGrowl: false });
  }

  if (
    (!postCodesList?.length || !currentCheckInPostcodes?.length) &&
    COUNTRIES_ADDRESS.AUTOCOMPLETE.includes(user.country)
  ) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: PostCodesTypes.SET },
        fail: { type: Api.API_ERROR }
      },
      promise: PostCodes.get(user.country.toLowerCase())
    });
  }

  if (!user.tc_accepted) {
    yield put({
      type: TransientTypes.UPDATE_PROP,
      key: 'tc_modal',
      value: true
    });
  }
};

export const setToggledCampaigns = function* ({ payload }) {
  const user = yield select(userSelector);
  const campaigns = yield select(campaignsSelector);
  const toggledCampaigns = yield select(toggledCampaignsSelector);
  const isSelectedCampaignToggled = payload.id === campaigns[user.id].selectedCampaign.id;

  yield put({ type: CampaignTypes.TOGGLE_CAMPAIGN, payload, userId: user.id });

  if (isSelectedCampaignToggled) {
    const firstAvailableCampaign = campaigns[user.id].campaigns.find(
      campaign => toggledCampaigns[campaign.id]?.toggled === true
    );

    yield put({
      type: CampaignTypes.SET_SELECTED_CAMPAIGN,
      userId: user.id,
      payload: firstAvailableCampaign
    });

    yield put({
      type: UserTypes.SET_CAMPAIGN,
      campaign: firstAvailableCampaign,
      displayGrowl: false
    });
  }
};

export const getError = function* () {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.campaign.get.title'),
    body: I18n.t('growl:error.campaign.get.body'),
    kind: 'error'
  });
};
