import Framework7 from 'framework7';
import Fab from 'framework7/components/fab';
import Card from 'framework7/components/card';
import Grid from 'framework7/components/grid';
import Tabs from 'framework7/components/tabs';
import Sheet from 'framework7/components/sheet';
import Input from 'framework7/components/input';
import Popup from 'framework7/components/popup';
import Panel from 'framework7/components/panel';
import Radio from 'framework7/components/radio';
import Toggle from 'framework7/components/toggle';
import Dialog from 'framework7/components/dialog';
import Swiper from 'framework7/components/swiper';
import Actions from 'framework7/components/actions';
import Checkbox from 'framework7/components/checkbox';
import Calendar from 'framework7/components/calendar';
import Sortable from 'framework7/components/sortable';
import Searchbar from 'framework7/components/searchbar';
import Preloader from 'framework7/components/preloader';
import DataTable from 'framework7/components/data-table';
import TextEditor from 'framework7/components/text-editor';
import SmartSelect from 'framework7/components/smart-select';
import VirtualList from 'framework7/components/virtual-list';
import LoginScreen from 'framework7/components/login-screen';
import Autocomplete from 'framework7/components/autocomplete';

import 'framework7/less';
import 'framework7/components/fab/css';
import 'framework7/components/card/css';
import 'framework7/components/grid/css';
import 'framework7/components/tabs/css';
import 'framework7/components/input/css';
import 'framework7/components/sheet/css';
import 'framework7/components/panel/css';
import 'framework7/components/popup/css';
import 'framework7/components/radio/css';
import 'framework7/components/dialog/css';
import 'framework7/components/swiper/css';
import 'framework7/components/toggle/css';
import 'framework7/components/actions/css';
import 'framework7/components/checkbox/css';
import 'framework7/components/calendar/css';
import 'framework7/components/sortable/css';
import 'framework7/components/preloader/css';
import 'framework7/components/searchbar/css';
import 'framework7/components/data-table/css';
import 'framework7/components/text-editor/css';
import 'framework7/components/login-screen/css';
import 'framework7/components/smart-select/css';
import 'framework7/components/virtual-list/css';
import 'framework7/components/autocomplete/css';

Framework7.use([
  Actions,
  Autocomplete,
  Calendar,
  Card,
  Checkbox,
  DataTable,
  Dialog,
  Fab,
  Grid,
  Input,
  LoginScreen,
  Panel,
  Popup,
  Preloader,
  Radio,
  Searchbar,
  Sheet,
  SmartSelect,
  Sortable,
  Swiper,
  Tabs,
  TextEditor,
  Toggle,
  VirtualList
]);

export default Framework7;
