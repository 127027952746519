import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Searchbar } from 'framework7-react';

import { I18n } from 'Locales';
import { debounce } from 'Helpers';
import { User } from 'Repositories';
import { RowView } from 'Containers';
import { COUNTRIES_ADDRESS, REGIONS } from 'Constants';

import { DE, FR, UK } from './countries';
import { getFieldProp } from '../../shared';
import UnavailableComponent from '../UnavailableComponent';

import './style.scss';

const Address = props => {
  const setValues = (oldProps, individualKeyValue, newProps, searchProps = false) => {
    const initialState = {
      search_address: '',

      City: '',
      AddressLine1: '',
      AddressLine2: '',
      AddressLine3: '',
      ExtraInfo: '',
      ExtraInfo2: '',
      Letter: '',
      Number: '',
      PostalDistrict: '',
      PostCode: '',
      StreetName: ''
    };

    let newState;

    if (searchProps) {
      const newPropsMapped = {
        StreetName: newProps.street_name,
        AddressLine1: newProps.address_line1,
        AddressLine2: newProps.address_line2,
        AddressLine3: newProps.address_line3,
        PostCode: newProps.postcode,
        search_address: newProps.full_address,
        City: newProps.town,
        ExtraInfo: newProps.house_info,
        Number: newProps.number
      };

      newState = { ...initialState, ...newPropsMapped };
    } else {
      newState = { ...initialState, ...oldProps, ...individualKeyValue, search_address: oldProps.search_address };
    }

    props.updateValue({ value: newState });
  };

  const handleUKSearch = debounce((getUKAddress, e) => {
    const text = e.target.value.trim();
    if (text.length >= 1) {
      getUKAddress({ text });
    }
  }, 500);

  const onClickUKHandler = ({ updateTransientProps, item }) => {
    User.getUKAddress(item).then(cb => {
      if (cb.status === 200) {
        const address_uk_find = cb.data[0];
        setValues(null, { full_address: address_uk_find.full_address }, address_uk_find, true);
        updateTransientProps({
          uk_address: [],
          address_field_disabled: true
        });
      }
    });
  };

  const handleSearch = debounce((updateTransientProps, e) => {
    const value = e.target.value.toLowerCase().trim();

    if (value.length === 0) {
      updateTransientProps({ filtered_addresses: [] });
      return;
    }

    const searchParts = value.split(/\s+/);
    /* Split search input by whitespace (by adding this
     we'll be able to search for an address by using comma or not) */

    const filteredAddresses = props.currentCheckInAdress
      ?.filter(({ full_address }) => {
        const fullAddress = full_address.toLowerCase();
        return searchParts.every(part => fullAddress.includes(part));
      })
      .slice(0, 10);

    updateTransientProps({ filtered_addresses: filteredAddresses });
  }, 500);

  const onClickHandler = ({ updateTransientProps, item }) => {
    setValues(null, null, item, true);
    updateTransientProps({
      filtered_addresses: []
    });
  };

  const { country, fields, getUKAddress, isOffline, name, transient, updateTransientProps } = props;
  const { address_field_disabled = true } = transient;

  return COUNTRIES_ADDRESS.ALL.includes(country) ? (
    <div>
      <RowView justifyContent={'flex-start'} paddingLeft={16}>
        <div className="item-title item-label">{name}</div>
      </RowView>

      {country === 'UK' && (
        <>
          {!isOffline && (
            <div id={'address-comp'}>
              <RowView paddingHorizontal={8}>
                <Searchbar
                  init={false}
                  placeholder={getFieldProp(fields, 'search_address').placeholder}
                  onChange={handleUKSearch.bind(null, getUKAddress)}
                  onClickClear={updateTransientProps.bind(null, { uk_address: [] })}
                />
              </RowView>
              <div className="px-16">{!address_field_disabled && I18n.t('flow:components.address.loqateDown')}</div>
            </div>
          )}

          {transient.uk_address && !!transient.uk_address.length && (
            <List className="mt-0">
              {transient.uk_address.map((item, index) => {
                const { id, type, full_address } = item;
                const address_params = {
                  id,
                  type,
                  text: full_address.split('(More Addresses)')[0].trim()
                };

                return (
                  <ListItem
                    key={index}
                    onClick={
                      item.type === 'Address'
                        ? onClickUKHandler.bind(null, { updateTransientProps, item })
                        : getUKAddress.bind(null, address_params)
                    }>
                    {item.full_address}
                  </ListItem>
                );
              })}
            </List>
          )}
        </>
      )}

      {COUNTRIES_ADDRESS.AUTOCOMPLETE.includes(country) && (
        <>
          <div id={'address-comp'}>
            <RowView paddingHorizontal={8}>
              <Searchbar
                init={false}
                placeholder={getFieldProp(fields, 'search_address').placeholder}
                onChange={handleSearch.bind(null, updateTransientProps)}
                onClickClear={updateTransientProps.bind(null, { filtered_addresses: [] })}
              />
            </RowView>
          </div>

          {transient.filtered_addresses && !!transient.filtered_addresses.length && (
            <List className="mt-0">
              {transient.filtered_addresses.map((item, index) => (
                <ListItem key={index} onClick={onClickHandler.bind(null, { updateTransientProps, item })}>
                  {item.full_address}
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}

      {REGIONS.BE.includes(country) && <DE {...props} setValues={setValues} />}
      {REGIONS.UK.includes(country) && <UK {...props} setValues={setValues} />}
      {country === 'FR' && <FR {...props} setValues={setValues} />}
    </div>
  ) : (
    <UnavailableComponent {...props} />
  );
};

Address.propTypes = {
  component_key: PropTypes.any,
  country: PropTypes.string,
  currentCheckInAdress: PropTypes.array,
  fields: PropTypes.array,
  getUKAddress: PropTypes.func,
  isOffline: PropTypes.bool,
  name: PropTypes.string,
  shouldShowError: PropTypes.bool,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateValue: PropTypes.func,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.object)
};

export default Address;
