import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'framework7-react';

import { Logout } from 'Icons';
import { Fab } from 'Components';
import { mockFunction } from 'Helpers';
import { ICON_COLOR } from 'Constants';
import { Message } from 'FlowComponentTypes';

import './style.scss';

const DonorThankYou = ({ fields = [], logout = mockFunction }) => (
  <Page id="donor-thank-you">
    <Message fields={fields} />

    <Fab disabled={false} onClick={logout} position={'right-bottom'} slot={'fixed'}>
      <Logout fillColor={ICON_COLOR.WHITE} />
    </Fab>
  </Page>
);

DonorThankYou.propTypes = {
  fields: PropTypes.array,
  logout: PropTypes.func
};

export default DonorThankYou;
