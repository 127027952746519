import React from 'react';
import PropTypes from 'prop-types';

import { ADDRESS_FLOW_COMPONENT } from 'Constants';

const Confirmation = props => {
  const { fields = [], flow = {}, id = '', referenced_products = [] } = props;

  const currentFlowData = flow?.currentFlowData;
  const confirmationKey = fields[1]?.placeholder;
  const confirmationMessage = fields[0]?.placeholder;
  const refProducts = referenced_products;

  let displayValue = null;
  // Check if confirmationKey includes a dot (.) and the first part of the split key exists in currentFlowData
  if (confirmationKey.includes('.') && currentFlowData[confirmationKey.split('.')[0]]) {
    // Extract data and key from the nested structure
    const addressData = currentFlowData[confirmationKey.split('.')[0]].value;
    const addressKey = confirmationKey.split('.')[1];

    // Check if addressData is an object, addressKey is present, and addressData is not an array
    if (addressData && typeof addressData === 'object' && addressKey in addressData && !Array.isArray(addressData)) {
      // Set displayValue based on the extracted addressData
      displayValue = addressData[addressKey];
    }
  } else if (confirmationKey in currentFlowData) {
    // If confirmationKey is a direct key in currentFlowData
    const confirmationItemData = currentFlowData[confirmationKey];

    // Check if the component type is 'product'
    if (confirmationItemData.component === 'product') {
      // Find the product in refProducts based on ID and set its name (value of the selected ammount) as displayValue
      displayValue = refProducts.find(product => product.id.toString() === confirmationItemData.value)?.name;
    } else if (
      // Check if the value is an object with an 'email' property and it is not an array
      typeof confirmationItemData.value === 'object' &&
      'email' in confirmationItemData.value &&
      !Array.isArray(confirmationItemData.value)
    ) {
      // Set displayValue as the email value from the object
      displayValue = confirmationItemData.value.email;
    } else if (typeof confirmationItemData.value === 'string') {
      // Set displayValue as the string value
      displayValue = confirmationItemData.value;
    } else {
      // If none of the above conditions match, attempt to find a matching key in ADDRESS_FLOW_COMPONENT.KEYS
      const matchingKey = ADDRESS_FLOW_COMPONENT.KEYS?.find(
        key =>
          'value' in confirmationItemData &&
          typeof confirmationItemData.value === 'object' &&
          key in confirmationItemData.value &&
          !Array.isArray(confirmationItemData.value)
      );

      // If a matching key is found, set displayValue based on the matching key
      if (matchingKey) {
        displayValue = confirmationItemData.value[matchingKey];
      }
    }
  }

  return (
    <div key={id} className="px-18 py-4">
      {confirmationMessage} {displayValue}
    </div>
  );
};

Confirmation.propTypes = {
  fields: PropTypes.array,
  flow: PropTypes.object,
  id: PropTypes.string,
  referenced_products: PropTypes.array
};

export default Confirmation;
