import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Sheet } from 'framework7-react';

import { f7Colors } from 'Theme';
import { RowView, ColumnView } from 'Containers';

import Button from '../Button';

import style from './style';

class Growl extends React.Component {
  handleOnClick = (props, buttonAction) => {
    props.updateProp('visible', false);
    props[buttonAction](props.rest);
  };

  render = () => {
    const { body, buttons, client, kind, title, updateProp, visible } = this.props;
    return (
      <Sheet push opened={visible} position={'top'} backdrop={false} style={style.autoHeight}>
        <RowView
          shadow
          paddingVertical={15}
          paddingHorizontal={15}
          marginBottom={15}
          onClick={updateProp.bind(null, 'visible', false)}>
          <ColumnView alignItems={'flex-start'}>
            <RowView justifyContent={'flex-start'}>
              <Icon
                material={kind === 'error' ? 'error_outline' : 'info_outline'}
                color={kind === 'error' ? f7Colors.red : f7Colors.black}
              />
              {client && (
                <ColumnView alignItems={'flex-start'} marginLeft={5}>
                  {client.toUpperCase()}
                </ColumnView>
              )}
            </RowView>
            <RowView justifyContent={'flex-start'}>
              <strong>{title}</strong>
            </RowView>
            <RowView justifyContent={'flex-start'}>{body}</RowView>

            <RowView justifyContent={'flex-end'}>
              {buttons.map((button, idx) => (
                <Button.Transparent
                  onClick={this.handleOnClick.bind(null, this.props, button.action)}
                  key={button.label + idx}>
                  {button.label}
                </Button.Transparent>
              ))}
            </RowView>
          </ColumnView>
        </RowView>
      </Sheet>
    );
  };
}

Growl.propTypes = {
  client: PropTypes.string,
  body: PropTypes.string,
  buttons: PropTypes.array,
  dispatch: PropTypes.func,
  title: PropTypes.string,
  kind: PropTypes.string,
  updateProp: PropTypes.func,
  visible: PropTypes.bool
};

export default Growl;
