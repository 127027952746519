import React from 'react';

import PropTypes from 'prop-types';

const Identity = ({ fillColor = '#1C1B1F' }) => (
  <svg width="24" height="24" fill="none">
    <mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" marktype="alpha">
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fillColor}
        // eslint-disable-next-line max-len
        d="M6.154 17.712h5.692v-.297c0-.255-.07-.491-.212-.709a1.404 1.404 0 0 0-.592-.506A4.997 4.997 0 0 0 9 15.77a4.996 4.996 0 0 0-2.042.43c-.253.12-.45.289-.592.506a1.276 1.276 0 0 0-.212.71v.296Zm8.442-1.52h2.808a.58.58 0 0 0 .596-.595.58.58 0 0 0-.596-.597h-2.808a.579.579 0 0 0-.596.596.58.58 0 0 0 .596.596ZM8.998 15c.373 0 .691-.13.954-.392.263-.261.394-.579.394-.952s-.13-.691-.392-.954a1.292 1.292 0 0 0-.952-.394c-.373 0-.691.13-.954.392a1.292 1.292 0 0 0-.394.952c0 .373.13.69.392.954.261.263.578.394.952.394Zm5.598-1.5h2.808a.58.58 0 0 0 .596-.596.58.58 0 0 0-.596-.596h-2.808a.58.58 0 0 0-.596.595.58.58 0 0 0 .596.597Zm-10.288 8c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V9.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525H9.5V4c0-.413.147-.766.44-1.06.294-.293.648-.44 1.06-.44h2c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v3.5h5.192c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v10.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.308Zm0-1.5h15.384a.3.3 0 0 0 .221-.087.3.3 0 0 0 .087-.22V9.307a.3.3 0 0 0-.087-.221.3.3 0 0 0-.22-.087H14.5v.385c0 .409-.148.76-.443 1.056a1.443 1.443 0 0 1-1.057.444h-2c-.409 0-.761-.148-1.057-.444A1.443 1.443 0 0 1 9.5 9.385V9H4.308a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.22v10.385a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087ZM11 9.385h2V4h-2v5.385Z"
      />
    </g>
  </svg>
);

export default Identity;

Identity.propTypes = {
  fillColor: PropTypes.string
};
