import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'Components';
import { mockFunction } from 'Helpers';
import { ColumnView, RowView } from 'Containers';

import './style.scss';

const Card = ({
  buttonCardDisabled = false,
  buttonLabel = '',
  buttonPosition = 'flex-start',
  buttonVariant = '',
  buttonWidth = '',
  className = '',
  color = 'card-bg-blue',
  description = {},
  iconButtonLeft = undefined,
  iconButtonRight = undefined,
  number = '',
  onClick = mockFunction,
  title = '',
  width = '100%'
}) => {
  const backgroundColor = color === 'red' ? 'card-bg-red' : 'card-bg-blue';
  const buttonVariants = {
    outline: Button.Outline,
    primary: Button.Primary,
    transparet: Button.Transparent
  };

  const ButtonComponent = buttonVariants[buttonVariant] || Button.Tertiary;

  return (
    <div id={'card'} className={`mt-0 ${backgroundColor} ${className}`} style={{ backgroundColor, width }}>
      <ColumnView gap={number ? 8 : 16}>
        {title && (
          <RowView justifyContent={'flex-start'}>
            <p className="card-title uppercase">{title}</p>
          </RowView>
        )}
        <RowView>
          {number && <div className="card-number">{number}</div>}

          {description && (
            <RowView justifyContent={'flex-start'}>
              <div className="card-description">{description}</div>
            </RowView>
          )}
        </RowView>
        {buttonLabel && (
          <RowView justifyContent={buttonPosition}>
            <ButtonComponent
              disabled={buttonCardDisabled}
              iconLeft={iconButtonLeft}
              iconRight={iconButtonRight}
              width={buttonWidth}
              onClick={onClick}>
              <span className="font-medium">{buttonLabel}</span>
            </ButtonComponent>
          </RowView>
        )}
      </ColumnView>
    </div>
  );
};

Card.propTypes = {
  buttonCardDisabled: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonPosition: PropTypes.string,
  buttonVariant: PropTypes.string,
  buttonWidth: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.node,
  iconButtonLeft: PropTypes.node,
  iconButtonRight: PropTypes.node,
  number: PropTypes.number,
  onClick: PropTypes.func,
  title: PropTypes.string,
  width: PropTypes.string
};

export default Card;
