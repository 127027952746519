import I18n from 'i18next';

import en from './en';
import fr from './fr';
import nl from './nl';

const detectBrowserLanguage = () => {
  const fullLanguageCode =
    (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
  return fullLanguageCode.split('-')[0];
};

I18n.init({
  resources: {
    en,
    fr,
    nl
  },
  lng: detectBrowserLanguage(),

  fallbackLng: 'en',
  whitelist: ['nl', 'en', 'fr', 'de']
});

export default I18n;
