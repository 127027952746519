import React from 'react';
import PropTypes from 'prop-types';

import { Warning } from 'Icons';
import { RowView } from 'Containers';

const LabelWarning = ({ isRequired, label, warning }) => (
  <RowView justifyContent={'flex-start'} alignItems={'normal'}>
    {warning && (
      <div className="icon-wrapper">
        <Warning />
      </div>
    )}
    {label}
    {isRequired && <div className="text-fail text-md pl-4">*</div>}
  </RowView>
);

LabelWarning.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  warning: PropTypes.bool
};

LabelWarning.defaultProps = {
  isRequired: false,
  label: '',
  warning: false
};

export default LabelWarning;
