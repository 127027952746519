import React from 'react';
import PropTypes from 'prop-types';

const Online = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clipPath="url(#clip0_3967_4433)">
      <path
        // eslint-disable-next-line max-len
        d="M10.8328 17.5625H10.8415M20.2884 8.11317C17.7974 5.83049 14.4777 4.4375 10.8327 4.4375C7.18764 4.4375 3.86797 5.83049 1.37695 8.11317M4.47321 11.2126C6.16906 9.7187 8.39508 8.8125 10.8327 8.8125C13.2704 8.8125 15.4964 9.7187 17.1923 11.2126M14.0688 14.3032C13.1771 13.6042 12.0535 13.1875 10.8327 13.1875C9.59332 13.1875 8.45427 13.6169 7.55621 14.3351"
        stroke="#747474"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3967_4433">
        <rect width="21" height="21" fill="white" transform="translate(0.333008 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

Online.propTypes = {
  fillColor: PropTypes.string
};

export default Online;
