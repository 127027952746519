import React from 'react';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { TextField } from 'Components';
import { FormattedLabel, isSmallDevice } from 'Helpers';

import './style.scss';
import { componentPropTypes, getIconColor } from '../../shared';

class ConfirmEmail extends React.Component {
  render = () => {
    const { fields, required, shouldShowError, value } = this.props;
    let emailValues = value;
    if (!emailValues) {
      emailValues = {
        email: '',
        emailConfirmation: ''
      };
    }

    const { email, emailConfirmation } = emailValues;
    return (
      <RowView flexWrap={isSmallDevice ? 'wrap' : 'nowrap'}>
        <TextField
          label={FormattedLabel({ required, name: I18n.t('flow:components.confirmEmail.email') })}
          type={'email'}
          placeholder={fields[0]?.placeholder}
          className={'input-style'}
          value={email}
          onChange={e => this.setValues(value, { email: e.target.value })}
          errorMessageForce={shouldShowError || email !== emailConfirmation}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError || email !== emailConfirmation, email)}
        />

        <TextField
          label={FormattedLabel({ required, name: I18n.t('flow:components.confirmEmail.confirmEmail') })}
          type={'email'}
          placeholder={fields[1]?.placeholder}
          value={emailConfirmation}
          className={'input-style'}
          onChange={e => this.setValues(value, { emailConfirmation: e.target.value })}
          errorMessageForce={shouldShowError || email !== emailConfirmation}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError || email !== emailConfirmation, emailConfirmation)}
        />
      </RowView>
    );
  };

  setValues = (oldProps, individualKeyValue) => {
    const initialState = {
      email: '',
      emailConfirmation: ''
    };

    const newState = Object.assign(initialState, oldProps, individualKeyValue);

    this.props.updateValue({ value: newState });
  };
}

ConfirmEmail.propTypes = {
  ...componentPropTypes
};

export default ConfirmEmail;
