import Config from 'Config';

import { I18n } from 'Locales';
import { Creators as FlowCreators } from 'Reducers/flow';
import { Creators as GrowlCreators } from 'Reducers/growl';
import { NavigationService, dataFormatter } from 'Services';
import { Creators as CheckInCreators } from 'Reducers/checkIn';
import { Creators as CampaignCreators } from 'Reducers/campaign';
import { Creators as ApplicationCreators } from 'Reducers/application';

const badStatuses = [0, 401, 403, 404, 'TIMEOUT'];

export const onServiceWorkerMessage = function ({ data: { type, url, data, status } }) {
  switch (type) {
    case 'request':
      receivedRequestMessage({ url, data, status });
      break;

    case 'serviceWorkerVersion':
      NavigationService.dispatch(
        GrowlCreators.alert(
          I18n.t('growl:warn.newVersion.title', {
            version: `${Config.APP_VERSION}-${data}`
          }),
          I18n.t('growl:warn.newVersion.body'),
          'info',
          true
        )
      );
      receivedSWVersionMessage(data);
      break;
  }
};

const receivedSWVersionMessage = data => {
  NavigationService.dispatch(ApplicationCreators.updateProp('serviceWorkerVersion', data));
};

const receivedRequestMessage = ({ url, data, status }) => {
  const path = url.replace(Config.API_PATH_PREFIX, '');
  const { user } = NavigationService.getStoreState();
  const userId = user.id;
  const errorHandled = false;
  const deserializedData = dataFormatter.deserialize(data);

  if (badStatuses.includes(status)) {
    // TODO switch for paths in here
    if (!errorHandled) {
      NavigationService.dispatch(ApplicationCreators.apiError({ status }));
    }
  } else {
    switch (path) {
      // EXACT MATCH URLs
      case '/campaigns/light_index':
        NavigationService.dispatch(CampaignCreators.set(deserializedData, userId));
        break;

      case '/check_ins':
        NavigationService.dispatch(CheckInCreators.set(deserializedData, userId));

        break;

      default:
        // REGEX URLs
        if (path.startsWith('/published_flows/')) {
          NavigationService.dispatch(FlowCreators.set(deserializedData, userId));
        }
    }
  }
};
