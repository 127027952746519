import React from 'react';
import ReactDOM from 'react-dom';
import Framework7React from 'framework7-react';

import { ServiceWorker } from 'Offline';
import { Application } from 'Components';

import Framework7 from './framework7-components';

import 'CSS/app.scss';
import 'CSS/icons.css';

Framework7.use(Framework7React);

ReactDOM.render(React.createElement(Application), document.getElementById('application'));

if ('serviceWorker' in navigator) {
  ServiceWorker.register();
}
