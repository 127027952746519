import React from 'react';

import PropTypes from 'prop-types';

const General = ({ fillColor = '#1C1B1F' }) => (
  <svg width="24" height="24" fill="none">
    <mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" masktype="alpha">
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fillColor}
        // eslint-disable-next-line max-len
        d="M12.5 11.663c-1.09 0-2.025-.375-2.801-1.125-.777-.75-1.166-1.653-1.166-2.706 0-1.054.389-1.956 1.166-2.706C10.475 4.376 11.409 4 12.5 4c1.09 0 2.025.375 2.801 1.126.777.75 1.166 1.652 1.166 2.706 0 1.053-.389 1.955-1.166 2.706-.776.75-1.71 1.125-2.801 1.125ZM4 18.337v-.77c0-.537.15-1.033.452-1.49a2.985 2.985 0 0 1 1.209-1.054c1.12-.53 2.25-.928 3.39-1.194a15.166 15.166 0 0 1 6.898 0c1.14.266 2.27.664 3.39 1.194.504.246.907.597 1.209 1.054.301.457.452.953.452 1.49v.77c0 .462-.168.854-.502 1.178a1.69 1.69 0 0 1-1.22.485H5.722a1.69 1.69 0 0 1-1.22-.485A1.576 1.576 0 0 1 4 18.337Zm1.7.02h13.6v-.79c0-.222-.067-.428-.2-.616a1.496 1.496 0 0 0-.541-.463 14.9 14.9 0 0 0-2.987-1.055 13.341 13.341 0 0 0-6.144 0 14.9 14.9 0 0 0-2.987 1.055c-.228.12-.409.274-.542.463-.133.188-.199.394-.199.615v.792Zm6.8-8.336c.623 0 1.157-.214 1.6-.643.445-.429.667-.944.667-1.546 0-.603-.222-1.118-.666-1.547a2.222 2.222 0 0 0-1.601-.643c-.623 0-1.157.214-1.6.643a2.073 2.073 0 0 0-.667 1.547c0 .602.222 1.117.666 1.546.444.429.978.643 1.601.643Z"
      />
    </g>
  </svg>
);

export default General;

General.propTypes = {
  fillColor: PropTypes.string
};
