export default {
  appWrapper: {
    position: 'absolute',
    zIndex: 1
  },
  splashScreen: {
    position: 'absolute',
    zIndex: 2
  }
};
