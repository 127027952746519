import PropType from 'prop-types';
import React, { useRef, useState } from 'react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { ArrowRight } from 'Icons';
import { Button } from 'Components';
import { isSmallDevice } from 'Helpers';
import { ColumnView, RowView } from 'Containers';

import Signature from '../../shared/Signature';
import { componentPropTypes } from '../../shared';

const updateSign = (updateSignature, agreement, value) => {
  updateSignature('signature', {
    base64: value,
    agreement: agreement
  });
};

const SummarySignature = props => {
  const { currentPageIndex, description, mode, updateAgreement, updateSignature, userFullName, value } = props;

  const ref = useRef(null);

  const [sheetVisible, setSheetVisible] = useState(false);

  return (
    <div ref={ref}>
      <ColumnView paddingLeft={16} marginBottom={15}>
        <RowView justifyContent="flex-start">
          <span>
            <b>{I18n.t(`flow:summary.${mode !== 'donor' ? 'customerSignature' : 'yourSignature'}`)}</b>
          </span>
        </RowView>

        <RowView flexWrap={isSmallDevice ? 'wrap' : 'nowrap'} justifyContent={'flex-start'}>
          <RowView width={isSmallDevice ? '100%' : '50%'} paddingRight={isSmallDevice ? 16 : 0}>
            <Signature
              currentPageIndex={currentPageIndex}
              description={description}
              parentRef={ref}
              setSheetVisible={setSheetVisible}
              sheetVisible={sheetVisible}
              updateSignature={updateSign.bind(null, updateSignature, value?.agreement)}
              value={value?.base64}
            />
          </RowView>

          <ColumnView paddingLeft={isSmallDevice ? 0 : 12} justifyContent={'flex-start'}>
            <RowView justifyContent={'flex-start'}>
              <label>{description}</label>
            </RowView>

            {mode !== 'donor' && (
              <>
                <ColumnView alignItems={'stretch'} paddingTop={6}>
                  <RowView justifyContent={'flex-start'}>
                    <span>
                      <b>{I18n.t('flow:summary.customerSignature')}</b>
                    </span>
                  </RowView>

                  <RowView justifyContent={'flex-start'} paddingRight={20}>
                    <ColumnView>
                      <RowView justifyContent={'space-between'}>
                        <label>{I18n.t('flow:summary.agree')}</label>
                        <Button.OutlineSmall
                          className="icon-wrapper px-24"
                          iconRight={ArrowRight}
                          onClick={setSheetVisible.bind(null, true)}>
                          {I18n.t('flow:summary.sign')}
                        </Button.OutlineSmall>
                      </RowView>
                      <RowView backgroundColor={colors.darkGrey} height={1} marginTop={5} marginVertical={10} />
                    </ColumnView>
                  </RowView>
                </ColumnView>
                <ColumnView alignItems={'stretch'}>
                  <RowView justifyContent={'flex-start'}>
                    <span>
                      <b>{I18n.t('flow:summary.validity')}</b>
                    </span>
                  </RowView>

                  <RowView justifyContent={'flex-start'} paddingRight={20}>
                    <ColumnView>
                      <RowView justifyContent={'flex-start'} paddingRight={20}>
                        <label className={`checkbox ${!value?.base64 ? 'disabled' : ''}`}>
                          <input
                            id="agreement-checkbox-summary"
                            type="checkbox"
                            disabled={!value?.base64}
                            checked={value?.agreement}
                            onClick={updateAgreement}
                          />
                          <i className="icon-checkbox" />
                        </label>
                        <label htmlFor={'agreement-checkbox-summary'} className="pl-6">
                          {I18n.t('flow:summary.signatureValidity', {
                            userFullName,
                            interpolation: { escapeValue: false }
                          })}
                        </label>
                      </RowView>
                      <RowView backgroundColor={colors.darkGrey} height={1} marginTop={5} marginVertical={10} />
                    </ColumnView>
                  </RowView>
                </ColumnView>
              </>
            )}
          </ColumnView>
        </RowView>
      </ColumnView>
    </div>
  );
};

SummarySignature.propTypes = {
  ...componentPropTypes,
  currentPageIndex: PropType.number,
  mode: PropType.string,
  updateAgreement: PropType.func,
  updateSignature: PropType.func,
  userFullName: PropType.string,
  values: PropType.object
};

export default SummarySignature;
