import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Block, Checkbox, List, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { DataByteLogo } from 'Icons';
import { USER_LANGUAGES } from 'Constants';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { Button, StrengthBar, TextField } from 'Components';
import { checkSearchParams, handleOnChangeWithValidations, mockFunction, validations } from 'Helpers';

import './style.scss';

const tcArray = [
  {
    key: 'terms_agreement',
    title: 'mediaReleaseForm'
  },
  {
    key: 'conditions_agreement',
    title: 'legalAgreements'
  },
  {
    key: 'privacy_agreement',
    title: 'privacyStatement'
  }
];

const handleTearmsAndConditionsList = (updateTransientProps, item) => {
  updateTransientProps({ renderKey: item.key });
  NavigationService.navigate({
    name: 'TermsAndConditions'
  });
};

const checkBoxMessage = updateTransientProps => (
  <div className="text-xs">
    {I18n.t('general:iAgreeTo')}
    {tcArray.map((item, index) => (
      <span key={index}>
        <span
          className="title-links text-xs"
          onClick={handleTearmsAndConditionsList.bind(null, updateTransientProps, item)}>
          {I18n.t(`general:${item.title}`)}
        </span>
        {index < tcArray.length - 2 ? ', ' : index === tcArray.length - 2 ? `${' '}${I18n.t('general:and')}${' '}` : ''}
      </span>
    ))}
  </div>
);

const getIconColor = (fieldHasError, hasFieldValue) => (fieldHasError ? 'red' : hasFieldValue ? 'blue' : 'gray');

const SignUp = ({
  application,
  activateAccount,
  transient,
  updateAplicationProps,
  updateTransientProps,
  updateTransientPropWithValidations
}) => {
  const disableButton =
    !transient.tc_accepted ||
    transient.email_has_error ||
    transient.password_has_error ||
    transient.password_confirmation_has_error ||
    transient.password !== transient.password_confirmation ||
    !transient.email ||
    !transient.password ||
    !transient.password_confirmation;

  const isPasswordMatching = {
    isValid: value => value === transient.password,
    message: I18n.t('validations:passwordDontMatch'),
    messageKey: 'validations:passwordDontMatch'
  };

  const locale = checkSearchParams(window.location.search).locale;

  useEffect(() => {
    updateAplicationProps({ locale: locale });
    updateTransientProps({ tc_accepted: false });
  }, []);

  const handleChangeOption = (updateAplicationProps, key, e) => {
    updateAplicationProps({ [key]: e.target.value });
  };

  const renderOption = (option, index) => (
    <option key={index} value={option.value}>
      {option.label}
    </option>
  );

  return (
    <Page noToolbar noNavbar noSwipeback id={'sign-up-page'}>
      <ColumnView flex={1} height={'100%'} paddingBottom={90}>
        <ColumnView className="align-center" marginTop={60}>
          <img src={DataByteLogo} className="pt-68" width={'35%'} height={'35%'} />
          <ColumnView>
            <List form className="w-full">
              <TextField
                label={I18n.t('inputs:email.label')}
                type="email"
                placeholder={I18n.t('inputs:email.placeholder')}
                value={transient.email || ''}
                disabled
                autofocus
                validate
                validateOnBlur
                required
              />
              <TextField
                label={I18n.t('inputs:password.label')}
                type="password"
                placeholder={I18n.t('inputs:password.placeholder')}
                value={transient?.password || ''}
                onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'password', [
                  validations.isRequired,
                  validations.passwordValidation
                ])}
                errorMessageForce={transient?.password_has_error}
                errorMessage={I18n.t(transient?.password_error_message_key)}
                validate
                validateOnBlur
                icon="material:check"
                iconColor={getIconColor(transient?.password_has_error, transient?.password)}
              />
              <TextField
                label={I18n.t('inputs:confirmPassword.label')}
                type="password"
                placeholder={I18n.t('inputs:confirmPassword.placeholder')}
                value={transient?.password_confirmation || ''}
                onChange={handleOnChangeWithValidations.bind(
                  null,
                  updateTransientPropWithValidations,
                  'password_confirmation',
                  [validations.isRequired, validations.passwordValidation, isPasswordMatching]
                )}
                errorMessageForce={transient?.password_confirmation_has_error}
                errorMessage={I18n.t(transient?.password_confirmation_error_message_key)}
                validate
                validateOnBlur
                icon="material:check"
                iconColor={getIconColor(transient?.password_confirmation_has_error, transient?.password_confirmation)}
              />
              <TextField
                label={I18n.t('settings:languagePreferences')}
                type="select"
                value={application.locale || locale}
                onChange={handleChangeOption.bind(null, updateAplicationProps, 'locale')}>
                {USER_LANGUAGES.LANGUAGES?.map(renderOption)}
              </TextField>
              <StrengthBar password={transient?.password} className="px-16" />
              <RowView justifyContent={'flex-start'} marginTop={8} paddingHorizontal={16}>
                <div className="password-requirement">{I18n.t('settings:passwordRequirement')}</div>
              </RowView>
            </List>
          </ColumnView>
        </ColumnView>
        <ColumnView marginTop={'auto'}>
          <Block className="w-full">
            <RowView paddingVertical={16}>
              <Checkbox onChange={updateTransientProps.bind(null, { tc_accepted: !transient.tc_accepted })} />
              <div className="pl-8">{checkBoxMessage(updateTransientProps)}</div>
            </RowView>
            <Button.Primary
              disabled={disableButton}
              processing={transient?.processing}
              onClick={activateAccount.bind(null, transient)}>
              {I18n.t('general:activate')}
            </Button.Primary>
          </Block>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

SignUp.propTypes = {
  application: PropTypes.object,
  activateAccount: PropTypes.func,
  transient: PropTypes.object,
  updateAplicationProps: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func
};

SignUp.defaultProps = {
  application: {},
  activateAccount: mockFunction,
  transient: {},
  updateAplicationProps: mockFunction,
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction
};

export default SignUp;
