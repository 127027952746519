import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Page, Searchbar } from 'framework7-react';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { PAGE_NAMES } from 'Constants';
import { AppHeader } from 'Components';
import { NavigationService } from 'Services';
import { debounce, mockFunction } from 'Helpers';

const handleSearch = debounce((updateTransientProps, postCodes, e) => {
  const { value } = e.target;
  const trimmedValue = value.trim().toUpperCase();

  if (trimmedValue.length < 2) {
    return;
  }

  const filteredPostCodes = postCodes.filter(postCode => postCode?.startsWith(trimmedValue));

  updateTransientProps({ search: value, filteredPostCodes });
}, 500);

export const CheckInList = ({ postCodes = [], updateTransientProps = mockFunction, transient = {} }) => {
  const { filteredPostCodes = [], selectedPostcodes = [] } = transient;

  const handlePostCodeClick = item => {
    const updatedPostcodes = selectedPostcodes.includes(item)
      ? selectedPostcodes.filter(postcode => postcode !== item)
      : [...selectedPostcodes, item];

    updateTransientProps({ selectedPostcodes: updatedPostcodes });
  };

  return (
    <Page id="check-in-postcodes">
      <AppHeader
        user
        goBack
        title={I18n.t('campaign:checkIn')}
        handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.CHECKIN })}
      />
      <div className="title">{I18n.t('checkIn:postcode')}</div>
      <RowView paddingHorizontal={16}>
        <Searchbar
          onChange={handleSearch.bind(null, updateTransientProps, postCodes)}
          onClickClear={updateTransientProps.bind(null, { search: '' })}
          placeholder={I18n.t('checkIn:searchPlaceHolder')}
        />
      </RowView>
      {transient?.filteredPostCodes &&
        transient?.search?.length &&
        filteredPostCodes.map((item, index) => (
          <List key={index}>
            <ListItem
              checked={selectedPostcodes?.includes(item)}
              checkbox={true}
              onClick={handlePostCodeClick.bind(null, item)}>
              {item}
            </ListItem>
          </List>
        ))}
    </Page>
  );
};

CheckInList.propTypes = {
  postCodes: PropTypes.array,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func
};

export default CheckInList;
