import PropType from 'prop-types';
import React, { useRef, useState } from 'react';

import { I18n } from 'Locales';
import { isSmallDevice } from 'Helpers';
import { SIGNATURE_COLORS } from 'Constants';
import { ColumnView, RowView } from 'Containers';

import Signature from '../../shared/Signature';
import { componentPropTypes } from '../../shared';

const updateSignature = (updateValue, component_type, agreementValue, e) =>
  updateValue({ component: component_type, value: e, agreement: agreementValue });

const updateAgreement = (updateValue, component_type, value, e) =>
  updateValue({
    component: component_type,
    value,
    agreement: e.target.checked
  });

const FlowSignature = props => {
  const {
    agreementValue,
    component_type,
    currentPageIndex,
    description,
    id,
    mode,
    name,
    shouldShowError,
    updateValue,
    userFullName,
    value
  } = props;

  const ref = useRef(null);
  const [sheetVisible, setSheetVisible] = useState(false);

  return (
    <div ref={ref}>
      <ColumnView paddingHorizontal={16} marginBottom={15}>
        <RowView justifyContent="flex-start" paddingBottom={8}>
          <span style={{ ...(shouldShowError && { color: SIGNATURE_COLORS.ERROR }) }}>
            <b>{name}</b>
          </span>
        </RowView>

        <RowView flexWrap={isSmallDevice ? 'wrap' : 'nowrap'} justifyContent={'flex-start'} paddingBottom={8}>
          <Signature
            shouldShowError={shouldShowError}
            currentPageIndex={currentPageIndex}
            updateSignature={updateSignature.bind(null, updateValue, component_type, agreementValue)}
            value={value}
            description={description}
            parentRef={ref}
            setSheetVisible={setSheetVisible}
            sheetVisible={sheetVisible}
          />

          <ColumnView justifyContent={'flex-start'} paddingTop={8}>
            <RowView justifyContent={'flex-start'}>
              <label style={{ ...(shouldShowError && { color: SIGNATURE_COLORS.ERROR }) }}>{description}</label>
            </RowView>

            {mode !== 'donor' && (
              <>
                <RowView backgroundColor={SIGNATURE_COLORS.darkGrey} height={1} marginVertical={10}></RowView>
                <ColumnView alignItems={'stretch'}>
                  <RowView justifyContent={'flex-start'}>
                    <span style={{ ...(shouldShowError && { color: SIGNATURE_COLORS.ERROR }) }}>
                      <b>{I18n.t('flow:summary.validity')}</b>
                    </span>
                  </RowView>

                  <RowView justifyContent={'flex-start'}>
                    <label className={`checkbox ${value === '' ? 'disabled' : ''}`}>
                      <input
                        id={`agreement-checkbox-${id}`}
                        type="checkbox"
                        disabled={value === ''}
                        checked={agreementValue}
                        onClick={updateAgreement.bind(null, updateValue, component_type, value)}
                      />
                      <i className="icon-checkbox"></i>
                    </label>

                    <RowView paddingLeft={38} justifyContent={'flex-start'}>
                      <label
                        htmlFor={`agreement-checkbox-${id}`}
                        style={{ ...(shouldShowError && { color: SIGNATURE_COLORS.ERROR }) }}>
                        {I18n.t('flow:summary.signatureValidity', {
                          userFullName,
                          interpolation: { escapeValue: false }
                        })}
                      </label>
                    </RowView>
                  </RowView>
                </ColumnView>
              </>
            )}
          </ColumnView>
        </RowView>
      </ColumnView>
    </div>
  );
};

FlowSignature.propTypes = {};

FlowSignature.propTypes = {
  ...componentPropTypes,
  currentPageIndex: PropType.number,
  mode: PropType.string,
  updateAgreement: PropType.func,
  updateSignature: PropType.func,
  userFullName: PropType.string,
  value: PropType.string
};

export default FlowSignature;
