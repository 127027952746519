import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { Actions, ActionsButton, ActionsGroup, Page, Popup } from 'framework7-react';

import { I18n } from 'Locales';
import { f7Colors } from 'Theme';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { CalendarMonth, Documents, Warning } from 'Icons';
import { AppHeader, Bubble, PassFlowModal } from 'Components';
import { REGIONS, RESULT_STATUS_TYPES, PAGE_NAMES } from 'Constants';
import {
  handleOnChangeWithValidations,
  mockFunction,
  phoneNumberSanitization,
  sortedDates,
  validations
} from 'Helpers';

import './style.scss';

const options = ['SMS', 'Email'];

const submittedResult = result =>
  result.state !== RESULT_STATUS_TYPES.INCOMPLETE && result.state !== RESULT_STATUS_TYPES.INACTIVE;

// When incomplete is true and state is inactive we'll consider the result incomplete
const incompletedResult = result =>
  result.state === RESULT_STATUS_TYPES.INCOMPLETE ||
  (result.state === RESULT_STATUS_TYPES.INACTIVE && result.incomplete);

// When status is inactive and incomplete is false we'll consider the result inactive
const inactiveResult = result => result.state === RESULT_STATUS_TYPES.INACTIVE && !result.incomplete;

const handleFileUpload = (uploadResultPicture, id, e) => {
  const { files } = e.target;
  if (!files?.length) return;
  uploadResultPicture({ file: files[0], id });
};

const onButtonClick = ref => ref && ref.click();

const handleRef = (files, id, el) => (files.current[id] = el);

const handleAction = ({ mainAction, setShowActionsMenu }) => {
  mainAction();
  setShowActionsMenu(null);
};

const renderResultRow = (
  {
    files = {},
    setShowActionsMenu = mockFunction,
    showActionsMenu = null,
    updateTransientProps = mockFunction,
    uploadResultPicture = mockFunction,
    user = {}
  },
  item = {}
) => {
  const resultType = {
    [RESULT_STATUS_TYPES.OFFLINE]: I18n.t('reports:state:offline'),
    [RESULT_STATUS_TYPES.INCOMPLETE]: I18n.t('reports:state:incomplete'),
    [RESULT_STATUS_TYPES.INACTIVE]: I18n.t('reports:state:inactive')
  };

  const determineResultType = result => {
    if (result.state === RESULT_STATUS_TYPES.OFFLINE) return RESULT_STATUS_TYPES.OFFLINE;
    if (result.state === RESULT_STATUS_TYPES.INCOMPLETE) return RESULT_STATUS_TYPES.INCOMPLETE;

    if (result.state === RESULT_STATUS_TYPES.INACTIVE) {
      return result.incomplete ? RESULT_STATUS_TYPES.INCOMPLETE : RESULT_STATUS_TYPES.INACTIVE;
    }
  };

  return (
    <tr key={item.id}>
      <td className="label-cell">{new Date(item.submitted_at).toLocaleString()}</td>
      <td className="label-cell">{item.campaign}</td>
      <td className="label-cell">{resultType[determineResultType(item)] ?? I18n.t('reports:state:submitted')}</td>
      <td className="numeric-cell">{item.id}</td>
      <td className="numeric-cell">{item.customer_full_name}</td>
      <td className="numeric-cell">
        <input
          type="file"
          ref={handleRef.bind(null, files, item.id)}
          className="input"
          onChange={handleFileUpload.bind(null, uploadResultPicture, item.id)}
          slot={'fixed'}
          // we accept pdf as a hack to let user to upload a new photo(not from galery)
          accept="image/*, application/pdf"
        />
        {item.state === RESULT_STATUS_TYPES.INCOMPLETE && REGIONS.UK.includes(user.country) && !item.media_url ? (
          <div className="text-primary cell-link" onClick={setShowActionsMenu.bind(null, item.id)}>
            {I18n.t('reports:actions')}
          </div>
        ) : (
          item.state === RESULT_STATUS_TYPES.INCOMPLETE && (
            <div
              className="text-primary cell-link"
              onClick={updateTransientProps.bind(null, {
                resendModal: true,
                resend_id: item.id,
                donor_name: item.customer_full_name
              })}>
              {I18n.t('reports:resendLink')}
            </div>
          )
        )}
        {!item.media_url && item.state !== RESULT_STATUS_TYPES.INCOMPLETE && REGIONS.UK.includes(user.country) && (
          <div className="text-primary cell-link" onClick={() => onButtonClick(files.current[item.id])}>
            {I18n.t('reports:upload')}
          </div>
        )}
      </td>
      <td>{item.state === RESULT_STATUS_TYPES.INCOMPLETE && <Warning />}</td>
      <td>
        <Actions opened={showActionsMenu === item.id} onActionsClosed={setShowActionsMenu.bind(null, null)}>
          <ActionsGroup className="actions-group">
            <ActionsButton
              onClick={updateTransientProps.bind(null, {
                resendModal: true,
                resend_id: item.id,
                donor_name: item.customer_full_name
              })}>
              <RowView>{I18n.t('reports:resendLink')}</RowView>
            </ActionsButton>
            <ActionsButton
              onClick={handleAction.bind(null, {
                setShowActionsMenu,
                mainAction: onButtonClick.bind(null, files.current[item.id])
              })}>
              <RowView>{I18n.t('reports:uploadPicture')}</RowView>
            </ActionsButton>
          </ActionsGroup>
          <ActionsGroup>
            <ActionsButton color={f7Colors.red}>
              <RowView>{I18n.t('general:cancel')}</RowView>
            </ActionsButton>
          </ActionsGroup>
        </Actions>
      </td>
    </tr>
  );
};

const reshapeCampaign = ({ setCampaignFilter }, campaign) => ({
  ...campaign,
  label: campaign.name,
  onClick: setCampaignFilter.bind(null, campaign.name)
});

const Reports = ({
  campaigns,
  isOffline,
  offlineResults,
  recentResults,
  resendLinkToDonor,
  reset,
  transient,
  updateTransientProps,
  updateTransientPropWithValidations,
  uploadResultPicture,
  user
}) => {
  const {
    donor_email_address = '',
    donor_email_address_has_error,
    donor_phone_number = '',
    donor_phone_number_has_error,
    resend_id,
    resendLinkLoading = false,
    resendModal,
    sending_option = ''
  } = transient;

  const files = useRef({});

  const [sortByDate, setSortByDate] = useState(true);
  const [dateFilter, setDateFilter] = useState('today');
  const [salesFilter, setSalesFilter] = useState('allSales');
  const [showActionsMenu, setShowActionsMenu] = useState(null);
  const [campaignFilter, setCampaignFilter] = useState(I18n.t('reports:allCampaigns'));

  const allCampaignsOption = {
    label: I18n.t('reports:allCampaigns'),
    onClick: setCampaignFilter.bind(null, I18n.t('reports:allCampaigns'))
  };

  const campaignsOptions = [allCampaignsOption, ...campaigns.map(reshapeCampaign.bind(null, { setCampaignFilter }))];

  const isProfileCompleted = user.profile_complete;
  const isEmailOption = sending_option === 'Email';
  const hasEmailError = !donor_email_address || donor_email_address_has_error;
  const hasPhoneError = !donor_phone_number || donor_phone_number_has_error;
  const disableButton = (isEmailOption ? hasEmailError : hasPhoneError) || resendLinkLoading;

  const result = {
    allSales: [...offlineResults, ...recentResults],
    offlineSales: offlineResults,
    submittedSales: recentResults.filter(submittedResult),
    inactiveSales: recentResults.filter(inactiveResult),
    incompleteSales: recentResults.filter(incompletedResult)
  };

  const donorSubmitKey = sending_option === 'Email' ? 'donor_email_address' : 'donor_phone_number';

  const allResults = sortedDates(result[salesFilter], sortByDate).filter(result => result[dateFilter]);
  const filteredResults =
    campaignFilter === I18n.t('reports:allCampaigns')
      ? allResults
      : allResults.filter(result => result.campaign === campaignFilter || campaignFilter === '');

  const sendingOptionsConfig = {
    Email: [
      {
        label: I18n.t('flow:email'),
        type: 'text',
        placeholder: I18n.t('flow:emailAddress'),
        value: donor_email_address || '',
        onChange: handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'donor_email_address', [
          validations.isRequired,
          validations.emailValidation
        ])
      }
    ],
    SMS: [
      {
        label: I18n.t('flow:sms'),
        type: 'text',
        placeholder: I18n.t('flow:phoneNumber'),
        value: donor_phone_number || '',
        onInput: phoneNumberSanitization,
        onChange: handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'donor_phone_number', [
          validations.isRequired,
          validations.phoneNumber
        ])
      }
    ]
  };

  const filterLabels = {
    sales: {
      allSales: I18n.t('reports:allSales'),
      offlineSales: I18n.t('reports:offlineSales'),
      submittedSales: I18n.t('reports:submittedSales'),
      inactiveSales: I18n.t('reports:inactiveSales'),
      incompleteSales: I18n.t('reports:incompleteSales')
    },
    date: {
      today: I18n.t('reports:today'),
      thisWeek: I18n.t('reports:thisWeek'),
      lastWeek: I18n.t('reports:lastWeek')
    }
  };

  const getFilterLabel = (type, filter) => filterLabels[type][filter] || '';

  const salesItems = [
    {
      onClick: setSalesFilter.bind(null, 'inactiveSales'),
      label: I18n.t('reports:inactiveSales')
    },
    {
      onClick: setSalesFilter.bind(null, 'incompleteSales'),
      label: I18n.t('reports:incompleteSales')
    },
    {
      onClick: setSalesFilter.bind(null, 'submittedSales'),
      label: I18n.t('reports:submittedSales')
    },
    {
      onClick: setSalesFilter.bind(null, 'offlineSales'),
      label: I18n.t('reports:offlineSales')
    },
    {
      onClick: setSalesFilter.bind(null, 'allSales'),
      label: I18n.t('reports:allSales')
    }
  ];

  const dateItems = [
    {
      onClick: setDateFilter.bind(null, 'lastWeek'),
      label: I18n.t('reports:lastWeek')
    },
    {
      onClick: setDateFilter.bind(null, 'thisWeek'),
      label: I18n.t('reports:thisWeek')
    },
    {
      onClick: setDateFilter.bind(null, 'today'),
      label: I18n.t('reports:today')
    }
  ];

  return (
    <Page id={'reports'}>
      <AppHeader
        user
        goBack
        title={I18n.t('reports:title')}
        handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.SETTINGS })}
      />
      <ColumnView paddingHorizontal={20} gap={20} marginTop={20}>
        <RowView justifyContent={'space-between'} height={'36px'}>
          <div className="title-md">{I18n.t('reports:dateRange')}</div>
          <Bubble items={dateItems} icon={CalendarMonth} label={getFilterLabel('date', dateFilter)} />
        </RowView>
        <RowView justifyContent={'space-between'} height={'36px'}>
          <div className="title-md">{I18n.t('reports:resultStatus')}</div>
          <Bubble items={salesItems} icon={Documents} label={getFilterLabel('sales', salesFilter)} />
        </RowView>
        {isProfileCompleted && campaigns?.length !== 0 && (
          <RowView justifyContent={'space-between'} height={'36px'}>
            <div className="title-md">{I18n.t('reports:campaignFilter')}</div>
            <Bubble items={campaignsOptions} label={campaignFilter} />
          </RowView>
        )}
        <RowView justifyContent={'space-between'} height={'36px'}>
          <div className="title-md">{I18n.t('reports:totalSales')}</div>
          <RowView justifyContent={'center'} width={'auto'} paddingRight={8}>
            <span className="counter">{filteredResults?.length}</span>
          </RowView>
        </RowView>
      </ColumnView>
      <div className="px-16">
        <div className="data-table data-table-init card">
          <div className="card-content">
            <table>
              <thead>
                <tr>
                  <th
                    className="label-cell sortable-cell sortable-cell-active"
                    onClick={setSortByDate.bind(null, !sortByDate)}>
                    {I18n.t('reports:time')}
                  </th>
                  <th className="label-cell">{I18n.t('reports:campaign')}</th>
                  <th className="label-cell">{I18n.t('reports:status')}</th>
                  <th className="numeric-cell">{I18n.t('reports:transactionId')}</th>
                  <th className="numeric-cell">{I18n.t('reports:name')}</th>
                </tr>
              </thead>
              <tbody>
                {filteredResults?.map(
                  renderResultRow.bind(null, {
                    files,
                    setShowActionsMenu,
                    showActionsMenu,
                    updateTransientProps,
                    uploadResultPicture,
                    user
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Popup opened={resendModal && !isOffline} onPopupClosed={updateTransientProps.bind(null, { resendModal: false })}>
        <PassFlowModal
          title={I18n.t('reports:passFlowToDonor')}
          subtitle={I18n.t('reports:passFlowToDonorSubtitle')}
          donorName={transient?.donor_name}
          isOpen={resendModal && !isOffline}
          onClose={reset}
          sendingOption={sending_option}
          options={options}
          sendingOptionsConfig={sendingOptionsConfig}
          handleChange={(field, value) => updateTransientProps({ [field]: value })}
          handleSubmit={() =>
            resendLinkToDonor({
              id: resend_id,
              [donorSubmitKey]: transient[donorSubmitKey]
            })
          }
          disableButton={disableButton}
        />
      </Popup>
    </Page>
  );
};

Reports.propTypes = {
  campaigns: PropTypes.array,
  isOffline: PropTypes.bool,
  offlineResults: PropTypes.array,
  recentResults: PropTypes.array,
  resendLinkToDonor: PropTypes.func,
  reset: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  uploadResultPicture: PropTypes.func,
  user: PropTypes.object
};

Reports.defaultProps = {
  campaigns: [],
  isOffline: false,
  offlineResults: [],
  recentResults: [],
  resendLinkToDonor: mockFunction,
  reset: mockFunction,
  transient: {
    donor_email_address: '',
    donor_phone_number: '',
    resendLinkLoading: false,
    sending_option: ''
  },
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction,
  uploadResultPicture: mockFunction,
  user: {}
};

export default Reports;
