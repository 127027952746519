import { I18n } from 'Locales';
import { isValidIBAN } from './';

export default {
  accountNumber: {
    isValid: value => /^[0-9]{8,8}$/.test(value),
    message: I18n.t('validations:accountNumber'),
    messageKey: 'validations:accountNumber'
  },

  birthDepartment: {
    isValid: value => !value.trim() || /^\d{1,3}$/.test(value),
    message: I18n.t('validations:birthDepartment'),
    messageKey: 'validations:birthDepartment'
  },

  emailValidation: {
    isValid: value =>
      //eslint-disable-next-line no-useless-escape, max-len
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ),
    message: I18n.t('validations:emailValidation'),
    messageKey: 'validations:emailValidation'
  },

  fixed15: {
    isValid: value => /^[a-zA-Z0-9]{15,15}$/.test(value),
    message: I18n.t('validations:fixed15'),
    messageKey: 'validations:fixed15'
  },

  passwordValidation: {
    isValid: value =>
      //eslint-disable-next-line no-useless-escape, max-len
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/.test(
        value
      ),
    message: I18n.t('validations:passwordIsNotValid'),
    messageKey: 'validations:passwordIsNotValid'
  },

  isIBAN: {
    isValid: value => isValidIBAN(value),
    message: I18n.t('validations:iban'),
    messageKey: 'validations:iban'
  },

  isRequired: {
    isValid: value => value?.trim() !== '',
    message: I18n.t('validations:required'),
    messageKey: 'validations:required'
  },

  isObjectRequired: {
    isValid: object => Object.keys(object).length > 0,
    message: I18n.t('validations:required'),
    messageKey: 'validations:required'
  },

  lettersOnly: {
    isValid: value => /^[\p{L}\p{M}'"\s]+$/u.test(value?.trim()),
    message: I18n.t('validations:lettersOnly'),
    messageKey: 'validations:lettersOnly'
  },

  phoneNumber: {
    isValid: value => /^[0-9]+(\.[0-9]+)?(?:\([0-9]+(\.[0-9]+)?\))?$/.test(value),
    message: I18n.t('validations:phoneNumber'),
    messageKey: 'validations:phoneNumber'
  },

  sortCode: {
    isValid: value => /^[0-9]{6,6}$/.test(value),
    message: I18n.t('validations:sortCode'),
    messageKey: 'validations:sortCode'
  },

  nationalInsuranceNumber: {
    isValid: value => {
      if (!value.trim()) return true;
      return /^[A-Za-z]{2}\d{6}[A-Za-z]$/.test(value);
    },
    message: I18n.t('validations:nationalInsuranceNumber'),
    messageKey: 'validations:nationalInsuranceNumber'
  },

  maxDateValidation: {
    isValid: value => {
      const inputDate = new Date(value);
      const currentDate = new Date();

      return !isNaN(inputDate) && inputDate < currentDate;
    },
    message: I18n.t('validations:maxDate'),
    messageKey: 'validations:maxDate'
  },

  minDateValidation: {
    isValid: value => {
      const inputDate = new Date(value);
      const currentDate = new Date();

      return !isNaN(inputDate) && inputDate > currentDate;
    },
    message: I18n.t('validations:minDate'),
    messageKey: 'validations:minDate'
  }
};
