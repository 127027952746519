import React from 'react';
import PropTypes from 'prop-types';
import { Block, List, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { PAGE_NAMES } from 'Constants';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { AppHeader, Button, StrengthBar, TextField } from 'Components';
import { handleOnChangeWithValidations, mockFunction, validations } from 'Helpers';

import './style.scss';

const updateUserPassword = ({ updateUser, transient, navigateToSettings }) => {
  const newUser = {
    data: {
      attributes: {
        current_password: transient.current_password,
        password: transient.password,
        password_confirmation: transient.password_confirmation
      }
    }
  };
  updateUser(newUser, navigateToSettings);
};

const navigateToSettings = () => {
  NavigationService.navigate({
    name: PAGE_NAMES.SETTINGS
  });
};

const getIconColor = (fieldHasError, hasFieldValue) => (fieldHasError ? 'red' : hasFieldValue ? 'blue' : 'gray');

export const ChangePassword = ({ transient, updateTransientPropWithValidations, updateUser }) => {
  const disableButton =
    transient.password_has_error ||
    transient.current_password_has_error ||
    transient.password_confirmation_has_error ||
    transient.password !== transient.password_confirmation ||
    !transient.password ||
    !transient.current_password ||
    !transient.password_confirmation;

  const isPasswordMatching = {
    isValid: value => value === transient.password,
    message: I18n.t('validations:passwordDontMatch'),
    messageKey: 'validations:passwordDontMatch'
  };

  return (
    <Page id="change-password-settings">
      <AppHeader
        user
        goBack
        title={I18n.t('general:changePassword')}
        handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.SETTINGS })}
      />
      <ColumnView minHeight={'calc(100% - 74px)'} alignItems={'flex-start'} justifyContent={'flex-start'}>
        <RowView>
          <List form className="w-full">
            <TextField
              label={I18n.t('settings:oldPassword')}
              type="password"
              value={transient?.current_password || ''}
              onChange={handleOnChangeWithValidations.bind(
                null,
                updateTransientPropWithValidations,
                'current_password',
                [validations?.isRequired]
              )}
              errorMessageForce={transient?.current_password_has_error}
              errorMessage={I18n.t(transient?.current_password_error_message_key)}
              icon="material:check"
              iconColor={getIconColor(transient?.current_password_has_error, transient?.current_password)}
            />
            <TextField
              label={I18n.t('settings:newPassword')}
              type="password"
              value={transient?.password || ''}
              onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'password', [
                validations?.isRequired,
                validations?.passwordValidation
              ])}
              errorMessageForce={transient?.password_has_error}
              errorMessage={I18n.t(transient?.password_error_message_key)}
              icon="material:check"
              iconColor={getIconColor(transient?.password_has_error, transient?.password)}
            />
            <TextField
              label={I18n.t('settings:confirmPassword')}
              type="password"
              value={transient?.password_confirmation || ''}
              onChange={handleOnChangeWithValidations.bind(
                null,
                updateTransientPropWithValidations,
                'password_confirmation',
                [validations?.isRequired, validations?.passwordValidation, isPasswordMatching]
              )}
              icon="material:check"
              iconColor={getIconColor(transient?.password_confirmation_has_error, transient?.password_confirmation)}
              errorMessageForce={transient?.password_confirmation_has_error}
              errorMessage={I18n.t(transient?.password_confirmation_error_message_key)}
            />
            <Block className="mt-20 mb-0">
              <StrengthBar password={transient?.password} />
              <div className={'password-requirement mt-20'}>{I18n.t('settings:passwordRequirement')}</div>
            </Block>
          </List>
        </RowView>
        <RowView gap={24} paddingHorizontal={20} paddingBottom={8} marginTop={'auto'}>
          <Button.Outline width={'100%'} onClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.SETTINGS })}>
            {I18n.t('general:cancel')}
          </Button.Outline>
          <Button.Primary
            width={'100%'}
            onClick={updateUserPassword.bind(null, {
              updateUser,
              transient,
              navigateToSettings
            })}
            disabled={disableButton}>
            {I18n.t('general:done')}
          </Button.Primary>
        </RowView>
      </ColumnView>
    </Page>
  );
};

ChangePassword.propTypes = {
  transient: PropTypes.object,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func
};

ChangePassword.defaultProps = {
  transient: {},
  updateTransientPropWithValidations: mockFunction,
  updateUser: mockFunction
};

export default ChangePassword;
