import React from 'react';
import PropTypes from 'prop-types';

import { checkSearchParams } from 'Helpers';

const Privacy = ({ language, user }) => {
  const { locale: userLocaleFromSearch, mc_name, mc_email } = checkSearchParams(window.location.search);
  const { mc_email: mc_email_tc_params, mc_name: mc_name_tc_params } = checkSearchParams(user.tc_params);
  const resolvedUserLocale = user.locale !== language ? language : userLocaleFromSearch;

  return (
    <div className="padding-agreements">
      {(resolvedUserLocale === 'en' || user.locale === 'en') && (
        <div>
          <h3>Opening Statement</h3>
          <p>
            We do not require your consent for the processing of your Personal Data, as we are processing it for the
            Performance of Contracts to which you are party to (i.e. the Term of Service / Use for DataByte). Your
            Personal Data will be processed for as long as is required in order to perform this contract.
          </p>
          <p>
            The Firm Organisation Limited (“TFO Group”) fully respects your right to privacy and we are committed to
            ensuring that your privacy is protected. You are entitled to the protection of your personal information.
            This data may relate to your name, telephone number, email address or any other information relating to you.
          </p>
          <p>
            Please read the following privacy statement to learn more about how we collect, store, use and disclose
            information about you when you interact with TFO Group and specifically DataByte. This Policy applies to all
            your Personal Data collected by (or on behalf of) TFO Group (which includes its group companies), together
            referred to in this Policy as “TFO Group”, “we”, “us” and “our”.
          </p>
          <h3>What does this Privacy Policy cover?</h3>
          <p>
            This Privacy Policy covers our treatment of your personal information that marketing companies and brand
            ambassadors gather on our behalf when we are providing customer acquisition services on behalf of our
            clients (the “Services”). In the course of the provision of the Services to our clients, we (or marketing
            companies and brand ambassadors acting on our behalf) gather various types of information about customers,
            including information that identifies you as an individual (“Personal Data”) as explained in more detail
            below.
          </p>
          <p>
            The DataByte Platform is not intended for children and we do not knowingly collect data relating to
            children.
          </p>
          <h3>Who is responsible for the processing of your Personal Data?</h3>
          <p>
            The entity which is responsible for the processing of your Personal Data is TFO Group whose registered
            office is 32 Merrion Street Dublin 2, Ireland. The identity of the Data Controller for your Personal Data
            will be the Administrator as defined in your terms of service and is the Marketing Company you are
            contracted to.
          </p>
          <h3>Who can you contact if you have Questions or Requests?</h3>
          <p>
            Our Data Protection Officer will handle your questions or requests relating to this Policy or your Personal
            Data. For any questions or requests or complaints concerning the application of this Policy or to exercise
            your rights, as described in this Policy, you may contact us at the below:
          </p>
          <p>
            <b>Name: {mc_name || mc_name_tc_params}</b>
          </p>
          <p>
            <b>Position: </b> Data Protection Officer (Group)
          </p>
          <p>
            <b>Email: </b>
            {mc_email || mc_email_tc_params}
          </p>
          <h3>Key Principles</h3>
          <p>
            We value your Personal Data entrusted to us and we are committed to processing your Personal Data in a fair,
            transparent and secure way. The key principles that TFO Group applies are as follows:
          </p>
          <ul>
            <li>
              <b>Lawfulness: </b> we will only collect your Personal Data in a fair, lawful and transparent manner.
            </li>
            <li>
              <b>Data minimisation</b>: we will limit the collection of your Personal Data to what is directly relevant
              and necessary for the purposes for which they have been collected.
            </li>
            <li>
              <b>Purpose limitation</b>: we will only collect your Personal Data for specified, explicit and legitimate
              purposes and not process your Personal Data further in a way incompatible with those purposes.
            </li>
            <li>
              <b>Accuracy</b>: we will keep your Personal Data accurate and up to date.
            </li>
            <li>
              <b>Data security and protection</b>: we will implement technical and organisational measures to ensure an
              appropriate level of data security and protection considering, among others, the nature of your Personal
              Data to be protected. Such measures provide for the prevention of any unauthorised disclosure or access,
              accidental or unlawful destruction or accidental loss, or alteration and any other unlawful form of
              Processing.
            </li>
            <li>
              <b>Access and rectification</b>: we will process your Personal Data in line with your legal rights.
            </li>
            <li>
              <b>Retention limitation</b>: we will retain your Personal Data in a manner consistent with the applicable
              data protection laws and regulations and for no longer than is necessary for the purposes for which it has
              been collected.
            </li>
            <li>
              <b>Protection for international transfers</b>: we will ensure that any of your Personal Data transferred
              outside the EEA is adequately protected.
            </li>
            <li>
              <b>Safeguards re third parties</b>: we will ensure that Personal Data access by (and transfers to) third
              parties are carried out in accordance with applicable law and with suitable contractual safeguards.{' '}
            </li>
            <li>
              <b>Lawfulness of direct marketing and cookies</b>: if we send you promotional materials or place cookies
              on your computer, we will ensure that we do so in accordance with applicable law.
            </li>
          </ul>
          <h3>What information do we collect?</h3>
          <p>
            We receive and store information that you provide to us, via our network of independently contracted
            Marketing Companies and their independently contracted Brand Ambassadors, that is necessary for the
            provision of our Services to our clients. We may collect, use, store and transfer different kinds of
            personal data about you which we have grouped together as follows:
          </p>
          <ul>
            <li>
              <b>Identity Data</b> including first name, maiden name, last name, username or similar identifier, marital
              status, title, PPS number date of birth and gender;
            </li>
            <li>
              <b>Contact Data</b> including residential address, email address and telephone numbers;
            </li>
            <li>
              <b>Financial Data</b> including bank account details and credit card details;
            </li>
            <li>
              <b>Transaction Data</b> including details about payments to and from you and other details of products and
              services you have purchased from us;
            </li>
            <li>
              <b>Technical Data</b> including internet protocol (IP) address, your login data, browser type and version,
              time zone setting and location, browser plug-in types and versions, operating system and platform and
              other technology on the devices you use to access this website;
            </li>
            <li>
              <b>Usage Data</b> including information about how you use our website, products and services; and
            </li>
            <li>
              <b>Marketing and Communications Data</b> including your preferences in receiving marketing from us and our
              third parties and your communication preferences.
            </li>
          </ul>
          <p>
            We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose.
            Aggregated Data may be derived from your personal data but is not considered personal data in law as this
            data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to
            calculate the percentage of users accessing a specific website feature. However, if we combine or connect
            Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the
            combined data as personal data which will be used in accordance with this privacy notice.
          </p>
          <p>
            Generally, we do not process any Special Categories of Personal Data about you, this includes details about
            your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political
            opinions, trade union membership, information about your health and genetic and biometric data). In some
            instances, you may sign contracts or documents using electronic signature tools (eIDAS) and these tools can
            use biometric data in the form of how you write your signature as this is unique to you. This biometric
            information is secured and encrypted, to the extend that we do not use it or process it and it can only be
            accessed if there is a dispute as to your identity.
          </p>
          <p>
            Any information which you provide to us is not made available to any third parties without your permission
            and is used by TFO Group only in line with the purpose for which you provided it and in accordance with the
            GDPR.
          </p>
          <h3>How do we use the information?</h3>
          <p>
            We will only process your Personal Data for specified, explicit and legitimate purposes and we will not
            process your Personal Data further in a way that is incompatible with those purposes.
          </p>
          <p>
            Such purposes include the operation and administration of our customer acquisition business, the improvement
            of our products and services, the improvement of your visit on one of our websites or portals, processing
            invoices for customer acquisition services, paying commissions to the sales force and the production of
            client KPI reports.{' '}
          </p>
          <p>
            We may also use the information you send to us to communicate with you via email and, possibly, other means,
            regarding events or services we think may be of interest to you or to send you a newsletter, if you have
            consented to such contact. However, you will always be able to opt-out of such communications at any time
            (see the “How can I Exercise my Data Subject Rights” section below).
          </p>
          <h3>What is the legal basis for processing Personal Data?</h3>
          <p>
            The principal legal basis for this processing is our legitimate interest in the operation and administration
            of customer acquisition business as well as our legitimate interest in marketing and promoting our business.
            In addition, please note that in accordance with applicable data protection law, your Personal Data can be
            processed if:
          </p>
          <ul>
            <li>
              you have given us your consent for the purposes of the Processing. For the avoidance of doubt, you will
              always have the right to withdraw your consent at any time;
            </li>
            <li>it is necessary for the performance of a contract to which you are a party; or</li>
            <li>it is required by law.</li>
          </ul>
          <h3>How do we share your Personal Data?</h3>
          <p>
            We may have to share your Personal Data with our clients who have commissioned the marketing or direct sales
            campaign in order to provide the Services. We do not share your personal information with anyone except
            where consent has been provided or is necessary for the execution of a contract or is required by law.
          </p>
          <h3>Is my Personal Data secure?</h3>
          <p>
            We implement security safeguards designed to protect your data. We regularly monitor our systems for
            possible vulnerabilities and attacks. However, we cannot guarantee the security of any information that we
            receive. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of
            any of our physical, technical, or managerial safeguards despite our best efforts to prevent this.
          </p>
          <p>
            We use appropriate technical, organisational and administrative security measures to protect any information
            we hold in our records from loss, misuse, and unauthorized access, disclosure, alteration and destruction.
          </p>
          <p>
            These measures have been designed taking into account our IT infrastructure, the potential impact on your
            privacy and the costs involved and in accordance with current industry standards and practice.
          </p>
          <p>
            Your Personal Data will only be processed by a third-party data processor if that data processor agrees to
            comply with those technical and organisational data security measures.
          </p>
          <p>
            Maintaining data security means protecting the confidentiality, integrity and availability of your Personal
            Data:{' '}
          </p>
          <ol type="a">
            <li>
              <b>Confidentiality</b>: we will protect your Personal Data from unwanted disclosure to third parties.
            </li>
            <li>
              <b>Integrity</b>: we will protect your Personal Data from being modified by unauthorised third parties.
            </li>
            <li>
              <b>Availability</b>: we will ensure that authorized parties are able to access your Personal Data when
              needed.
            </li>
          </ol>
          <p>
            Our data security procedures include: access security, backup systems, monitoring, review and maintenance,
            management of security incidents and continuity, etc.
          </p>
          <h3>How long will we use your Personal Data for?</h3>
          <p>
            We will only retain your Personal Data for as long as necessary to fulfil the purposes we collected it for,
            including for the purposes of satisfying any legal, accounting, or reporting requirements.
          </p>
          <p>
            In some circumstances you can ask us to delete your data: see Right to erasure below for further
            information.
          </p>
          <p>
            In some circumstances we may anonymise your Personal Data (so that it can no longer be associated with you)
            for research or statistical purposes in which case we may use this information indefinitely without further
            notice to you.{' '}
          </p>
          <h3>Information We Automatically Collect:</h3>
          <p>
            When you visit our Website, we collect certain information related to your device, such as your device’s IP
            address, referring website, what pages your device visited, and the time that your device visited our
            Website.
          </p>
          <p>
            (“IP”) address. Information collected by cookies and other similar technologies – we use various
            technologies to collect information which may include saving cookies to users’ computers. For further
            information, please see the section below headed “Cookies and other Tracking Technologies”.
          </p>
          <h3>Cookies and other tracking technologies</h3>
          <p>
            A "cookie" is a bite-sized piece of data that is stored on your computer's hard drive. They are used by
            nearly all websites and do not harm your system. We use them to track your activity to help ensure you get
            the smoothest possible experience when visiting our website. We can use the information from cookies to
            ensure we present you with options tailored to your preferences on your next visit. We can also use cookies
            to analyse traffic and for advertising purposes.
          </p>
          <p>
            If you want to check or change what types of cookies you accept, this can usually be altered within your
            browser settings. Please consult our Cookie Policy for more information about the type of cookies and
            tracking technologies that we use on this Website and why, and how to accept and reject them.
          </p>
          <h3>Disclosure of Personal Data</h3>
          <p>
            Depending on the purposes for which we collect your Personal Data, we may disclose it to the following
            categories of recipients, which will then process your Personal Data only for one of the following purposes:
          </p>
          <ol type="a">
            <li>
              Within our organisations:
              <ul>
                <li>Our authorised staff members;</li>
                <li>Our affiliates and subsidiary companies;</li>
              </ul>
            </li>
            <li>
              Third party business partners:
              <ul>
                <li>
                  The Marketing Companies and the Brand Ambassadors that conduct the customer acquisition business on
                  our behalf.
                </li>
                <li>
                  Our clients who have commissioned us to perform the applicable marketing customer acquisition
                  campaign.
                </li>
                <li>
                  Business partners: for example, trusted companies that may use your Personal Data to provide you with
                  the services and/or the products you requested and/or that may provide you with marketing materials
                  (provided that you have consented to receiving such marketing materials). Such business partners will
                  be subject to strict confidentiality.
                </li>
              </ul>
            </li>
            <li>
              Other third parties:
              <ul>
                <li>when required by law or as lawfully necessary to protect TFO Group:</li>
                <li>
                  to comply with the law, requests from authorities, court orders, legal procedures, obligations related
                  to the reporting and filing of information with authorities, etc.;
                </li>
                <li>to verify or enforce compliance with TFO Group’s policies and agreements; and</li>
                <li>to protect the rights, property or safety of TFO Group and/or its customers;</li>
                <li>
                  in connection with corporate transactions: in the context of a transfer or divestiture of all or a
                  portion of its business, or otherwise in connection with a merger, consolidation, change in control,
                  reorganisation or liquidation of all or part of TFO Group’s business.
                </li>
              </ul>
            </li>
          </ol>
          <h3>Marketing Communications</h3>
          <p>
            If you have consented to receiving certain promotional or marketing communications from us, you can opt-out
            of receiving such promotional or marketing communications from us at any time, by using the unsubscribe link
            in the emails communications we send, click the “Exercise your Rights” link available at the bottom of our
            Privacy Statement, or send us a message via our website.
          </p>
          <h3>Profiling</h3>
          Certain personal data may be used on an aggregated basis for the profiling of certain territories for the
          reason of territory management. The profiling that is conducted is done to assist the sales force in selecting
          and managing territories in which they operate. This enables us to allocate our resources in the most
          efficient manner for the purposes of targeting customer acquisition. We do not profile individual data
          subjects and data subjects are not subject to automated decision making. The envisaged consequences of such
          processing for the affected data subjects is minimal, only that the marketing companies and brand ambassadors
          that act on our behalf may be more or less likely to conduct our customer acquisition business in their
          territory depending on the results of the profiling.
          <h3>Transfer of Data outside the EEA</h3>
          <p>All processing is conducted within the EEA. </p>
          <h3>How can I Exercise my Data Subject Rights?</h3>
          <p>Under the General Data Protection Regulation, you have the following rights:</p>
          <p>
            <b>Right to object</b>: If we are using your data because we deem it necessary for our legitimate interests
            to do so, and you do not agree, you have the right to object. We will respond to your request within 30 days
            (although we may be allowed to extend this period in certain cases). Generally, we will only disagree with
            you if certain limited conditions apply.
          </p>
          <p>
            <b>Right to withdraw consent</b>: Where we have obtained your consent to process your Personal Data for
            certain activities, or consent to market to you, you may withdraw your consent at any time.
          </p>
          <p>
            <b>Right to Rectification</b>: if your Personal Data that we hold is inaccurate or incomplete, you have the
            right to request the rectification of your Personal Data.
          </p>
          <p>
            <b>Data Subject Access Requests</b>: Just so it's clear, you have the right to ask us to confirm what
            information we hold about you at any time, and to provide you with copies of that information. We will
            respond to your request within 30 days. At this point we may comply with your request or, additionally do
            one of the following
          </p>
          <ul>
            <li>we may ask you to verify your identity, or ask for more information about your request; and</li>
            <li>
              where we are legally permitted to do so, we may decline your request, but we will explain why if we do so.
            </li>
          </ul>
          <p>
            <b>Right to erasure</b>: In certain situations (for example, where we have processed your data unlawfully),
            you have the right to request us to "erase" your Personal Data. We will respond to your request within 30
            days (although we may be allowed to extend this period in certain cases) and will only disagree with you if
            certain limited conditions apply. If we do agree to your request, we will delete your data.
          </p>
          <p>
            <b>Right of data portability</b>: If you wish, you have the right to transfer your data from us to another
            data controller. We will help with this – either by directly transferring your data for you, or by providing
            you with a copy in a commonly used machine-readable format.
          </p>
          <p>
            <b>Right to lodge a complaint with a supervisory authority</b>: You also have the right to lodge a complaint
            with the Office of the Data Protection Commissioner.
          </p>
          <p>
            If your interests or requirements change, you can unsubscribe from part or all of our marketing content (for
            example job role emails or TFO Group newsletters) by clicking the unsubscribe link in the email, or by
            sending us a request via email.
          </p>
          <p>
            <b>Changing this Policy</b>
            We may need to change this Privacy Statement from time to time. We will alert you to material changes by,
            for example, placing a notice on our websites and/or by sending you an email (if you have registered your
            e-mail details with us) when we are required to do so by applicable law. You are responsible for
            periodically reviewing this Privacy Statement.
          </p>
          <p>This Privacy Statement was most recently updated on 28th May 2019.</p>
        </div>
      )}
      {(resolvedUserLocale === 'fr' || user.locale === 'fr') && (
        <div>
          <h3>Déclaration d'ouverture</h3>
          <p>
            Nous n'avons pas besoin de votre consentement pour le traitement de vos données personnelles, car nous les
            traitons pour l'exécution des contrats auxquels vous êtes partie (c'est-à-dire les Conditions de Service /
            Utilisation de DataByte). Vos données personnelles seront traitées aussi longtemps que nécessaire pour
            l'exécution du présent contrat.{' '}
          </p>
          <p>
            The Firm Organisation Limited ("Groupe TFO") respecte pleinement votre droit à la confidentialité et nous
            nous engageons à assurer la protection de votre vie privée. Vous avez droit à la protection de vos
            renseignements personnels. Ces données peuvent concerner votre nom, votre numéro de téléphone, votre adresse
            électronique ou toute autre information vous concernant.
          </p>
          <p>
            Veuillez lire la déclaration de confidentialité suivante pour en savoir plus sur la façon dont nous
            recueillons, stockons, utilisons et divulguons les informations vous concernant lorsque vous interagissez
            avec le Groupe TFO et plus particulièrement avec DataByte. La présente politique s'applique à tous vos
            renseignements personnels recueillis par (ou au nom du) Groupe TFO (qui comprend les sociétés de son
            groupe), désignés ensemble dans la présente politique par les termes " Groupe TFO ", " nous ", " notre " et
            " nos ".
          </p>

          <h3>Que couvre cette politique de confidentialité ?</h3>
          <p>
            La présente politique de confidentialité couvre le traitement de vos renseignements personnels que les
            sociétés de marketing et les ambassadeurs de marque recueillent en notre nom lorsque nous fournissons des
            services d'acquisition de clientèle au nom de nos clients (les " Services "). Dans le cadre de la fourniture
            des Services à nos clients, nous (ou les sociétés de marketing et les ambassadeurs de marque agissant en
            notre nom) recueillons divers types d'informations sur les clients, y compris des informations qui vous
            identifient en tant qu'individu (" Données personnelles ") comme expliqué plus en détail ci-dessous.{' '}
          </p>

          <p>
            La plate-forme DataByte n'est pas destinée aux enfants et nous ne collectons pas sciemment de données
            relatives aux enfants.
          </p>

          <h3>Qui est responsable du traitement de vos données personnelles ?</h3>
          <p>
            L'entité responsable du traitement de vos données personnelles est le groupe TFO dont le siège social est
            situé au 32 Merrion Street Dublin 2, Irlande. L'identité du contrôleur de vos données personnelles sera
            l'Administrateur tel que défini dans vos conditions de service et est la Société de marketing avec laquelle
            vous avez signé un contrat.
          </p>

          <h3>Qui pouvez-vous contacter si vous avez des questions ou des demandes ?</h3>
          <p>
            Notre responsable de la protection des données traitera vos questions ou demandes relatives à la présente
            politique ou à vos données personnelles. Pour toute question, demande ou plainte concernant l'application de
            la présente politique ou pour exercer vos droits, tels que décrits dans la présente politique, vous pouvez
            nous contacter à l'adresse ci-dessous :
          </p>

          <p>
            <b>Nom: </b> {mc_name || mc_name_tc_params}
          </p>
          <p>
            <b>Poste: </b> Responsable de la protection des données (Groupe)
          </p>
          <p>
            <b>Email: </b>
            {mc_email || mc_email_tc_params}
          </p>

          <h3>Principes clés</h3>
          <p>
            Nous apprécions les données personnelles qui nous sont confiées et nous nous engageons à les traiter de
            manière équitable, transparente et sûre. Les principes clés que le groupe TFO applique sont les suivants :
          </p>

          <ul>
            <li>
              <b>Légalité: </b> nous ne collectons vos données personnelles que de manière équitable, légale et
              transparente.
            </li>

            <li>
              <b>Réduction des données: </b> nous limiterons la collecte de vos données personnelles à ce qui est
              directement pertinent et nécessaire aux fins pour lesquelles elles ont été recueillies.
            </li>

            <li>
              <b>Limitation des objectifs: </b> nous ne collectons vos données personnelles qu'à des fins spécifiques,
              explicites et légitimes et nous ne traitons pas vos données personnelles d'une manière incompatible avec
              ces objectifs.
            </li>

            <li>
              <b>Exactitude: </b> nous maintiendrons vos données personnelles exactes et à jour.
            </li>

            <li>
              <b>Sécurité et protection des données: </b> nous mettrons en œuvre des mesures techniques et
              organisationnelles pour assurer un niveau approprié de sécurité et de protection des données en tenant
              compte, entre autres, de la nature de vos données personnelles à protéger. Ces mesures prévoient la
              prévention de toute divulgation ou accès non autorisé, de toute destruction accidentelle ou illicite, de
              toute perte accidentelle ou altération et de toute autre forme de traitement illicite.
            </li>

            <li>
              <b>Accès et rectification: </b> nous traiterons vos données personnelles conformément à vos droits légaux.
            </li>

            <li>
              <b>Limitation de la conservation: </b> nous conserverons vos données personnelles d'une manière conforme
              aux lois et règlements applicables en matière de protection des données et pour une durée n'excédant pas
              celle nécessaire aux fins pour lesquelles elles ont été recueillies.
            </li>

            <li>
              <b>Protection pour les transferts internationaux: </b> nous veillerons à ce que toutes vos données
              personnelles transférées en dehors de l'EEE soient protégées de manière adéquate.{' '}
            </li>

            <li>
              <b>Garanties concernant les tiers: </b> nous nous assurerons que l'accès aux Données Personnelles par (et
              les transferts à) des tiers sont effectués conformément au droit applicable et avec des garanties
              contractuelles appropriées.{' '}
            </li>

            <li>
              <b>Légalité du marketing direct et des cookies: </b> si nous vous envoyons du matériel promotionnel ou si
              nous plaçons des cookies sur votre ordinateur, nous nous assurerons que nous le faisons conformément à la
              loi applicable.
            </li>
          </ul>

          <h3>Quels renseignements recueillons-nous ?</h3>
          <p>
            Nous recevons et stockons les informations que vous nous fournissez, via notre réseau de Sociétés de
            marketing indépendantes et leurs ambassadeurs de marque indépendants, qui sont nécessaires pour la
            fourniture de nos Services à nos clients. Nous pouvons collecter, utiliser, stocker et transférer différents
            types de données personnelles vous concernant que nous avons regroupées comme suit :
          </p>

          <ul>
            <li>
              <b>Données d'identité</b>, y compris le prénom, le nom de jeune fille, le nom de famille, le nom
              d'utilisateur ou un identificateur similaire, l'état civil, le titre, le numéro PPS, la date de naissance
              et le sexe ;
            </li>
            <li>
              <b>Données de contact</b>, y compris l'adresse résidentielle, l'adresse électronique et les numéros de
              téléphone ;
            </li>
            <li>
              <b>Données financières</b>, y compris les détails du compte bancaire et les détails de la carte de crédit
              ;
            </li>
            <li>
              <b>Données de transaction</b>, y compris les détails sur les paiements que vous avez versés ou reçus et
              d'autres détails sur les produits et services que vous avez achetés chez nous ;
            </li>
            <li>
              <b>Données techniques</b>, y compris l'adresse IP (protocole Internet), vos données de connexion, le type
              et la version de votre navigateur, le réglage et l'emplacement du fuseau horaire, les types et versions de
              plug-ins de navigateur, le système d'exploitation et la plate-forme et d'autres technologies sur les
              appareils que vous utilisez pour accéder à ce site Web ;
            </li>
            <li>
              <b>Données d'utilisation</b>, y compris des informations sur la façon dont vous utilisez notre site Web,
              nos produits et nos services
            </li>
            <li>
              <b>Données de marketing et de communication</b>, y compris vos préférences en matière de réception de
              marketing de notre part et de celle de nos tiers et vos préférences en matière de communication.
            </li>
          </ul>

          <p>
            Nous recueillons, utilisons et partageons également des données agrégées telles que des données statistiques
            ou démographiques à toutes fins. Les données agrégées peuvent être dérivées de vos données personnelles mais
            ne sont pas considérées comme des données personnelles en droit car ces données ne révèlent pas directement
            ou indirectement votre identité. Par exemple, nous pouvons regrouper vos données d'utilisation pour calculer
            le pourcentage d'utilisateurs qui accèdent à une fonction spécifique du site Web. Toutefois, si nous
            combinons ou connectons des données agrégées avec vos données personnelles afin qu'elles puissent vous
            identifier directement ou indirectement, nous traitons les données combinées comme des données personnelles
            qui seront utilisées conformément au présent avis de confidentialité.
          </p>

          <p>
            En général, nous ne traitons pas de catégories spéciales de données personnelles vous concernant, ce qui
            inclut des détails sur votre race ou votre appartenance ethnique, vos croyances religieuses ou
            philosophiques, votre vie sexuelle, votre orientation sexuelle, vos opinions politiques, votre appartenance
            à un syndicat, des informations sur votre santé et des données génétiques et biométriques). Dans certains
            cas, vous pouvez signer des contrats ou des documents à l'aide d'outils de signature électronique (eIDAS) et
            ces outils peuvent utiliser des données biométriques sous la forme de la manière dont vous écrivez votre
            signature, car celle-ci vous est propre. Ces informations biométriques sont sécurisées et cryptées, dans la
            mesure où nous ne les utilisons pas et ne les traitons pas, et elles ne peuvent être consultées qu'en cas de
            litige sur votre identité.
          </p>

          <p>
            Toute information que vous nous fournissez n'est pas mise à la disposition de tiers sans votre autorisation
            et n'est utilisée par le groupe TFO que dans le but pour lequel vous l'avez fournie et conformément au RGPD.
          </p>

          <h3>Comment utilisons-nous les informations ?</h3>

          <p>
            Nous ne traiterons vos données personnelles qu'à des fins spécifiques, explicites et légitimes et nous ne
            les traiterons pas d'une manière incompatible avec ces fins.
          </p>

          <p>
            Ces fins comprennent l'exploitation et l'administration des activités d'acquisition de nos clients,
            l'amélioration de nos produits et services, l'amélioration de votre visite sur l'un de nos sites Web ou
            portails, le traitement des factures pour les services d'acquisition de clients, le versement de commissions
            à la force de vente et la production de rapports sur les KPI des clients.
          </p>

          <p>
            Nous pouvons également utiliser les informations que vous nous envoyez pour communiquer avec vous par
            courrier électronique et, éventuellement, par d'autres moyens, concernant des événements ou des services que
            nous pensons susceptibles de vous intéresser ou pour vous envoyer un bulletin d'information, si vous avez
            consenti à un tel contact. Toutefois, vous pourrez toujours vous retirer de ces communications à tout moment
            (voir la section " Comment puis-je exercer mes droits de personne fichée " ci-dessous).
          </p>

          <h3>Quelle est la base juridique du traitement des données à caractère personnel ?</h3>

          <p>
            Le principal fondement juridique de ce traitement est notre intérêt légitime dans l'exploitation et
            l'administration des activités d'acquisition de clients ainsi que notre intérêt légitime dans le marketing
            et la promotion de notre entreprise. En outre, veuillez noter que conformément à la loi sur la protection
            des données en vigueur, vos données personnelles peuvent être traitées si :
          </p>

          <ul>
            <li>
              vous nous avez donné votre consentement aux fins du traitement. Pour éviter tout doute, vous aurez
              toujours le droit de retirer votre consentement à tout moment ;
            </li>
            <li>elle est nécessaire à l'exécution d'un contrat auquel vous êtes partie ; ou</li>
            <li>il est exigé par la loi.</li>
          </ul>

          <h3>Comment partageons-nous vos données personnelles ?</h3>

          <p>
            Nous pouvons être amenés à partager vos Données Personnelles avec nos clients qui ont commandé la campagne
            marketing ou de vente directe afin de fournir les Services. Nous ne partageons vos renseignements personnels
            avec personne, sauf si un consentement a été fourni ou est nécessaire à l'exécution d'un contrat ou est
            exigé par la loi.
          </p>

          <h3>Mes données personnelles sont-elles sécurisées ?</h3>

          <p>
            Nous mettons en place des mesures de sécurité conçues pour protéger vos données. Nous surveillons
            régulièrement nos systèmes pour détecter les vulnérabilités et les attaques éventuelles. Cependant, nous ne
            pouvons pas garantir la sécurité des informations que nous recevons. Il n'y a aucune garantie que les
            données ne puissent pas être consultées, divulguées, modifiées ou détruites par une violation de l'une de
            nos protections physiques, techniques ou de gestion, malgré nos meilleurs efforts pour empêcher cela.
          </p>
          <p>
            Nous utilisons des mesures de sécurité techniques, organisationnelles et administratives appropriées pour
            protéger toute information que nous détenons dans nos dossiers contre la perte, le mauvais usage et l'accès
            non autorisé, la divulgation, l'altération et la destruction.
          </p>
          <p>
            Ces mesures ont été conçues en tenant compte de notre infrastructure informatique, de l'impact potentiel sur
            votre vie privée et des coûts impliqués, et conformément aux normes et pratiques actuelles de l'industrie.
          </p>
          <p>
            Vos données personnelles ne seront traitées par un tiers que si ce dernier accepte de respecter ces mesures
            techniques et organisationnelles de sécurité des données.
          </p>
          <p>
            Maintenir la sécurité des données signifie protéger la confidentialité, l'intégrité et la disponibilité de
            vos données personnelles :
          </p>

          <ol type="a">
            <li>
              <b>Confidentialité</b>: nous protégerons vos données personnelles contre toute divulgation non désirée à
              des tiers.
            </li>
            <li>
              <b>Intégrité</b>: nous protégerons vos données personnelles contre toute modification par des tiers non
              autorisés.
            </li>
            <li>
              <b>Disponibilité</b>: nous veillerons à ce que les parties autorisées puissent accéder à vos données
              personnelles en cas de besoin.
            </li>
          </ol>

          <p>
            Nos procédures de sécurité des données comprennent : la sécurité des accès, les systèmes de sauvegarde, la
            surveillance, la révision et la maintenance, la gestion des incidents de sécurité et la continuité, etc.
          </p>

          <h3>Pendant combien de temps utiliserons-nous vos données personnelles ?</h3>

          <p>
            Nous ne conserverons vos données personnelles qu'aussi longtemps que nécessaire pour atteindre les objectifs
            pour lesquels nous les avons collectées, y compris pour satisfaire à toute exigence juridique, comptable ou
            de reporting.
          </p>
          <p>
            Dans certaines circonstances, vous pouvez nous demander d'effacer vos données : voir la section Droit
            d'effacement ci-dessous pour de plus amples informations.
          </p>
          <p>
            Dans certaines circonstances, nous pouvons rendre vos données personnelles anonymes (afin qu'elles ne
            puissent plus être associées à vous) à des fins de recherche ou de statistiques, auquel cas nous pouvons
            utiliser ces informations indéfiniment sans vous en informer.
          </p>

          <h3>Informations que nous recueillons automatiquement :</h3>
          <p>
            Lorsque vous visitez notre site Web, nous recueillons certaines informations relatives à votre appareil,
            telles que l'adresse IP de votre appareil, le site Web de référence, les pages visitées par votre appareil
            et l'heure à laquelle votre appareil a visité notre site Web.
          </p>
          <p>
            L'adresse ("IP"). Informations collectées par les cookies et autres technologies similaires - nous utilisons
            diverses technologies pour collecter des informations qui peuvent inclure la sauvegarde des cookies sur les
            ordinateurs des utilisateurs. Pour plus d'informations, veuillez consulter la section ci-dessous intitulée "
            Cookies et autres technologies de suivi ".
          </p>

          <h3>Cookies et autres technologies de suivi</h3>

          <p>
            Un " cookie " est un petit fichier de données qui est stocké sur le disque dur de votre ordinateur. Ils sont
            utilisés par presque tous les sites Web et ne nuisent pas à votre système. Nous les utilisons pour suivre
            votre activité afin de nous assurer que votre visite sur notre site Web se déroule le plus harmonieusement
            possible. Nous pouvons utiliser les informations provenant des cookies pour nous assurer de vous présenter
            des options adaptées à vos préférences lors de votre prochaine visite. Nous pouvons également utiliser des
            cookies pour analyser le trafic et à des fins publicitaires.
          </p>
          <p>
            Si vous souhaitez vérifier ou modifier les types de cookies que vous acceptez, cela peut généralement être
            modifié dans les paramètres de votre navigateur. Veuillez consulter notre Politique relative aux cookies
            pour plus d'informations sur le type de cookies et les technologies de suivi que nous utilisons sur ce site
            Web et sur les raisons pour lesquelles nous les acceptons et les rejetons.
          </p>

          <h3>Divulgation des données personnelles</h3>
          <p>
            En fonction des objectifs pour lesquels nous recueillons vos données personnelles, nous pouvons les
            divulguer aux catégories de destinataires suivantes, qui ne traiteront alors vos données personnelles que
            pour l'un des objectifs suivants :
          </p>

          <ol type="a">
            <li>
              Au sein de nos organisations :
              <ul>
                <li>Nos collaborateurs autorisés ;</li>
                <li>Nos sociétés affiliées et filiales ;</li>
              </ul>
            </li>
            <li>
              Les partenaires commerciaux tiers :
              <ul>
                <li>
                  Les Sociétés de marketing et les ambassadeurs de la marque qui mènent les activités d'acquisition de
                  clients en notre nom.
                </li>
                <li>
                  Nos clients qui nous ont mandatés pour effectuer la campagne marketing d'acquisition de clients
                  applicable.
                </li>
                <li>
                  Partenaires commerciaux : par exemple, des sociétés de confiance qui peuvent utiliser vos données
                  personnelles pour vous fournir les services et/ou les produits que vous avez demandés et/ou qui
                  peuvent vous fournir du matériel marketing (à condition que vous ayez consenti à recevoir ce matériel
                  marketing). Ces partenaires commerciaux seront soumis à une stricte confidentialité.
                </li>
              </ul>
            </li>
            <li>
              Autres tiers :
              <ul>
                <li>lorsque la loi l'exige ou lorsque cela est légalement nécessaire pour protéger le groupe TFO :</li>
                <li>
                  pour se conformer à la loi, aux demandes des autorités, aux ordonnances des tribunaux, aux procédures
                  légales, aux obligations liées à la déclaration et au dépôt d'informations auprès des autorités, etc ;
                </li>
                <li>pour vérifier ou faire respecter la conformité aux politiques et aux accords du groupe TFO; et</li>
                <li>pour protéger les droits, la propriété ou la sécurité du Groupe TFO et/ou de ses clients ;</li>
                <li>
                  dans le cadre d'opérations corporatives : dans le cadre d'un transfert ou d'une cession de tout ou
                  partie de ses activités, ou encore dans le cadre d'une fusion, d'une consolidation, d'un changement de
                  contrôle, d'une réorganisation ou d'une liquidation de tout ou partie des activités du groupe TFO.
                </li>
              </ul>
            </li>
          </ol>

          <h3>Communications marketing</h3>
          <p>
            Si vous avez consenti à recevoir certaines communications promotionnelles ou marketing de notre part, vous
            pouvez refuser de les recevoir à tout moment, en utilisant le lien de désabonnement dans les courriels que
            nous envoyons, en cliquant sur le lien " Exercez vos droits " disponible au bas de notre Déclaration de
            confidentialité, ou en nous envoyant un message via notre site Web.
          </p>

          <h3>Profilage</h3>
          <p>
            Certaines données personnelles peuvent être utilisées sur une base agrégée pour le profilage de certains
            territoires pour des raisons de gestion des territoires. Le profilage qui est effectué est fait pour aider
            la force de vente à sélectionner et à gérer les territoires dans lesquels elle opère. Cela nous permet
            d'affecter nos ressources de la manière la plus efficace possible afin de cibler l'acquisition de clients.
            Nous ne dressons pas de profil individuel des personnes concernées et les personnes concernées ne font pas
            l'objet d'une prise de décision automatisée. Les conséquences prévues de ce traitement pour les personnes
            concernées sont minimes, seules les sociétés de marketing et les ambassadeurs de marque qui agissent en
            notre nom peuvent être plus ou moins susceptibles de mener nos activités d'acquisition de clients dans leur
            territoire selon les résultats du profilage.
          </p>

          <h3>Transfert de données en dehors de l'EEE</h3>
          <p>Tous les traitements sont effectués au sein de l'EEE.</p>

          <h3>Comment puis-je exercer mes droits de personne fichée ?</h3>
          <p>En vertu du règlement général sur la protection des données, vous disposez des droits suivants :</p>

          <p>
            <b>Droit d'objection</b>: Si nous utilisons vos données parce que nous le jugeons nécessaire pour nos
            intérêts légitimes et que vous n'êtes pas d'accord, vous avez le droit de vous y opposer. Nous répondrons à
            votre demande dans un délai de 30 jours (bien que nous puissions être autorisés à prolonger ce délai dans
            certains cas). En général, nous ne serons en désaccord avec vous que si certaines conditions limitées
            s'appliquent.
          </p>
          <p>
            <b>Droit de retirer le consentement</b>: Lorsque nous avons obtenu votre consentement pour traiter vos
            données personnelles dans le cadre de certaines activités, ou votre consentement pour vous faire du
            marketing, vous pouvez retirer votre consentement à tout moment.
          </p>
          <p>
            <b>Droit de rectification</b>: si les Données Personnelles que nous détenons sont inexactes ou incomplètes,
            vous avez le droit de demander la rectification de vos Données Personnelles.
          </p>
          <p>
            <b>Demandes d'accès de la personne fichée</b>: Pour que ce soit clair, vous avez le droit de nous demander
            de confirmer les renseignements que nous détenons à votre sujet en tout temps et de vous en fournir des
            copies. Nous répondrons à votre demande dans un délai de 30 jours. À ce stade, nous pouvons nous conformer à
            votre demande ou, en plus, faire l'une des choses suivantes :
          </p>

          <ul>
            <li>
              nous pouvons vous demander de vérifier votre identité ou de fournir plus de renseignements sur votre
              demande; et
            </li>
            <li>
              lorsque nous sommes légalement autorisés à le faire, nous pouvons refuser votre demande, mais nous vous
              expliquerons pourquoi si nous le faisons.
            </li>
          </ul>

          <p>
            <b>Droit à l'effacement</b>: Dans certaines situations (par exemple, lorsque nous avons traité vos données
            de manière illégale), vous avez le droit de nous demander "d'effacer" vos données personnelles. Nous
            répondrons à votre demande dans les 30 jours (bien que nous puissions être autorisés à prolonger cette
            période dans certains cas) et nous ne serons en désaccord avec vous que si certaines conditions limitées
            s'appliquent. Si nous acceptons votre demande, nous supprimerons vos données.
          </p>
          <p>
            <b>Droit de portabilité des données</b>: Si vous le souhaitez, vous avez le droit de transférer vos données
            de notre part à un autre contrôleur de données. Nous vous aiderons dans cette tâche, soit en transférant
            directement vos données pour vous, soit en vous fournissant une copie dans un format lisible par machine
            couramment utilisé. 
          </p>
          <p>
            <b>Droit de déposer une plainte auprès d'une autorité de surveillance</b>: Vous avez également le droit de
            déposer une plainte auprès du Bureau du Commissaire à la protection des données.
          </p>
          <p>
            Si vos intérêts ou vos besoins changent, vous pouvez vous désabonner de tout ou partie de notre contenu
            marketing (par exemple, les courriels sur les postes vacants ou les bulletins d'information du groupe TFO)
            en cliquant sur le lien de désabonnement dans le courriel, ou en nous envoyant une demande par courriel).
          </p>
          <p>
            <b>Modification de la présente politique</b>
            Il se peut que nous devions modifier cette déclaration de confidentialité de temps à autre. Nous vous
            avertirons des changements importants en plaçant, par exemple, un avis sur nos sites Web et/ou en vous
            envoyant un courriel (si vous avez enregistré vos coordonnées de courriel chez nous) lorsque nous sommes
            tenus de le faire en vertu de la loi applicable. Il vous incombe de revoir périodiquement la présente
            déclaration de confidentialité.
          </p>
          <p>Cette déclaration de confidentialité a été mise à jour le 28 mai 2019.</p>
        </div>
      )}
      {(resolvedUserLocale === 'nl' || user.locale === 'nl') && (
        <div>
          <h3>Openingsverklaring</h3>
          <p>
            Wij hebben uw toestemming niet nodig voor de verwerking van uw Persoonsgegevens, aangezien wij deze
            verwerken voor de uitvoering van contracten waarbij u partij bent (d.w.z. de
            Servicevoorwaarden/Gebruiksvoorwaarden voor DataByte). Uw Persoonsgegevens worden verwerkt zolang als nodig
            is om dit contract uit te voeren.
          </p>
          <p>
            The Firm Organisation Limited ("TFO Group") respecteert uw recht op privacy volledig en wij doen er alles
            aan om ervoor te zorgen dat uw privacy wordt beschermd. U hebt recht op de bescherming van uw persoonlijke
            gegevens. Deze gegevens kunnen betrekking hebben op uw naam, telefoonnummer, emailadres of andere informatie
            die op u betrekking heeft.
          </p>
          <p>
            Lees de volgende privacyverklaring om meer te weten te komen over hoe wij informatie over u verzamelen,
            opslaan, gebruiken en openbaar maken wanneer u contact hebt met de TFO Group en specifiek met DataByte. Dit
            Beleid is van toepassing op al uw Persoonsgegevens die worden verzameld door (of namens) de TFO Group
            (waaronder ook haar groepsmaatschappijen vallen), samen in dit Beleid aangeduid als "TFO Group", "wij",
            "ons" en "onze".
          </p>
          <h3>Wat dekt dit privacybeleid?</h3>
          <p>
            Dit Privacybeleid dekt onze behandeling van uw persoonlijke informatie die marketingbedrijven en
            merkambassadeurs namens ons verzamelen wanneer wij namens onze klanten diensten voor klantenwerving verlenen
            (de "Diensten"). Tijdens de levering van de Diensten aan onze klanten verzamelen wij (of marketingbedrijven
            en merkambassadeurs die namens ons optreden) verschillende soorten informatie over klanten, inclusief
            informatie die u als individu identificeert ("Persoonsgegevens"), zoals hieronder in meer detail wordt
            uitgelegd.
          </p>
          <p>
            Het DataByte Platform is niet bedoeld voor kinderen en wij verzamelen niet bewust gegevens met betrekking
            tot kinderen.
          </p>
          <h3>Wie is verantwoordelijk voor de verwerking van uw Persoonsgegevens?</h3>
          <p>
            De entiteit die verantwoordelijk is voor de verwerking van uw Persoonsgegevens is TFO Group,
            Maatschappelijke zetel: 32 Merrion Street Dublin 2, Ierland. De identiteit van de Gegevensbeheerder voor uw
            Persoonsgegevens zal de Beheerder zijn zoals gedefinieerd in uw servicevoorwaarden en is het
            Marketingbedrijf waarmee u een contract hebt.
          </p>
          <h3>Met wie kunt u contact opnemen als u vragen of verzoeken hebt?</h3>
          <p>
            Onze Functionaris Gegevensbescherming zal uw vragen of verzoeken in verband met dit Beleid of uw
            Persoonsgegevens behandelen. Voor vragen of verzoeken of klachten over de toepassing van dit Beleid of om uw
            rechten uit te oefenen, zoals beschreven in dit Beleid, kunt u contact met ons opnemen op het onderstaande
            adres:
          </p>
          <p>
            <b>Naam: </b> {mc_name || mc_name_tc_params}
          </p>
          <p>
            <b>Functie: </b> Functionaris Gegevensbescherming (Group)
          </p>
          <p>
            <b>E-mail: </b>
            {mc_email || mc_email_tc_params}
          </p>
          <h3>Belangrijkste principes</h3>
          <p>
            Wij hechten waarde aan uw Persoonsgegevens die aan ons zijn toevertrouwd en wij zetten ons in om uw
            Persoonsgegevens op een eerlijke, transparante en veilige manier te verwerken. De belangrijkste principes
            die TFO Group hanteert zijn de volgende:
          </p>
          <ul>
            <li>
              <b>Rechtmatigheid: </b> wij zullen uw Persoonsgegevens alleen verzamelen op een eerlijke, rechtmatige en
              transparante manier.
            </li>
            <li>
              <b>Gegevensminimalisering</b>: wij zullen het verzamelen van uw Persoonsgegevens beperken tot hetgeen
              direct relevant en noodzakelijk is voor de doeleinden waarvoor zij zijn verzameld.
            </li>
            <li>
              <b>Doelbinding</b>: wij zullen uw Persoonsgegevens alleen voor welbepaalde, uitdrukkelijk omschreven en
              gerechtvaardigde doeleinden verzamelen en uw Persoonsgegevens niet verder verwerken op een wijze die
              onverenigbaar is met die doeleinden.
            </li>
            <li>
              <b>Nauwkeurigheid</b>: wij zullen uw Persoonsgegevens nauwkeurig en up-to-date houden.
            </li>
            <li>
              <b>Beveiliging en bescherming van gegevens:</b>: wij zullen technische en organisatorische maatregelen
              treffen om een passend niveau van beveiliging en bescherming van gegevens te waarborgen, waarbij onder
              meer rekening wordt gehouden met de aard van uw te beschermen Persoonsgegevens. Dergelijke maatregelen
              voorzien in de preventie van ongeoorloofde openbaarmaking of toegang, accidentele of onwettige
              vernietiging of accidenteel verlies, of wijziging en elke andere onwettige vorm van Verwerking.
            </li>
            <li>
              <b>Toegang en rectificatie</b>: wij zullen uw Persoonsgegevens verwerken in overeenstemming met uw
              wettelijke rechten.
            </li>
            <li>
              <b>Bewaarbeperking</b>: wij zullen uw Persoonsgegevens bewaren op een wijze die in overeenstemming is met
              de toepasselijke wet- en regelgeving inzake gegevensbescherming en niet langer dan noodzakelijk is voor de
              doeleinden waarvoor zij zijn verzameld.
            </li>
            <li>
              <b>Bescherming voor internationale overdrachten</b>: wij zullen ervoor zorgen dat al uw Persoonsgegevens
              die buiten de EER worden overgedragen, adequaat worden beschermd.
            </li>
            <li>
              <b>Waarborgen voor derden</b>: wij zullen ervoor zorgen dat de toegang tot (en de doorgifte van)
              Persoonsgegevens aan derden in overeenstemming met de toepasselijke wetgeving en met passende contractuele
              waarborgen geschiedt.{' '}
            </li>
            <li>
              <b>Rechtmatigheid van direct marketing en cookies</b>: als wij u promotiemateriaal sturen of cookies op uw
              computer plaatsen, zorgen wij ervoor dat wij dit doen in overeenstemming met de toepasselijke wetgeving.
            </li>
          </ul>
          <h3>Welke informatie verzamelen wij?</h3>
          <p>
            Wij ontvangen en bewaren informatie die u aan ons verstrekt, via ons netwerk van onafhankelijk
            gecontracteerde marketingbedrijven en hun onafhankelijk gecontracteerde merkambassadeurs, en die nodig is
            voor de levering van onze Diensten aan onze klanten. Wij kunnen verschillende soorten persoonsgegevens over
            u verzamelen, gebruiken, opslaan en overdragen, die wij als volgt hebben gegroepeerd:
          </p>
          <ul>
            <li>
              <b>Identiteitsgegevens</b> waaronder voornaam, meisjesnaam, achternaam, gebruikersnaam of soortgelijke
              identificatiecode, burgerlijke staat, titel, PPS-nummer, geboortedatum en geslacht;
            </li>
            <li>
              <b>Contactgegevens</b> waaronder woonadres, e-mailadres en telefoonnummers;
            </li>
            <li>
              <b>Financiële gegevens</b> waaronder bankrekeninggegevens en kredietkaartgegevens;
            </li>
            <li>
              <b>Transactiegegevens</b> waaronder details over betalingen aan en van u en andere details over producten
              en diensten die u bij ons hebt gekocht;
            </li>
            <li>
              <b>Technische gegevens</b> waaronder internetprotocoladres (IP-adres), uw inloggegevens, browsertype en
              -versie, tijdzone-instelling en locatie, browser plug-in types en versies, besturingssysteem en platform
              en andere technologie op de apparaten die u gebruikt om toegang te krijgen tot deze website;
            </li>
            <li>
              <b>Gebruiksgegevens</b> waaronder informatie over hoe u onze website, producten en diensten gebruikt; en
            </li>
            <li>
              <b>Marketing- en communicatiegegevens</b> met inbegrip van uw voorkeuren voor het ontvangen van marketing
              van ons en onze derde partijen en uw communicatievoorkeuren.
            </li>
          </ul>
          <p>
            Wij verzamelen, gebruiken en delen ook geaggregeerde gegevens, zoals statistische of demografische gegevens,
            voor welk doel dan ook. Geaggregeerde Gegevens kunnen worden afgeleid van uw persoonsgegevens, maar worden
            volgens de wet niet beschouwd als persoonsgegevens, aangezien deze gegevens uw identiteit niet direct of
            indirect onthullen. Wij kunnen bijvoorbeeld uw Gebruiksgegevens aggregeren om het percentage gebruikers te
            berekenen dat toegang heeft tot een specifieke websitefunctie. Indien wij echter Geaggregeerde Gegevens
            combineren of verbinden met uw persoonsgegevens zodat deze u direct of indirect kunnen identificeren,
            behandelen wij de gecombineerde gegevens als persoonsgegevens die zullen worden gebruikt in overeenstemming
            met deze privacyverklaring.
          </p>
          <p>
            In het algemeen verwerken wij geen Speciale categorieën Persoonsgegevens over u (dit omvat details over uw
            ras of etniciteit, religieuze of filosofische overtuigingen, seksleven, seksuele geaardheid, politieke
            opvattingen, lidmaatschap van een vakbond, informatie over uw gezondheid en genetische en biometrische
            gegevens). In sommige gevallen ondertekent u contracten of documenten met hulpmiddelen voor elektronische
            handtekeningen (eIDAS) en deze hulpmiddelen kunnen biometrische gegevens gebruiken in de vorm van de manier
            waarop u uw handtekening schrijft, aangezien die uniek is voor u. Deze biometrische gegevens zijn beveiligd
            en gecodeerd, in die zin dat wij ze niet gebruiken of verwerken en dat ze alleen toegankelijk zijn indien er
            een geschil is over uw identiteit.
          </p>
          <p>
            Alle informatie die u ons verstrekt, wordt zonder uw toestemming niet aan derden ter beschikking gesteld en
            wordt door de TFO Group alleen gebruikt in overeenstemming met het doel waarvoor u ze hebt verstrekt en in
            overeenstemming met de GDPR (Algemene verordening gegevensbescherming).
          </p>
          <h3>Hoe gebruiken wij de informatie?</h3>
          <p>
            Wij zullen uw Persoonsgegevens alleen voor welbepaalde, uitdrukkelijk omschreven en gerechtvaardigde
            doeleinden verwerken en wij zullen uw Persoonsgegevens niet verder verwerken op een wijze die onverenigbaar
            is met die doeleinden. .
          </p>
          <p>
            Dergelijke doeleinden zijn onder meer de werking en administratie van onze klantenwervingsactiviteiten, de
            verbetering van onze producten en diensten, de verbetering van uw bezoek aan een van onze websites of
            portalen, de verwerking van facturen voor klantenwervingsdiensten, de betaling van commissies aan het
            verkoopteam en de productie van KPI-rapporten van klanten.{' '}
          </p>
          <p>
            Wij kunnen de informatie die u ons stuurt ook gebruiken om met u te communiceren via e-mail en eventueel
            andere middelen, over evenementen of diensten waarvan wij denken dat ze u kunnen interesseren of om u een
            nieuwsbrief te sturen, indien u toestemming hebt gegeven voor dergelijk contact. U kunt zich echter te allen
            tijde afmelden voor dergelijke communicatie (zie het gedeelte "Hoe kan ik mijn rechten als betrokkene
            uitoefenen" hieronder).
          </p>
          <h3>Wat is de rechtsgrondslag voor de verwerking van Persoonsgegevens?</h3>
          <p>
            De belangrijkste rechtsgrondslag voor deze verwerking is ons gerechtvaardigd belang bij de exploitatie en
            administratie van klantenwervingsactiviteiten, alsmede ons gerechtvaardigd belang bij de marketing en
            promotie van ons bedrijf. Daarnaast wijzen wij u erop dat, in overeenstemming met de toepasselijke wetgeving
            inzake gegevensbescherming, uw Persoonsgegevens kunnen worden verwerkt indien:
          </p>
          <ul>
            <li>
              u ons uw toestemming hebt gegeven voor de doeleinden van de Verwerking. Voor alle duidelijkheid: u hebt
              altijd het recht om uw toestemming op elk moment in te trekken;
            </li>
            <li>het noodzakelijk is voor de uitvoering van een contract waarbij u partij bent; of</li>
            <li>het bij de wet verplicht is.</li>
          </ul>
          <h3>Hoe delen wij uw Persoonsgegevens?</h3>
          <p>
            Het is mogelijk dat wij uw Persoonsgegevens moeten delen met onze klanten die opdracht hebben gegeven voor
            de marketing of directe verkoopcampagne om de Diensten te kunnen verlenen. Wij delen uw Persoonsgegevens met
            niemand, tenzij hiervoor toestemming is gegeven of dit noodzakelijk is voor de uitvoering van een contract
            of dit wettelijk verplicht is.
          </p>
          <h3>Zijn mijn persoonlijke gegevens veilig?</h3>
          <p>
            Wij implementeren veiligheidsmaatregelen om uw gegevens te beschermen. Wij controleren onze systemen
            regelmatig op mogelijke kwetsbaarheden en aanvallen. Wij kunnen echter de veiligheid van alle informatie die
            wij ontvangen niet garanderen. Er is geen garantie dat gegevens niet kunnen worden ingezien, bekendgemaakt,
            gewijzigd of vernietigd door inbreuk op een van onze fysieke, technische of bestuurlijke
            veiligheidsmaatregelen, ondanks onze inspanningen om dit te voorkomen.
          </p>
          <p>
            Wij gebruiken passende technische, organisatorische en administratieve veiligheidsmaatregelen om alle
            informatie in onze bestanden te beschermen tegen verlies, misbruik en ongeoorloofde toegang, openbaarmaking,
            wijziging en vernietiging. .
          </p>
          <p>
            Bij het ontwerpen van deze maatregelen is rekening gehouden met onze IT-infrastructuur, de mogelijke
            gevolgen voor uw privacy en de betrokken kosten, en met de huidige normen en praktijken in de sector. .
          </p>
          <p>
            Uw Persoonsgegevens zullen alleen door een derde gegevensverwerker worden verwerkt indien die
            gegevensverwerker ermee instemt deze technische en organisatorische gegevensbeveiligingsmaatregelen na te
            leven.
          </p>
          <p>
            Handhaving van de gegevensbeveiliging houdt in dat de vertrouwelijkheid, de integriteit en de
            beschikbaarheid van uw Persoonsgegevens worden beschermd:{' '}
          </p>
          <ol type="a">
            <li>
              <b>Vertrouwelijkheid</b>: wij zullen uw Persoonsgegevens beschermen tegen ongewenste openbaarmaking aan
              derden.
            </li>
            <li>
              <b>Integriteit</b>: wij zullen uw Persoonsgegevens beschermen tegen wijziging door onbevoegde derden.
            </li>
            <li>
              <b>Beschikbaarheid</b>: wij zullen ervoor zorgen dat geautoriseerde partijen toegang hebben tot uw
              Persoonsgegevens wanneer dat nodig is.
            </li>
          </ol>
          <p>
            Onze procedures voor gegevensbeveiliging omvatten: toegangsbeveiliging, back-upsystemen, monitoring,
            evaluatie en onderhoud, beheer van veiligheidsincidenten en continuïteit, enz.
          </p>
          <h3>Hoe lang zullen wij uw Persoonsgegevens gebruiken?</h3>
          <p>
            Wij zullen uw Persoonsgegevens slechts zo lang bewaren als nodig is om de doeleinden waarvoor wij ze hebben
            verzameld te verwezenlijken, met inbegrip van de doeleinden om te voldoen aan wettelijke, boekhoudkundige of
            rapportagevereisten.
          </p>
          <p>
            In sommige omstandigheden kunt u ons vragen uw gegevens te verwijderen: zie "Recht op verwijderen" hieronder
            voor meer informatie.
          </p>
          <p>
            In sommige omstandigheden kunnen wij uw Persoonsgegevens anonimiseren (zodat zij niet langer met u in
            verband kunnen worden gebracht) voor onderzoeks- of statistische doeleinden; in dat geval kunnen wij deze
            informatie voor onbepaalde tijd gebruiken zonder u daarvan verder in kennis te stellen.{' '}
          </p>
          <h3>Informatie die wij automatisch verzamelen:</h3>
          <p>
            Wanneer u onze Website bezoekt, verzamelen wij bepaalde informatie met betrekking tot uw apparaat, zoals het
            IP-adres van uw apparaat, de verwijzende website, welke pagina's uw apparaat heeft bezocht en het tijdstip
            waarop uw apparaat onze Website heeft bezocht.
          </p>
          <p>
            ("IP") adres. Informatie verzameld door cookies en andere soortgelijke technologieën - wij gebruiken
            verschillende technologieën om informatie te verzamelen, waaronder het opslaan van cookies op de computers
            van gebruikers. Voor meer informatie, zie de sectie hieronder "Cookies en andere traceringstechnologieën".
          </p>
          <h3>Cookies en andere traceertechnologieën</h3>
          <p>
            Een "cookie" is een stukje data dat op de harde schijf van uw computer wordt opgeslagen. Ze worden door
            bijna alle websites gebruikt en brengen geen schade toe aan uw systeem. Wij gebruiken ze om uw activiteiten
            bij te houden, zodat u een zo vlot mogelijke ervaring krijgt wanneer u onze website bezoekt. Wij kunnen de
            informatie uit cookies gebruiken om ervoor te zorgen dat wij u bij uw volgende bezoek opties presenteren die
            op uw voorkeuren zijn afgestemd. Wij kunnen cookies ook gebruiken om het verkeer te analyseren en voor
            reclamedoeleinden.
          </p>
          <p>
            Als u wilt controleren of wijzigen welke soorten cookies u accepteert, kan dit meestal worden gewijzigd in
            uw browserinstellingen. Raadpleeg ons Cookiebeleid voor meer informatie over het soort cookies en
            traceertechnologieën die wij op deze Website gebruiken en waarom, en hoe u deze kunt accepteren en weigeren.
          </p>
          <h3>Openbaarmaking van persoonsgegevens</h3>
          <p>
            Afhankelijk van de doeleinden waarvoor wij uw Persoonsgegevens verzamelen, kunnen wij deze bekendmaken aan
            de volgende categorieën ontvangers, die uw Persoonsgegevens vervolgens uitsluitend voor een van de volgende
            doeleinden zullen verwerken:
          </p>
          <ol type="a">
            <li>
              Binnen onze organisaties:
              <ul>
                <li>Onze bevoegde personeelsleden;</li>
                <li>Onze filialen en dochterondernemingen;</li>
              </ul>
            </li>
            <li>
              Derden-zakenpartners:
              <ul>
                <li>De marketingbedrijven en de merkambassadeurs die namens ons aan klantenwerving doen.</li>
                <li>
                  Onze klanten die ons de opdracht hebben gegeven om de toepasselijke marketing campagne voor
                  klantenwerving uit te voeren.
                </li>
                <li>
                  Zakelijke partners: bijvoorbeeld vertrouwde bedrijven die uw Persoonsgegevens kunnen gebruiken om u de
                  diensten en/of producten te leveren die u hebt aangevraagd en/of die u marketingmateriaal kunnen
                  leveren (mits u hebt ingestemd met het ontvangen van dergelijk marketingmateriaal). Dergelijke
                  zakenpartners zullen onderworpen zijn aan strikte vertrouwelijkheid.
                </li>
              </ul>
            </li>
            <li>
              Andere derden:
              <ul>
                <li>
                  wanneer de wet dit vereist of wanneer dit wettelijk noodzakelijk is om de TFO Group te beschermen:
                </li>
                <li>
                  om te voldoen aan de wet, verzoeken van autoriteiten, gerechtelijke bevelen, juridische procedures,
                  verplichtingen in verband met het melden en indienen van informatie bij autoriteiten, enz;
                </li>
                <li>
                  om de naleving van het beleid en de overeenkomsten van de TFO Group te controleren of af te dwingen;
                  en
                </li>
                <li>om de rechten, eigendommen of veiligheid van de TFO Group en/of haar klanten te beschermen;</li>
                <li>
                  in verband met bedrijfstransacties: in het kader van een overdracht of afstoting van het geheel of een
                  deel van haar activiteiten, of anderszins in verband met een fusie, consolidatie, wijziging in
                  zeggenschap, reorganisatie of liquidatie van het geheel of een deel van de activiteiten van de TFO
                  Group.
                </li>
              </ul>
            </li>
          </ol>
          <h3>Marketing communicatie</h3>
          <p>
            Als u hebt ingestemd met het ontvangen van bepaalde promotionele of marketingcommunicatie van ons, kunt u
            zich op elk moment afmelden voor het ontvangen van dergelijke promotionele of marketingcommunicatie van ons,
            door gebruik te maken van de afmeldlink in de e-mailcommunicatie die wij sturen, te klikken op de link "Uw
            rechten uitoefenen" die beschikbaar is onderaan onze Privacyverklaring, of ons een bericht te sturen via
            onze website.
          </p>
          <h3>Profilering</h3>
          Bepaalde persoonsgegevens kunnen op geaggregeerde basis worden gebruikt voor de profilering van bepaalde
          gebieden met het oog op gebiedsbeheer. De profilering die wordt uitgevoerd, wordt gedaan om de
          verkoopmedewerkers te helpen bij het selecteren en beheren van de gebieden waarin zij actief zijn. Dit stelt
          ons in staat onze middelen op de meest efficiënte manier toe te wijzen met het oog op gerichte klantenwerving.
          Wij stellen geen profielen op van individuele betrokkenen en de betrokkenen worden niet onderworpen aan
          geautomatiseerde besluitvorming. De beoogde gevolgen van een dergelijke verwerking voor de betrokkenen zijn
          minimaal, alleen dat de marketingbedrijven en merkambassadeurs die namens ons optreden, meer of minder geneigd
          kunnen zijn om onze klantenwerving in hun gebied uit te voeren, afhankelijk van de resultaten van de
          profilering.
          <h3>Doorgifte van gegevens buiten de EER</h3>
          <p>Alle verwerking vindt plaats binnen de EER. </p>
          <h3>Hoe kan ik mijn rechten als betrokkene uitoefenen?</h3>
          <p>Krachtens de Algemene verordening gegevensbescherming hebt u de volgende rechten:</p>
          <p>
            <b>Recht op het maken van bezwaar</b>: Als wij uw gegevens gebruiken omdat wij het noodzakelijk achten voor
            onze legitieme belangen om dit te doen, en u bent het daar niet mee eens, dan heeft u het recht om bezwaar
            te maken. Wij zullen binnen 30 dagen op uw verzoek reageren (hoewel wij deze periode in bepaalde gevallen
            kunnen verlengen). In het algemeen zullen wij het alleen met u oneens zijn als bepaalde beperkte voorwaarden
            van toepassing zijn.
          </p>
          <p>
            <b>Recht om toestemming in te trekken</b>: Wanneer wij uw toestemming hebben verkregen om uw
            Persoonsgegevens voor bepaalde activiteiten te verwerken, of toestemming om u op de markt te brengen, kunt u
            uw toestemming op elk moment intrekken.
          </p>
          <p>
            <b>Recht op rectificatie</b>: indien uw Persoonsgegevens die wij in ons bezit hebben onnauwkeurig of
            onvolledig zijn, heeft u het recht om rectificatie van uw Persoonsgegevens te verzoeken.
          </p>
          <p>
            <b>Verzoeken om toegang tot gegevens</b>: Voor alle duidelijkheid: u hebt het recht om ons op elk moment te
            vragen te bevestigen welke informatie wij over u bewaren, en om u kopieën van die informatie te bezorgen.
            Wij zullen binnen 30 dagen op uw verzoek reageren. Op dat moment kunnen wij aan uw verzoek voldoen of
            daarnaast één van de volgende dingen doen:
          </p>
          <ul>
            <li>wij u kunnen vragen uw identiteit te verifiëren, of meer informatie over uw verzoek te vragen; en</li>
            <li>
              indien wij wettelijk daartoe gemachtigd zijn, kunnen wij uw verzoek afwijzen, maar wij zullen uitleggen
              waarom als wij dat doen.
            </li>
          </ul>
          <p>
            <b>Recht op verwijderen</b>: In bepaalde situaties (bijvoorbeeld wanneer wij uw gegevens onrechtmatig hebben
            verwerkt) heeft u het recht ons te verzoeken uw Persoonsgegevens te "verwijderen". Wij zullen binnen 30
            dagen op uw verzoek reageren (hoewel wij deze periode in bepaalde gevallen kunnen verlengen) en zullen het
            alleen met u oneens zijn als bepaalde beperkte voorwaarden van toepassing zijn. Indien wij wel akkoord gaan
            met uw verzoek, zullen wij uw gegevens verwijderen.
          </p>
          <p>
            <b>Recht op gegevensoverdraagbaarheid</b>: Als u dat wenst, hebt u het recht om uw gegevens van ons over te
            dragen aan een andere gegevensbeheerder. Wij zullen u hierbij helpen - hetzij door uw gegevens rechtstreeks
            voor u over te dragen, hetzij door u een kopie te bezorgen in een gangbaar machine-leesbaar formaat.
          </p>
          <p>
            <b>Recht om een klacht in te dienen bij een toezichthoudende autoriteit</b>: U hebt ook het recht om een
            klacht in te dienen bij het bureau van de commissaris voor gegevensbescherming.
          </p>
          <p>
            Als uw interesses of behoeften veranderen, kunt u zich afmelden voor een deel van of al onze marketinginhoud
            (bijvoorbeeld functie-emails of nieuwsbrieven van de TFO Group) door op de afmeldlink in de e-mail te
            klikken, of door ons een verzoek per e-mail te sturen).
          </p>
          <p>
            <b>Wijziging van dit beleid</b>
            Het kan nodig zijn dat wij deze Privacyverklaring van tijd tot tijd wijzigen. Wij zullen u op de hoogte
            brengen van materiële wijzigingen door bijvoorbeeld een kennisgeving op onze websites te plaatsen en/of door
            u een e-mail te sturen (als u uw e-mailgegevens bij ons hebt geregistreerd) wanneer wij daartoe verplicht
            zijn door de toepasselijke wetgeving. U bent verantwoordelijk voor het periodiek doornemen van deze
            Privacyverklaring.
          </p>
          <p>Deze privacyverklaring is voor het laatst bijgewerkt op 28 mei 2019.</p>
        </div>
      )}
    </div>
  );
};

Privacy.propTypes = {
  language: PropTypes.string,
  mc_email: PropTypes.string,
  mc_name: PropTypes.string,
  user: PropTypes.object
};

Privacy.defaultProps = {
  language: '',
  mc_email: '',
  mc_name: '',
  user: {}
};

export default Privacy;
