import React from 'react';
import PropTypes from 'prop-types';

class UnavailableComponent extends React.Component {
  render = () => {
    const { id, component_type } = this.props;
    return <p key={id}>Component {component_type} Could not be rendered yet!</p>;
  };
}

UnavailableComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  component_type: PropTypes.string
};

export default UnavailableComponent;
