const updateApiModel = (state, { props }) => {
  const newState = { ...state };

  for (const key in props) {
    newState[key] = props[key];
  }
  return newState;
};

export default updateApiModel;
