import React from 'react';
import PropTypes from 'prop-types';
import { Block, List, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { DataByteLogo } from 'Icons';
import { NavigationService } from 'Services';
import { Button, TextField } from 'Components';
import { ColumnView, RowView } from 'Containers';
import { handleOnChangeWithValidations, mockFunction, validations } from 'Helpers';

import './style.scss';

const ForgotPassword = ({ requestChangeUserPassword, transient, updateTransientPropWithValidations }) => {
  const getIconColor = (fieldHasError, hasFieldValue) => (fieldHasError ? 'red' : hasFieldValue ? 'blue' : 'gray');

  return (
    <Page noToolbar noNavbar noSwipeback className="page-content" id={'forgot-password'}>
      <ColumnView flex={1} minHeight={'100%'}>
        <ColumnView className="align-center" marginTop={60}>
          <img src={DataByteLogo} width={'35%'} height={'35%'} />
          <RowView>
            <ColumnView>
              <List form className="w-full">
                <TextField
                  label={I18n.t('inputs:email.label')}
                  type={'text'}
                  placeholder={I18n.t('inputs:email.placeholder')}
                  value={transient?.email || ''}
                  onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'email', [
                    validations.isRequired,
                    validations.emailValidation
                  ])}
                  validate
                  validateOnBlur
                  errorMessageForce={transient?.email_has_error}
                  errorMessage={I18n.t(transient?.email_error_message_key)}
                  icon="material:check"
                  iconColor={getIconColor(transient?.email_has_error, transient?.email)}
                />
              </List>
            </ColumnView>
          </RowView>
        </ColumnView>
        <ColumnView marginTop={'auto'}>
          <Block className="w-full mb-90">
            <Button.Primary
              disabled={transient?.email_has_error || !transient.email}
              onClick={requestChangeUserPassword.bind(null, { user: { email: transient?.email } })}>
              {I18n.t('general:resetEmail')}
            </Button.Primary>
            <Button.Outline
              className="mt-16"
              onClick={NavigationService.navigate.bind(null, {
                name: 'Login'
              })}>
              {I18n.t('general:cancel')}
            </Button.Outline>
          </Block>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

ForgotPassword.propTypes = {
  requestChangeUserPassword: PropTypes.func,
  transient: PropTypes.object,
  updateTransientPropWithValidations: PropTypes.func
};

ForgotPassword.defaultProps = {
  requestChangeUserPassword: mockFunction,
  transient: {},
  updateTransientPropWithValidations: mockFunction
};

export default ForgotPassword;
