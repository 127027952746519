import {
  Birthday,
  BrokenOrUnauthorized,
  Campaigns,
  Carousel,
  ChangePassword,
  CheckIn,
  CheckInList,
  Feedback,
  Finish,
  Flow,
  ForgotPassword,
  FullName,
  Login,
  NotFound,
  PostCodesList,
  Profile,
  Reports,
  ResetPassword,
  ResetPasswordConfirmation,
  Settings,
  SignUp,
  TermsAndConditions,
  ThankYou,
  Tools,
  VersionUpdate
} from 'Pages';

const publicRoutes = [
  {
    name: 'TermsAndConditions',
    path: '/public/user/signup/terms-and-conditions',
    component: TermsAndConditions
  },
  {
    name: 'Login',
    path: '/public/user/login',
    component: Login
  },
  {
    name: 'SignUp',
    path: '/public/user/signup',
    component: SignUp
  },
  {
    name: 'ForgotPassword',
    path: '/public/user/forgot-password',
    component: ForgotPassword
  },
  {
    name: 'BrokenOrUnauthorized',
    path: '/public/broken-or-unauthorized',
    component: BrokenOrUnauthorized
  },
  {
    name: 'ResetPassword',
    path: '/public/user/reset-password',
    component: ResetPassword
  },
  {
    name: 'ResetPasswordConfirmation',
    path: '/public/user/reset-password-confirmation',
    component: ResetPasswordConfirmation
  }
];

const sessionRoutes = [
  {
    name: 'Birthday',
    path: '/session/birthday',
    component: Birthday,
    accessPermission: 'agent'
  },
  {
    name: 'Carousel',
    path: '/session/carousel',
    component: Carousel,
    accessPermission: 'agent'
  },
  {
    name: 'Finish',
    path: '/session/finish',
    component: Finish,
    accessPermission: 'agent'
  },
  {
    name: 'FullName',
    path: '/session/full-name',
    component: FullName,
    accessPermission: 'agent'
  },
  {
    name: 'Campaigns',
    path: '/session/campaigns',
    component: Campaigns,
    accessPermission: 'agent'
  },
  {
    name: 'ChangePassword',
    path: '/session/change-password',
    component: ChangePassword,
    accessPermission: 'agent'
  },
  {
    name: 'CheckIn',
    path: '/session/checkin',
    component: CheckIn,
    accessPermission: 'agent'
  },
  {
    name: 'Feedback',
    path: '/session/feedback',
    component: Feedback
  },
  {
    name: 'Flow',
    path: '/session/flow',
    component: Flow
  },
  {
    name: 'Profile',
    path: '/session/profile',
    component: Profile,
    accessPermission: 'agent'
  },
  {
    name: 'Reports',
    path: '/session/reports',
    component: Reports,
    accessPermission: 'agent'
  },
  {
    name: 'Settings',
    path: '/session/settings',
    component: Settings,
    accessPermission: 'agent'
  },
  {
    name: 'Tools',
    path: '/session/tools',
    component: Tools,
    accessPermission: 'agent'
  },
  {
    name: 'ThankYou',
    path: '/session/thankyou',
    component: ThankYou
  },
  {
    name: 'PostCodesList',
    path: '/session/postcode-list',
    component: PostCodesList,
    accessPermission: 'agent'
  },
  {
    name: 'VersionUpdate',
    path: '/session/version-update',
    component: VersionUpdate
  },
  {
    name: 'CheckInList',
    path: '/session/check-in-list',
    component: CheckInList,
    accessPermission: 'agent'
  }
];

const routes = [
  ...publicRoutes,
  ...sessionRoutes,
  {
    path: '(.*)',
    component: NotFound
  }
];

export const defaultRoutes = {
  donorSession: {
    name: 'Flow',
    url: '/session/flow'
  },
  noSession: {
    name: 'Login',
    url: '/public/user/login'
  },
  session: {
    name: 'Campaigns',
    url: '/session/campaigns'
  }
};

export default routes;
