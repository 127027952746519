import React from 'react';
import { Block, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { Button } from 'Components';
import { ColumnView } from 'Containers';
import { ResetPasswordEmail } from 'Icons';
import { NavigationService } from 'Services';

import './style.scss';

const ResetPasswordConfirmation = () => (
  <Page id={'password-confirmation'}>
    <ColumnView height={'100%'}>
      <ColumnView className="align-center" marginTop={60}>
        <img src={ResetPasswordEmail} />
        <h3 className="title">{I18n.t('general:resetLink')}</h3>
        <p className="subtitle">{I18n.t('general:resetLinkSubtitle')}</p>
      </ColumnView>
      <ColumnView marginTop={'auto'}>
        <Block className="w-full mb-90">
          <Button.Primary
            onClick={NavigationService.navigate.bind(null, {
              name: 'ForgotPassword'
            })}>
            {I18n.t('general:goBack')}
          </Button.Primary>
        </Block>
      </ColumnView>
    </ColumnView>
  </Page>
);

export default ResetPasswordConfirmation;
