import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'Components';
import { ICON_COLOR, POSITION } from 'Constants';

import './style.scss';

const handleItemClick = ({ isOpen, onClick, setIsOpen }) => {
  onClick();
  setIsOpen(!isOpen);
};

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);
};

const Bubble = ({
  disabled = false,
  icon: Icon,
  items = [],
  label = '',
  loading = false,
  position = 'bottom',
  width = ''
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const poitionRight = position === 'right';

  useOutsideClick(dropdownRef, () => {
    setIsOpen(false);
  });

  return (
    <div className="dropdown" ref={dropdownRef}>
      <Button
        processing={loading}
        id="button"
        width={width}
        disabled={disabled}
        {...(!loading && { onClick: setIsOpen.bind(null, !isOpen) })}
        className={isOpen ? 'btn-primary' : 'btn-secondary'}
        round
        outline>
        <div className={`bubble-label ${isOpen ? 'active' : ''} ${Icon ? 'pr-4' : ''}`}>{label}</div>
        {Icon && <Icon fillColor={isOpen ? ICON_COLOR.WHITE : ICON_COLOR.BLUE} />}
      </Button>

      {isOpen && (
        <div className={`dropdown-content ${POSITION[position]}`}>
          {items.map(({ disabled, icon: Icon, label, onClick }, idx) => (
            <div
              className="flex justify-center align-center"
              key={idx}
              {...(!disabled && {
                onClick: handleItemClick.bind(null, { isOpen, onClick, setIsOpen })
              })}>
              {poitionRight && (
                <div className={`bubble-icon ${disabled ? 'icon-disabled' : ''} mr-4`}>
                  {Icon && <Icon fillColor={disabled ? ICON_COLOR.LIGHT_GRAY : ICON_COLOR.BLUE} />}
                </div>
              )}
              <div className={`bubble-items ${disabled ? 'items-disabled' : ''}`}>{label}</div>
              {!poitionRight && (
                <div className={`bubble-icon ${disabled ? 'icon-disabled' : ''} ml-4`}>
                  {Icon && <Icon fillColor={disabled ? ICON_COLOR.LIGHT_GRAY : ICON_COLOR.BLUE} />}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Bubble.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  loading: PropTypes.bool,
  position: PropTypes.string,
  width: PropTypes.string
};

export default Bubble;
