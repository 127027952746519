import React from 'react';
import PropTypes from 'prop-types';

const Person = ({ fillColor = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 15 14">
    <path
      fill={fillColor}
      stroke="#747474"
      strokeWidth="0.2"
      //eslint-disable-next-line no-useless-escape, max-len
      d="M7.833 6.385a2.889 2.889 0 0 1-2.119-.881 2.889 2.889 0 0 1-.881-2.12c0-.824.294-1.53.881-2.118A2.889 2.889 0 0 1 7.833.385c.825 0 1.531.294 2.119.881.587.588.881 1.294.881 2.119 0 .825-.294 1.531-.881 2.119a2.889 2.889 0 0 1-2.119.88Zm-6 7.23a1 1 0 0 1-1-1v-.646c0-.412.12-.799.36-1.158.241-.36.564-.639.97-.838a14.19 14.19 0 0 1 2.833-1.018c.945-.226 1.89-.34 2.837-.34.946 0 1.892.114 2.837.34a14.19 14.19 0 0 1 2.832 1.018c.407.199.73.478.97.838s.361.746.361 1.158v.647a1 1 0 0 1-1 1h-12Zm0-1h12v-.646c0-.221-.072-.43-.214-.625a1.725 1.725 0 0 0-.594-.494 12.687 12.687 0 0 0-2.546-.916 11.18 11.18 0 0 0-5.292 0c-.875.212-1.723.517-2.546.916-.253.135-.45.3-.594.494a1.037 1.037 0 0 0-.214.625v.647Zm6-7.23c.55 0 1.02-.196 1.413-.588.391-.391.587-.862.587-1.412 0-.55-.196-1.021-.587-1.413a1.926 1.926 0 0 0-1.413-.587c-.55 0-1.02.196-1.412.587a1.926 1.926 0 0 0-.588 1.413c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588Z"
    />
  </svg>
);

export default Person;

Person.propTypes = {
  fillColor: PropTypes.string
};
