import React from 'react';
import PropTypes from 'prop-types';
import { Fab as F7Fab } from 'framework7-react';

import { f7Colors } from 'Theme';

const emptyMethod = () => {};

const Fab = props => (
  <F7Fab {...props} color={props.disabled ? f7Colors.gray : f7Colors.blue} onClick={!props.disabled && props.onClick}>
    {props.children}
  </F7Fab>
);

Fab.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

Fab.defaultProps = {
  disabled: true,
  children: null,
  onClick: emptyMethod
};

export default Fab;
