import React from 'react';
import { Page, Link, Navbar, Block } from 'framework7-react';

import { NavigationService } from 'Services';

const NotFound = () => (
  <Page>
    <Navbar title="Not found" backLink="Back" />
    <Block strong>
      <p>Sorry</p>
      <p>Requested content not found.</p>
    </Block>
    <Link onClick={NavigationService.goBack}>Go back</Link>
  </Page>
);

export default NotFound;
