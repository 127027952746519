import { connect } from 'react-redux';

import Feedback from './view';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';

export default connect(state => ({ transient: state.transient }), {
  sendFeedback: userActions.sendFeedback,
  updateTransientProps: transientActions.updateProps
})(Feedback);
