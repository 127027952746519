import Config from 'Config';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Block, BlockFooter, List, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { DataByteLogo } from 'Icons';
import { validations } from 'Helpers';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { Button, DynamicLogo, TextField } from 'Components';

import './style.scss';

const handleChange = (key, updatePropWithValidations, validations, e) => {
  updatePropWithValidations(key, e.target.value, validations);
};

const getIconColor = (fieldHasError, hasFieldValue) => (fieldHasError ? 'red' : hasFieldValue ? 'blue' : 'gray');

const Login = ({
  client,
  donorLogin,
  isOffline,
  login,
  mode,
  orID,
  lastKnownEmail = '',
  serviceWorkerVersion,
  transient,
  updateTransientProps,
  updatePropWithValidations
}) => {
  const isDonor = mode === 'donor';

  useEffect(() => {
    /* on logout we keep the email on the application state and after reload 
    we need to update it in order to populate the email field for login */
    updateTransientProps({ email: lastKnownEmail });
  }, []);

  const {
    badge_number_has_error = true,
    badge_number,
    email_has_error = true,
    email,
    primaryLogo = '',
    password_has_error = true,
    password
  } = transient;

  const emailNotValid = email_has_error || !email;
  const passwordNotValid = password_has_error || !password;

  const isAgentLoginDisabled = passwordNotValid || emailNotValid;
  const isDonorLoginDisabled = badge_number_has_error || !badge_number;

  return (
    <Page noToolbar noNavbar noSwipeback id={'login-page'} className={transient?.error ? 'shake-form' : ''}>
      <ColumnView height={'100%'}>
        <ColumnView className="align-center" marginTop={60}>
          {!isDonor && <img src={DataByteLogo} width={'35%'} height={'35%'} />}
          {isDonor && client && (
            <div className="logo-container">
              <DynamicLogo name={client} height={'auto'} logo={primaryLogo} />
            </div>
          )}
          <RowView>
            <ColumnView>
              {!isDonor && (
                <List form className="w-full">
                  <TextField
                    label={I18n.t('inputs:email.label')}
                    type={'text'}
                    placeholder={I18n.t('inputs:email.placeholder')}
                    value={transient?.email || ''}
                    onChange={handleChange.bind(this, 'email', updatePropWithValidations, [
                      validations.emailValidation
                    ])}
                    errorMessageForce={transient?.email_has_error}
                    errorMessage={I18n.t(transient?.email_error_message_key)}
                    autofocus
                    validate
                    validateOnBlur
                    icon="material:check"
                    iconColor={getIconColor(transient?.email_has_error, transient?.email)}
                  />
                  <TextField
                    label={I18n.t('inputs:password.label')}
                    type="password"
                    placeholder={I18n.t('inputs:password.placeholder')}
                    value={transient?.password || ''}
                    onChange={handleChange.bind(this, 'password', updatePropWithValidations, [validations.isRequired])}
                    errorMessageForce={transient?.password_has_error}
                    errorMessage={I18n.t(transient?.password_error_message_key)}
                    validate
                    validateOnBlur
                    icon="material:check"
                    iconColor={getIconColor(transient?.password_has_error, transient?.password)}
                  />
                </List>
              )}
              {isDonor && (
                <List form className="w-full">
                  <TextField
                    label={I18n.t('inputs:baBadge.label')}
                    type={'text'}
                    placeholder={I18n.t('inputs:baBadge.placeholder')}
                    value={transient?.badge_number || ''}
                    onChange={handleChange.bind(this, 'badge_number', updatePropWithValidations, [
                      validations.isRequired
                    ])}
                    errorMessageForce={transient?.badge_number_has_error}
                    errorMessage={I18n.t(transient?.badge_number_error_message_key)}
                    autofocus
                    validate
                    validateOnBlur
                    icon="material:check"
                    iconColor={getIconColor(transient?.badge_number_has_error, transient?.badge_number)}
                  />
                </List>
              )}
            </ColumnView>
          </RowView>
        </ColumnView>
        <ColumnView marginTop={'auto'}>
          <Block className="w-full">
            <Button.Primary
              disabled={(isDonor && isDonorLoginDisabled) || (!isDonor && isAgentLoginDisabled) || isOffline}
              processing={transient?.processing}
              onClick={
                isDonor
                  ? donorLogin.bind(null, transient?.badge_number, orID)
                  : login.bind(null, transient?.email, transient?.password)
              }>
              {I18n.t(`general:${isOffline ? 'offline_' : ''}${isDonor ? 'unlockFlow' : 'signIn'}`)}
            </Button.Primary>
            {!isDonor && (
              <Button.Outline
                disabled={isOffline}
                className="mt-16"
                onClick={NavigationService.navigate.bind(null, {
                  name: 'ForgotPassword'
                })}>
                {I18n.t('general:resetPassword')}
              </Button.Outline>
            )}
            <ColumnView>
              <BlockFooter className="text-center">
                {I18n.t(`general:${isDonor ? 'donorLogin' : 'login'}Description`)}
              </BlockFooter>
              <BlockFooter className="mt-0 text-center">
                v: {Config.APP_VERSION}-{serviceWorkerVersion}
              </BlockFooter>
            </ColumnView>
          </Block>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

Login.propTypes = {
  client: PropTypes.string,
  donorLogin: PropTypes.func,
  isOffline: PropTypes.bool,
  lastKnownEmail: PropTypes.string,
  login: PropTypes.func,
  mode: PropTypes.string,
  orID: PropTypes.string,
  serviceWorkerVersion: PropTypes.string,
  transient: PropTypes.object,
  updateProp: PropTypes.func,
  updatePropWithValidations: PropTypes.func,
  updateTransientProps: PropTypes.func,
  user: PropTypes.object
};

export default Login;
