import { connect } from 'react-redux';

import * as Selectors from 'Selectors';
import { Creators as userActions } from 'Reducers/user';
import { Creators as flowActions } from 'Reducers/flow';
import { Creators as checkInActions } from 'Reducers/checkIn';
import { Creators as campaignActions } from 'Reducers/campaign';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as applicationActions } from 'Reducers/application';

import Campaigns from './view';

export default connect(
  state => ({
    badgeNumber: Selectors.validBadgeNumber(state),
    campaign: state.campaign,
    campaigns: Selectors.userCampaigns(state),
    checkInObject: Selectors.checkInObject(state),
    currentPage: state.application.currentPage,
    flow: state.flow,
    flowId: Selectors.flowId(state),
    flowReady: Selectors.flowReady(state),
    hasValidBadge: Selectors.hasValidBadge(state),
    isCheckedIn: Selectors.isCheckedIn(state),
    isOffline: state.application.isOffline,
    profilePages: state.application.profilePages,
    selectedCampaignPublishedFlowId: Selectors.selectedCampaignPublishedFlowId(state),
    transient: state.transient,
    user: state.user
  }),
  {
    checkIn: checkInActions.checkIn,
    checkOut: checkInActions.checkOut,
    getAttachments: userActions.getAttachments,
    getCampaigns: campaignActions.get,
    getOnboardingProfiles: userActions.getOnboardingProfiles,
    getPublishedFlows: flowActions.getPublishedFlows,
    getRoles: userActions.getRoles,
    getUser: userActions.getUser,
    setUserCampaign: userActions.setCampaign,
    updateApplicationProp: applicationActions.updateProp,
    updateProp: applicationActions.updateProp,
    updateTransientProps: transientActions.updateProps,
    updateUser: userActions.updateApiModel,
    updateUserProps: userActions.updateProps
  }
)(Campaigns);
