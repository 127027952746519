import React, { useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Block, Page } from 'framework7-react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { I18n } from 'Locales';
import { Button } from 'Components';
import { ColumnView } from 'Containers';
import { NavigationService } from 'Services';
import { GirlFashion, GirlRunning, Conversation } from 'Icons';

import 'swiper/css';
import './style.scss';
import 'swiper/css/pagination';

const carouselPage = (page, index) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <SwiperSlide className="swiper" key={index}>
      <ColumnView height={'100%'}>
        {isLoading && <div className="loader" />}
        <img src={page.picture} className="mb-32" onLoad={setIsLoading.bind(null, false)} />
        <div className="swiper-title">{I18n.t(page.title)}</div>
        <div className="swiper-subtitle pt-24"> {I18n.t(page.subtitle)}</div>
      </ColumnView>
    </SwiperSlide>
  );
};

const Carousel = () => {
  const carouselPages = [
    { title: I18n.t('general:readySetGo'), subtitle: I18n.t('general:subtitleReadySetGo'), picture: GirlFashion },
    { title: I18n.t('general:sellLikeAPro'), subtitle: I18n.t('general:subtitleSellLikeAPro'), picture: Conversation },
    { title: I18n.t('general:trackItAll'), subtitle: I18n.t('general:subtitleTrackItAll'), picture: GirlRunning }
  ];

  return (
    <Page>
      <ColumnView flex={1} height={'100%'}>
        <ColumnView className="align-center" marginTop={60} height={'100%'}>
          <Swiper pagination={true} modules={[Pagination]} className="carousel">
            {carouselPages.map(carouselPage)}
          </Swiper>
        </ColumnView>
        <ColumnView marginTop={'auto'}>
          <Block className="w-full mb-90">
            <Button.Primary
              width={'100%'}
              onClick={NavigationService.navigate.bind(null, {
                name: 'FullName'
              })}>
              {I18n.t('general:getStarted')}
            </Button.Primary>
          </Block>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

export default Carousel;
