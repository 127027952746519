import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Page } from 'framework7-react';

import { I18n } from 'Locales';
import { Button } from 'Components';
import { UpdateVersion } from 'Icons';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';

const VersionUpdate = ({ updateUserProps, updateTransientProps, clearSession }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleUpdate = () => {
    const updates = { version_update_required: false };

    updateTransientProps(updates);
    updateUserProps(updates);

    clearSession();
  };
  return (
    <Page id="versionUpdate">
      <ColumnView flex={1} height={'100%'} px={'18px'}>
        <ColumnView className="align-center" marginTop={60} height={'100%'}>
          {isLoading && <div className="loader" />}
          <img src={UpdateVersion} className="mb-32" onLoad={setIsLoading.bind(null, false)} />
          <div className="swiper-title">{I18n.t('general:updateVersion.title')}</div>
          <div className="swiper-subtitle pt-24"> {I18n.t('general:updateVersion.body')}</div>
        </ColumnView>
        <ColumnView marginTop={'auto'}>
          <div className="px-16 w-full">
            <Button.Primary className="mb-90" width={'100%'} onClick={() => handleUpdate()}>
              {I18n.t('general:reload')}
            </Button.Primary>
          </div>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

VersionUpdate.propTypes = {
  clearSession: PropTypes.func.isRequired,
  updateTransientProps: PropTypes.func.isRequired,
  updateUserProps: PropTypes.func.isRequired
};

VersionUpdate.defaultProps = {
  clearSession: mockFunction,
  updateTransientProps: mockFunction,
  updateUserProps: mockFunction
};
export default VersionUpdate;
