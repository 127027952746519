import { connect } from 'react-redux';

import * as Selectors from 'Selectors';
import { Creators as resultActions } from 'Reducers/result';
import { Creators as applicationActions } from 'Reducers/application';

import renderBaThankYou from './view';

export default connect(
  state => ({
    ...state.transient,
    isSubmittedOffline: Selectors.isSubmittedOffline(state),
    lastTransaction: state.application.lastTransaction,
    lastTransactionId: state.application.lastTransaction?.id,
    pictureUploaded: state.transient.pictureUploaded,
    submittedByBa: state.application.lastTransaction?.submittedByBa,
    user: state.user
  }),
  {
    updateApplicationProps: applicationActions.updateProps,
    uploadResultPicture: resultActions.uploadResultPicture
  }
)(renderBaThankYou);
