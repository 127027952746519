import React from 'react';

import PropTypes from 'prop-types';

const Documents = ({ fillColor = '#1C1B1F' }) => (
  <svg width="24" height="24" fill="none">
    <mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" masktype="alpha">
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fillColor}
        // eslint-disable-next-line max-len
        d="M6 21.5c-.699 0-1.29-.242-1.774-.726A2.413 2.413 0 0 1 3.5 19v-1.077c0-.497.177-.923.531-1.277a1.74 1.74 0 0 1 1.277-.53H6.5V4.307c0-.497.177-.923.531-1.277A1.74 1.74 0 0 1 8.308 2.5h10.384c.497 0 .923.177 1.277.531.354.354.531.78.531 1.277V19c0 .699-.242 1.29-.726 1.774A2.413 2.413 0 0 1 18 21.5H6ZM18 20c.283 0 .52-.096.712-.288A.968.968 0 0 0 19 19V4.308a.3.3 0 0 0-.087-.221.3.3 0 0 0-.22-.087H8.307a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.22v11.808h7.192c.497 0 .923.177 1.277.531.354.354.531.78.531 1.277V19c0 .283.096.52.288.712.191.192.429.288.712.288ZM9.942 8.692a.725.725 0 0 1-.534-.215.726.726 0 0 1-.216-.535c0-.213.072-.39.216-.534a.726.726 0 0 1 .534-.216h7.116c.212 0 .39.072.534.216a.726.726 0 0 1 .216.535c0 .212-.072.39-.216.534a.726.726 0 0 1-.534.215H9.942Zm0 2.885a.725.725 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.391.216-.535a.726.726 0 0 1 .534-.215h7.116c.212 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.39-.216.534a.726.726 0 0 1-.534.216H9.942ZM6 20h9.5v-2.077a.3.3 0 0 0-.086-.221.3.3 0 0 0-.222-.087H5.308a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.221V19c0 .283.096.52.287.712.192.192.43.288.713.288Zm0 0H5h10.5H6Z"
      />
    </g>
  </svg>
);

export default Documents;

Documents.propTypes = {
  fillColor: PropTypes.string
};
