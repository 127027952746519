import React from 'react';
import PropTypes from 'prop-types';
import { BlockTitle, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { ColumnView } from 'Containers';
import { DynamicLogo } from 'Components';

import './style.scss';

const BrokenOrUnauthorized = ({ client }) => (
  <Page id={'unauthorized'}>
    <ColumnView height={'100%'} backgroundColor={colors.standard}>
      <h3>{client && <DynamicLogo name={client} />}</h3>
      <img src={'/static/images/broken-link.png'} />

      <ColumnView paddingHorizontal={'10%'}>
        <BlockTitle large>{I18n.t('general:brokenOrUnauthorized.title')}</BlockTitle>
        <p>{I18n.t('general:brokenOrUnauthorized.description')}</p>
      </ColumnView>
    </ColumnView>
  </Page>
);

BrokenOrUnauthorized.propTypes = {
  client: PropTypes.string
};

export default BrokenOrUnauthorized;
