import React from 'react';

import { I18n } from 'Locales';
import { TextField } from 'Components';
import { FormattedLabel } from 'Helpers';
import { ColumnView, RowView } from 'Containers';

import '../style.scss';
import { propTypes } from './shared';
import { getFieldProp, getIconColor } from '../../../shared';

const FR = props => {
  const { fields, required, setValues, shouldShowError, showExtraInfo2, value } = props;

  let addressValues = value;
  if (!addressValues) {
    addressValues = {
      StreetName: '',
      PostCode: '',
      City: '',
      search_address: '',
      Number: '',
      Letter: '',
      ExtraInfo: '',
      ExtraInfo2: ''
    };
  }

  const numberProp = getFieldProp(fields, 'Number');
  const letterProp = getFieldProp(fields, 'Letter');
  const streetNameProp = getFieldProp(fields, 'StreetName');
  const extraInfoProp = getFieldProp(fields, 'ExtraInfo');
  const extraInfo2Prop = getFieldProp(fields, 'ExtraInfo2');
  const postCodeProp = getFieldProp(fields, 'PostCode');
  const cityProp = getFieldProp(fields, 'City');

  const { Number, Letter, StreetName, PostCode, City, ExtraInfo, ExtraInfo2 } = addressValues;
  return (
    <ColumnView>
      <div className="w-full">
        <TextField
          label={I18n.t('flow:components.address.numberLabel')}
          type={'number'}
          placeholder={numberProp?.placeholder}
          value={Number}
          className={'flex4'}
          onChange={e => setValues(value, { Number: e.target.value })}
          icon="material:check"
          iconColor={getIconColor(false, Number)}
        />
        <TextField
          label={I18n.t('flow:components.address.letterLabel')}
          type={'select'}
          placeholder={letterProp?.placeholder}
          value={Letter}
          onChange={e => setValues(value, { Letter: e.target.value })}>
          <option value={''}></option>
          <option value={'B'}>B</option>
          <option value={'T'}>T</option>
          <option value={'Q'}>Q</option>
        </TextField>
        <TextField
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.streetNameLabel') })}
          type={'text'}
          placeholder={streetNameProp?.placeholder}
          value={StreetName}
          onChange={e => setValues(value, { StreetName: e.target.value })}
          errorMessageForce={shouldShowError && StreetName === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && StreetName === '', StreetName)}
        />
        <TextField
          label={I18n.t('flow:components.address.extraInfoLabel')}
          type={'text'}
          placeholder={extraInfoProp?.placeholder}
          value={ExtraInfo}
          onChange={e => setValues(value, { ExtraInfo: e.target.value })}
          icon="material:check"
          iconColor={getIconColor(false, ExtraInfo)}
        />
      </div>

      {showExtraInfo2 && (
        <RowView>
          <TextField
            label={I18n.t('flow:components.address.extraInfo2Label')}
            type={'text'}
            placeholder={extraInfo2Prop?.placeholder}
            value={ExtraInfo2}
            onChange={e => setValues(value, { ExtraInfo2: e.target.value })}
            className={'flex4'}
            icon="material:check"
            iconColor={getIconColor(false, ExtraInfo2)}
          />
        </RowView>
      )}

      <div className="w-full">
        <TextField
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.postalCodeLabel') })}
          type={'text'}
          placeholder={postCodeProp?.placeholder}
          value={PostCode}
          onChange={e => setValues(value, { PostCode: e.target.value })}
          errorMessageForce={shouldShowError && PostCode === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && PostCode === '', PostCode)}
        />
        <TextField
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.cityLabel') })}
          type={'text'}
          placeholder={cityProp?.placeholder}
          value={City}
          onChange={e => setValues(value, { City: e.target.value })}
          errorMessageForce={shouldShowError && City === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && City === '', City)}
        />
      </div>
    </ColumnView>
  );
};

FR.propTypes = propTypes;

export default FR;
