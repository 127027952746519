import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';

import Birthday from './view';

export default connect(
  state => ({
    transient: state.transient
  }),
  {
    updateUser: userActions.updateApiModel,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations
  }
)(Birthday);
