/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'framework7-react';

import { ArrowBack } from 'Icons';
import { Avatar } from 'Components';
import { PAGE_NAMES } from 'Constants';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';

import './style.scss';

const handleOnClickAvatar = ({ onProfileRedirect, updateApplicationProp }) => {
  if (onProfileRedirect !== undefined) {
    onProfileRedirect();
  } else {
    NavigationService.navigate({
      name: PAGE_NAMES.PROFILE
    });
  }

  updateApplicationProp('selectedAppBottomBarSection', {
    label: 'Profile'
  });
};

const AppHeader = ({
  banner,
  children,
  goBack,
  handleClick,
  hideAvatar,
  onProfileRedirect,
  title,
  updateApplicationProp,
  user
}) => (
  <div className="sticky-top">
    <RowView
      backgroundColor="white"
      minHeight={60}
      maxHeight={70}
      justifyContent={'space-between'}
      paddingHorizontal={16}>
      <RowView justifyContent={'flex-start'} maxWidth={50}>
        {goBack && (
          <Link onClick={handleClick}>
            <ArrowBack />
          </Link>
        )}
      </RowView>
      <RowView>
        <div className="nav-title">{title}</div>
      </RowView>
      <RowView justifyContent={'flex-end'} maxWidth={50}>
        {user && Object.keys(user).length !== 0 && !hideAvatar && (
          <Avatar
            size={40}
            source={user?.avatar_url}
            user={user}
            userAvatar
            onClick={handleOnClickAvatar.bind(null, { onProfileRedirect, updateApplicationProp })}
          />
        )}
      </RowView>
    </RowView>
    {banner}
    <ColumnView paddingHorizontal={16}>{children}</ColumnView>
  </div>
);

AppHeader.propTypes = {
  children: PropTypes.node,
  hideAvatar: PropTypes.bool,
  onProfileRedirect: PropTypes.func,
  title: PropTypes.string,
  user: PropTypes.shape({
    avatar_url: PropTypes.string
  })
};

AppHeader.defaultProps = {
  children: null,
  hideAvatar: false,
  onProfileRedirect: undefined,
  title: '',
  user: null
};

export default AppHeader;
