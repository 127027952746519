import React from 'react';
import PropTypes from 'prop-types';

const Reupload = ({ fillColor = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      //eslint-disable-next-line no-useless-escape, max-len
      d="M18.333 9.584v2.583c0 1.867 0 2.8-.363 3.513a3.334 3.334 0 0 1-1.457 1.457c-.713.363-1.646.363-3.513.363H7c-1.867 0-2.8 0-3.513-.363A3.333 3.333 0 0 1 2.03 15.68c-.363-.713-.363-1.646-.363-3.513V7.834c0-1.867 0-2.8.363-3.514.32-.627.83-1.137 1.457-1.456C4.2 2.5 5.133 2.5 7 2.5h3.417m5.416 4.167v-5m-2.5 2.5h5m-5 5.833a3.333 3.333 0 1 1-6.666 0 3.333 3.333 0 0 1 6.666 0Z"
    />
  </svg>
);

Reupload.propTypes = {
  fillColor: PropTypes.string
};

export default Reupload;
