import React from 'react';
import { I18n } from 'Locales';
import PropTypes from 'prop-types';
import PasswordStrengthBar from 'react-password-strength-bar';

import './style.scss';

const StrengthBar = ({ password, className }) => {
  const shortScoreWord = I18n.t('settings:passwordStrength.tooShort');
  const scoreWords = [
    I18n.t('settings:passwordStrength.weak'),
    I18n.t('settings:passwordStrength.weak'),
    I18n.t('settings:passwordStrength.okay'),
    I18n.t('settings:passwordStrength.good'),
    I18n.t('settings:passwordStrength.strong')
  ];

  return (
    <PasswordStrengthBar
      password={password}
      scoreWords={scoreWords}
      shortScoreWord={password && shortScoreWord}
      className={`passwordStrengthBar ${className}`}
    />
  );
};

StrengthBar.propTypes = {
  className: PropTypes.string,
  password: PropTypes.string
};

StrengthBar.defaultProps = {
  className: '',
  password: ''
};

export default StrengthBar;
