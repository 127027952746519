import React from 'react';
import PropTypes from 'prop-types';

const AlertCircle = ({ fillColor = '#444444' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
    <path
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10 7.167V10.5m0 3.334h.008m8.325-3.334a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0Z"
    />
  </svg>
);

export default AlertCircle;

AlertCircle.propTypes = {
  fillColor: PropTypes.string
};
