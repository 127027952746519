import React from 'react';
import PropTypes from 'prop-types';

const TrashCan = ({ fillColor = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <mask id="a" width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse" maskType="alpha">
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fillColor}
        //eslint-disable-next-line no-useless-escape, max-len
        d="M6.09 17.083c-.416 0-.77-.147-1.065-.441a1.451 1.451 0 0 1-.442-1.065V5h-.208a.605.605 0 0 1-.446-.18.605.605 0 0 1-.179-.445c0-.177.06-.326.18-.446s.268-.18.445-.18H7.5a.71.71 0 0 1 .216-.52.71.71 0 0 1 .521-.216h3.526a.71.71 0 0 1 .521.215.71.71 0 0 1 .216.522h3.125c.177 0 .326.06.445.18.12.119.18.268.18.445s-.06.326-.18.445a.605.605 0 0 1-.445.18h-.208v10.577c0 .415-.147.77-.442 1.065-.294.294-.65.441-1.065.441H6.09ZM14.167 5H5.833v10.577a.25.25 0 0 0 .072.184.25.25 0 0 0 .185.072h7.82a.25.25 0 0 0 .184-.072.25.25 0 0 0 .073-.184V5Zm-6.33 9.166h1.25v-7.5h-1.25v7.5Zm3.076 0h1.25v-7.5h-1.25v7.5Z"
      />
    </g>
  </svg>
);

export default TrashCan;

TrashCan.propTypes = {
  fillColor: PropTypes.string
};
