import Config from 'Config';
import { Api } from 'Core';

const get = (country = '') => {
  const countryAddressesPrefix = {
    fr: '',
    be: 'be_'
  };
  return Api.get(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/${countryAddressesPrefix[country]}address_details/postcodes`
  );
};

export default { get };
