import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'framework7-react';

import { I18n } from 'Locales';
import { PAGE_NAMES } from 'Constants';
import { AppHeader } from 'Components';
import { NavigationService } from 'Services';
import { mockComponent, mockFunction } from 'Helpers';

import './style.scss';
import { Terms, Conditions, Privacy } from './subviews';

const book = {
  conditions_agreement: {
    component: Conditions,
    title: 'legalAgreements'
  },
  privacy_agreement: {
    component: Privacy,
    title: 'privacyStatement'
  },
  terms_agreement: {
    component: Terms,
    title: 'mediaReleaseForm'
  }
};

const TermsAndConditions = ({ application, renderKey, updateTransientProps, user }) => {
  const BookRender = book?.[renderKey]?.component || mockComponent;
  const BookTitle = book?.[renderKey]?.title || '';

  const handleNavigate = updateTransientProps => {
    if (application.session) {
      NavigationService.goBack({});
    } else {
      NavigationService.goBack({
        name: PAGE_NAMES.SIGNUP
      });
    }
    updateTransientProps({ tc_modal: true });
  };

  return (
    <Page id={'terms-and-condition'}>
      <AppHeader
        goBack
        hideAvatar
        title={I18n.t(`general:${BookTitle}`)}
        handleClick={handleNavigate.bind(null, updateTransientProps)}
      />
      <BookRender language={application.locale} user={user} />
    </Page>
  );
};

TermsAndConditions.propTypes = {
  application: PropTypes.object,
  language: PropTypes.string,
  renderKey: PropTypes.string,
  updateTransientProps: PropTypes.func,
  user: PropTypes.object
};

TermsAndConditions.defaultProps = {
  application: {},
  language: '',
  renderKey: '',
  updateTransientProps: mockFunction,
  user: {}
};

export default TermsAndConditions;
