import { connect } from 'react-redux';

import { Creators as transientActions } from 'Reducers/transient';

import TermsAndConditions from './view';

export default connect(
  state => ({
    ...state.transient,
    application: state.application,
    user: state.user
  }),
  {
    updateTransientProps: transientActions.updateProps
  }
)(TermsAndConditions);
