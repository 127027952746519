import React from 'react';
import PropTypes from 'prop-types';

const Warning = ({ fillColor = '#BB8C00' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <mask id="mask0_3331_35190" maskUnits="userSpaceOnUse" masktype="alpha" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3331_35190)">
      <path
        // eslint-disable-next-line max-len
        d="M3.42546 20.5004C3.25596 20.5004 3.10373 20.4589 2.96878 20.3761C2.83383 20.2932 2.72887 20.184 2.65391 20.0484C2.57592 19.9138 2.53303 19.768 2.52523 19.6109C2.51743 19.4539 2.55981 19.2984 2.65236 19.1443L11.2131 4.35652C11.3057 4.20249 11.4215 4.08861 11.5606 4.0149C11.6997 3.94116 11.8462 3.9043 12 3.9043C12.1539 3.9043 12.3003 3.94116 12.4394 4.0149C12.5785 4.08861 12.6944 4.20249 12.7869 4.35652L21.3477 19.1443C21.4402 19.2984 21.4826 19.4539 21.4748 19.6109C21.467 19.768 21.4241 19.9138 21.3461 20.0484C21.2711 20.184 21.1662 20.2932 21.0312 20.3761C20.8963 20.4589 20.7441 20.5004 20.5746 20.5004H3.42546ZM4.45001 19.0004H19.55L12 6.0004L4.45001 19.0004ZM12 17.8081C12.2289 17.8081 12.4207 17.7307 12.5755 17.5759C12.7303 17.4211 12.8077 17.2292 12.8077 17.0004C12.8077 16.7715 12.7303 16.5797 12.5755 16.4249C12.4207 16.2701 12.2289 16.1927 12 16.1927C11.7712 16.1927 11.5793 16.2701 11.4245 16.4249C11.2697 16.5797 11.1923 16.7715 11.1923 17.0004C11.1923 17.2292 11.2697 17.4211 11.4245 17.5759C11.5793 17.7307 11.7712 17.8081 12 17.8081ZM12.0003 15.1927C12.2129 15.1927 12.391 15.1208 12.5346 14.9771C12.6782 14.8333 12.75 14.6552 12.75 14.4427V10.9427C12.75 10.7302 12.6781 10.5521 12.5343 10.4083C12.3905 10.2646 12.2123 10.1927 11.9997 10.1927C11.7871 10.1927 11.609 10.2646 11.4654 10.4083C11.3218 10.5521 11.25 10.7302 11.25 10.9427V14.4427C11.25 14.6552 11.3219 14.8333 11.4657 14.9771C11.6095 15.1208 11.7877 15.1927 12.0003 15.1927Z"
        fill={fillColor}
      />
    </g>
  </svg>
);

Warning.propTypes = {
  fillColor: PropTypes.string
};

export default Warning;
