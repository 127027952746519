import { connect } from 'react-redux';

import Login from './view';

export default connect(
  state => ({
    client: state.application.client
  }),
  null
)(Login);
