import React from 'react';
import PropTypes from 'prop-types';

import style from './style';

const FullView = props => {
  const composedStyles = {
    ...style.fullview,
    ...props.style,
    backgroundColor: props.backgroundColor
  };
  return props.main ? (
    <main id={props.id} style={composedStyles}>
      {props.children}
    </main>
  ) : (
    <div id={props.id} style={composedStyles}>
      {props.children}
    </div>
  );
};

FullView.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.any,
  id: PropTypes.string,
  main: PropTypes.bool,
  style: PropTypes.object
};

FullView.defaultProps = {
  backgroundColor: 'transparent',
  children: null,
  id: 'F7-App',
  main: false,
  style: {}
};

export default FullView;
