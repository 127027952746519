import { connect } from 'react-redux';

import ChangePassword from './view';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';

export default connect(
  state => ({
    transient: state.transient
  }),
  {
    updateUser: userActions.updateApiModel,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations,
    validateProp: transientActions.validateProp
  }
)(ChangePassword);
