import React from 'react';
import PropTypes from 'prop-types';

import { colors } from 'Theme';

const Circle = props => (
  <div
    style={{
      borderRadius: '50%',
      backgroundColor: props.fill,
      width: props.size,
      height: props.size
    }}
  />
);

Circle.propTypes = {
  fill: PropTypes.any,
  size: PropTypes.number
};

Circle.defaultProps = {
  fill: colors.darkGrey,
  size: 10
};

export default Circle;
