import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';

import FullName from './view';

export default connect(
  state => ({
    transient: state.transient,
    user: state.user
  }),
  {
    updateUser: userActions.updateApiModel,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations
  }
)(FullName);
