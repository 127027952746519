import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';

import ResetPassword from './view';

export default connect(
  state => ({
    transient: state.transient,
    lastKnownAvatar: state.application.lastKnownAvatar
  }),
  {
    resetPassword: userActions.resetPassword,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations,
    validateProp: transientActions.validateProp
  }
)(ResetPassword);
