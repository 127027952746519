import Config from 'Config';
import { Api } from 'Core';

const checkIn = (published_flow_id, selected_postcodes) =>
  Api.post(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/check_ins`,
    { data: { attributes: { published_flow_id, selected_postcodes } } },
    false
  );

const checkOut = id => Api.delete(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/check_ins/${id}`);

const get = () => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/check_ins`);

const getAddress = (postCodes, country = '') => {
  const countryAddressesPrefix = {
    fr: '',
    be: 'be_'
  };
  return Api.get(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/${
      countryAddressesPrefix[country]
    }address_details?by_postcodes=${postCodes.replace(/\s+/g, '')}`
  );
};

export default { checkIn, checkOut, get, getAddress };
