import { createActions, createReducer } from 'reduxsauce';

import { updateProp, updateProps } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    activateAccount: ['session'],
    activateAccountFail: null,
    activateAccountSuccess: ['payload'],
    apiError: ['error'],
    checkOnline: null,
    backendLoginError: null,
    backendLoginSuccessful: ['payload'],
    brokenOrUnauthorizedAccess: null,
    checkDonorLoginCompleted: null,
    clearSession: ['isDonor'],
    closeSplashScreen: null,
    donorLogin: ['badge_number', 'orID'],
    donorLoginCompleted: ['flowId'],
    donorLoginError: ['error'],
    donorLoginSuccessful: ['payload'],
    initFrameworkSeven: null,
    login: ['email', 'password'],
    logout: null,
    navigateTo: ['params'],
    navigateBack: null,
    onOrientationChange: null,
    saveUserPublicInfo: ['email', 'avatar', 'id'],
    persisted: null,
    postConfirmationToken: ['confirmation_token'],
    postConfirmationTokenFail: null,
    postConfirmationTokenSuccess: ['payload'],
    routechanged: ['newRoute', 'previousRoute'],
    securityLogout: null,
    sessionExpiredLogout: null,
    updateProp: ['key', 'value'],
    updateProps: ['props'],
    userIsOnline: ['displayGrowl']
  },
  { prefix: 'application/' }
);

export const appInitialState = {
  isOffline: !window.navigator.onLine,
  offlineSince: null,
  routing: {
    history: [],
    historyParams: []
  },
  session: false,
  token: null,
  warnedSecurityLogout: false
};

const initialState = {
  country: 'default',
  initialised: false,
  lastKnownEmail: '',
  lastKnownAvatar: null,
  locale: 'en',
  mode: 'agent',
  orientation: 'portrait',
  serviceWorkerVersion: 'NoSWV',
  currentPage: 'profile',
  profilePages: ['profile', 'identity', 'bank_details', 'contact', 'documents'],
  selectedAppBottomBarSection: null,
  appBottomBarSections: [{ label: 'Home' }, { label: 'Tools' }, { label: 'Profile' }],
  ...appInitialState
};

export const navigateBack = state => {
  const newHistory = [...state.routing.history].slice(0, -1);
  const newHistoryParams = [...state.routing.historyParams].slice(0, -1);

  return {
    ...state,
    routing: {
      ...state.routing,
      history: newHistory,
      historyParams: newHistoryParams
    }
  };
};

export const navigateTo = (state, { params: { name, params } }) => ({
  ...state,
  routing: {
    history: [...state.routing.history, name],
    historyParams: [...state.routing.historyParams, params]
  }
});

export const saveUserPublicInfo = (state, { avatar, email, id }) => ({
  ...state,
  lastKnownEmail: email,
  lastKnownAvatar: avatar,
  lastKnownUserId: id
});

export default createReducer(initialState, {
  [Types.NAVIGATE_BACK]: navigateBack,
  [Types.NAVIGATE_TO]: navigateTo,
  [Types.SAVE_USER_PUBLIC_INFO]: saveUserPublicInfo,
  [Types.UPDATE_PROP]: updateProp,
  [Types.UPDATE_PROPS]: updateProps
});

export const ba = state => state.application.ba;
export const client = state => state.application.client;
export const isOffline = state => state.application.isOffline;
export const lastKnownAvatar = state => state.application.lastKnownAvatar;
export const lastKnownEmail = state => state.application.lastKnownEmail;
export const lastTransaction = state => state.application.lastTransaction;
export const lastTransactionId = state => state.application.lastTransaction.id;
export const locale = state => state.application.locale;
export const mode = state => state.application.mode;
export const offlineSince = state => state.application.offlineSince;
export const orID = state => state.application.orID;
export const routing = state => state.application.routing;
export const session = state => state.application.session;
export const token = state => state.application.token;
export const warnedSecurityLogout = state => state.application.warnedSecurityLogout;
