import { delay } from 'redux-saga/effects';

import { NavigationService } from 'Services';
import { Creators as ApplicationCreators } from 'Reducers/application';

export const orientationChangeDetected = () => {
  NavigationService.dispatch(ApplicationCreators.onOrientationChange());
};

export const onOrientationChange = function*() {
  yield delay(300);
  let orientation = 'portrait';
  if (window.matchMedia('(orientation: landscape)').matches) {
    orientation = 'landscape';
  }
  NavigationService.dispatch(ApplicationCreators.updateProp('orientation', orientation));
};
