import React from 'react';
import PropTypes from 'prop-types';
import { List, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { Button, TextField } from 'Components';
import { ColumnView, RowView } from 'Containers';

import './style.scss';

const PassFlowModal = ({
  disableButton,
  donorName,
  handleChange,
  handleSubmit,
  onClose,
  options,
  sendingOption,
  sendingOptionsConfig,
  subtitle,
  title
}) => (
  <Page id={'pass-flow-modal'}>
    <ColumnView paddingBottom={20} paddingTop={20} justifyContent={'space-between'} minHeight={'100%'}>
      <ColumnView>
        <ColumnView paddingHorizontal={20} gap={10}>
          <div className="title-md">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </ColumnView>

        <ColumnView gap={10} marginTop={10}>
          <List className="w-full">
            <TextField
              label={I18n.t('flow:sendingOption')}
              type="select"
              value={sendingOption}
              onChange={e => handleChange('sending_option', e.target.value)}>
              <option value="" disabled>
                {I18n.t('flow:sendingOption')}
              </option>
              {options?.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </List>
          {sendingOption && (
            <List className="w-full">
              {sendingOptionsConfig[sendingOption].map(({ label, type, placeholder, value, onChange }) => (
                <TextField
                  label={label}
                  type={type}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  key={label}
                />
              ))}
            </List>
          )}
        </ColumnView>
      </ColumnView>

      <ColumnView justifyContent={'flex-end'}>
        {!!donorName && donorName !== '' && (
          <RowView flexWrap={'wrap'} justifyContent={'flex-start'} paddingHorizontal={20}>
            <span className="text-sm pr-4">{I18n.t('reports:passFlowTo')}</span>
            <div className="font-semibold pr-4">{donorName}</div>
            <span>?</span>
          </RowView>
        )}
        <RowView gap={20} paddingHorizontal={20} justifyContent={'flex-end'} marginTop={'auto'}>
          <Button.Outline className="mt-20" onClick={onClose}>
            {I18n.t('general:cancel')}
          </Button.Outline>
          <Button.Primary className="mt-20" onClick={handleSubmit} disabled={disableButton}>
            {I18n.t('flow:send')}
          </Button.Primary>
        </RowView>
      </ColumnView>
    </ColumnView>
  </Page>
);

PassFlowModal.propTypes = {
  disableButton: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  donorName: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  sendingOption: PropTypes.string,
  sendingOptionsConfig: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

PassFlowModal.defaultProps = {
  disableButton: false,
  handleChange: mockFunction,
  donorName: '',
  handleSubmit: mockFunction,
  onClose: mockFunction,
  options: [],
  sendingOption: '',
  sendingOptionsConfig: {},
  subtitle: '',
  title: ''
};
export default PassFlowModal;
