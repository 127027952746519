const PwaService = {
  promptEvent: null,
  init: () => {
    window.addEventListener('beforeinstallprompt', e => {
      PwaService.promptEvent = e;
      PwaService.promptEvent.preventDefault();
    });
    return false;
  },
  showPrompt: () => {
    if (PwaService.promptEvent) {
      PwaService.promptEvent.prompt();
    }
  }
};

export default PwaService;
