import React from 'react';
import PropTypes from 'prop-types';
import { Link, Tabs, Tab, Toolbar as ToolbarF7 } from 'framework7-react';

import { I18n } from 'Locales';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { Assignment, Home, Person } from 'Icons';
import { ICON_COLOR, PAGE_NAMES } from 'Constants';

const DefaultBottomBar = ({ application, offlineResults, updateApplicationProp, user }) => {
  const bottomBarItems = [
    {
      id: 1,
      label: I18n.t('general:home'),
      icon: 'Home',
      pageName: PAGE_NAMES.CAMPAIGNS
    },
    {
      id: 2,
      label: I18n.t('general:tools'),
      icon: 'Assigment',
      pageName: PAGE_NAMES.TOOLS,
      iconNotification: offlineResults?.length
    },
    {
      id: 3,
      label: I18n.t('general:profile'),
      icon: 'Person',
      pageName: PAGE_NAMES.PROFILE,
      iconNotification:
        (user?.blank_fields?.length || 0) +
        (user?.needs_attention_documents?.length || 0) +
        (user?.rejected_fields?.length || 0)
    }
  ];

  const handleNavigate = ({ updateApplicationProp, item }) => {
    NavigationService.navigate({
      name: item.pageName
    });
    updateApplicationProp('selectedAppBottomBarSection', item);
  };

  const componentMap = {
    Home: Home,
    Assigment: Assignment,
    Person: Person
  };

  const renderBottomBarItem = (item, index) => {
    const IconComponent = componentMap[item.icon];

    const itemSelected = item.label === application?.selectedAppBottomBarSection?.label;

    return (
      <RowView height={74} key={index} paddingVerticaly={12}>
        <Link
          tabLink={item.label}
          tabLinkActive={itemSelected}
          onClick={handleNavigate.bind(null, { updateApplicationProp, item })}>
          <div className="position-relative">
            <IconComponent fillColor={itemSelected ? ICON_COLOR.BLUE : ICON_COLOR.GRAY} />
            {!!item.iconNotification && <span className="dot-notification">{item.iconNotification}</span>}
          </div>
          <div className={itemSelected ? 'text-blue' : ''}>{item.label}</div>
        </Link>
      </RowView>
    );
  };

  const renderTabItem = (item, index) => (
    <ColumnView key={index}>
      <Tab id={item.label} tabActive={item.label === application?.selectedAppBottomBarSection?.label} />
    </ColumnView>
  );

  return (
    <>
      <ToolbarF7 position={'bottom'} tabbar noShadow noHairLine scrollable id={'bottom-bar'}>
        {bottomBarItems.map(renderBottomBarItem)}
      </ToolbarF7>
      <Tabs>{bottomBarItems.map(renderTabItem)}</Tabs>
    </>
  );
};

DefaultBottomBar.propTypes = {
  application: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  offlineResults: PropTypes.array,
  updateApplicationProp: PropTypes.func.isRequired
};

export default DefaultBottomBar;
