import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as applicationActions } from 'Reducers/application';

import VersionUpdate from './view';

export default connect(null, {
  clearSession: applicationActions.clearSession,
  updateTransientProps: transientActions.updateProps,
  updateUserProps: userActions.updateProps
})(VersionUpdate);
