import React from 'react';
import { Page } from 'framework7-react';

import { I18n } from 'Locales';
import { PAGE_NAMES } from 'Constants';
import { CheckInScooter } from 'Icons';
import { NavigationService } from 'Services';
import { Button, AppHeader } from 'Components';
import { ColumnView, RowView } from 'Containers';

import './style.scss';

const CheckIn = () => (
  <Page>
    <AppHeader
      user
      goBack
      title={I18n.t('checkIn:postcode')}
      handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.CAMPAIGNS })}
    />
    <ColumnView minHeight={'calc(100% - 60px)'} justifyContent={'center'} paddingHorizontal={20}>
      <ColumnView height={'70%'} paddingBottom={32}>
        <RowView>
          <ColumnView>
            <img src={CheckInScooter} className="mb-32" />
            <div className="check-in-title">{I18n.t('checkIn:descriptionTitle')}</div>
            <div className="check-in-subtitle pt-24">{I18n.t('checkIn:descriptionMessage')}</div>
          </ColumnView>
        </RowView>
      </ColumnView>
      <RowView paddingTop={32}>
        <Button.Primary
          width={'100%'}
          onClick={NavigationService.navigate.bind(null, {
            name: 'CheckInList'
          })}>
          {I18n.t('general:getStarted')}
        </Button.Primary>
      </RowView>
    </ColumnView>
  </Page>
);

export default CheckIn;
