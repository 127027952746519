import Config from 'Config';
import { Api } from 'Core';

const bankAccountCheckFR = account_number =>
  Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/bank_services/validate_iban?iban=${account_number}`);

const bankAccountCheckUK = (account_number, sort_code) =>
  Api.get(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/bank_services/validate_account_number` +
      `?account_number=${account_number}&sort_code=${sort_code}`
  );

const deleteAttachment = id => Api.delete(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/attachments/${id}`);

const donorLogin = (badge_number, token) =>
  Api.post(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/badges/validate`,
    {
      badge_number,
      token
    },
    false
  );

const getAttachments = (campaignId = '') =>
  Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/attachments?by_campaign=${campaignId}`);

const getCountries = () => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/countries`);

const getExecutionToken = () => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/users/execution_token`);

const getPostCodes = (data, country = '') => {
  const trimmedData = data.trim();

  const countryConfigurations = {
    prefix: {
      fr: '',
      be: 'be_'
    }
  };
  return Api.get(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/${
      countryConfigurations.prefix[country]
    }address_details?page=1&per_page=100&by_full_address=${trimmedData}`
  );
};

const postConfirmationToken = confirmation_token =>
  Api.post(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/sessions/verify_confirmation_token`,
    confirmation_token,
    false
  );

const activateAccount = session =>
  Api.post(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/sessions/activate_account`,
    { session: { password: session.password, email: session.email } },
    false
  );

const login = (email, password) =>
  Api.post(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/sessions/?include=active_badges`,
    {
      session: {
        email,
        password
      }
    },
    false
  );

const readAttachment = payload =>
  Api.put(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/attachments/${payload.id}`, payload, false);

const uploadAttachments = data => Api.post(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/attachments`, data, false);

const sendFeedback = (feedback_type, feedback_message) =>
  Api.post(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/feedback`,
    {
      data: { attributes: { feedback_type, feedback_message } }
    },
    false
  );

const requestChangeUserPassword = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/passwords/`, data, false);

const resetPassword = payload => Api.put(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/passwords/`, payload, false);

const updateUser = user =>
  Api.patch(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/users/${user.id}/?include=active_badges`, user, false);

const getUser = id => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/users/${id}/?include=active_badges`);

const updateUserAvatar = data => Api.post(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/user_avatars/`, data, false);

const getOnboardingProfiles = id =>
  Api.get(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles?by_user_id=${id}`);

const getRoles = () => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/roles`);

const getUKAddress = data => {
  const { type = '', id = '', text = '' } = data;

  return Api.get(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/address?text=${text}&country=UK${type ? `&type=${type}` : ''}${
      id ? `&id=${id}` : ''
    }`
  );
};

export default {
  activateAccount,
  bankAccountCheckFR,
  bankAccountCheckUK,
  deleteAttachment,
  donorLogin,
  getAttachments,
  getCountries,
  getExecutionToken,
  getOnboardingProfiles,
  getUser,
  getPostCodes,
  getRoles,
  getUKAddress,
  login,
  postConfirmationToken,
  readAttachment,
  requestChangeUserPassword,
  resetPassword,
  sendFeedback,
  updateUser,
  updateUserAvatar,
  uploadAttachments
};
