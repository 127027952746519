import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { BlockTitle, List, ListItem } from 'framework7-react';

import { I18n } from 'Locales';
import { Warning } from 'Icons';
import { mockFunction } from 'Helpers';
import { RejectionReason } from 'Components';
import { NavigationService } from 'Services';
import { COUNTRIES_ADDRESS } from 'Constants';
import { ColumnView, RowView } from 'Containers';
import { REGIONS, ONBOARDING_FIELDS } from 'Constants';
import { isRejectedField } from 'Pages/session/Profile/utils/helpers';
import TextFieldComponent from 'Pages/session/Profile/ProfileTextField';
import { profileTextFieldsConfig } from 'Pages/session/Profile/utils/config';

import './style.scss';

const areEqual = (prevProps, nextProps) =>
  prevProps.isOffline === nextProps.isOffline &&
  Object.keys(prevProps.transient).every(key => prevProps.transient[key] === nextProps.transient[key]);

const renderTextFields = (sectionConfig, transient, user, otherProps) =>
  profileTextFieldsConfig.contact[sectionConfig].map((config, index) =>
    !config.countrySpecificField || config.countrySpecificField.includes(user.country) ? (
      <TextFieldComponent key={index} config={config} section={sectionConfig} transient={transient} {...otherProps} />
    ) : null
  );

const ContactTab = ({
  isOffline,
  onboarding_profile,
  transient,
  updateTransientProps,
  updateTransientPropWithValidations,
  updateUser,
  user
}) => {
  const { showContactRejectionModal = false } = transient;
  const { rejected_sections: rejectedSections, accepted_sections: acceptedSections } = onboarding_profile;
  const [rejectionSectionSelected, setRejectionSectionSelected] = useState(null);

  const rejectedFields = user?.rejected_fields || [];
  const rejectedContactArray = rejectedFields.filter(field => ONBOARDING_FIELDS.CONTACT.includes(field));
  const rejectedEmergencyArray = rejectedFields.filter(field => ONBOARDING_FIELDS.EMERGENCY.includes(field));

  const sections = {
    homeAddress: {
      readOnly: user.soft_deactivated || (acceptedSections?.by_mc_admin?.home_address && user.isApplicant) || isOffline,
      rejectedFields: rejectedSections?.home_address,
      rejectedReason: rejectedSections?.home_address?.reason
    },
    emergencyContact: {
      readOnly: user.soft_deactivated || (acceptedSections?.by_mc_admin?.emergency && user.isApplicant) || isOffline,
      rejectedFields: rejectedSections?.emergency,
      rejectedReason: rejectedSections?.emergency?.reason
    }
  };

  const handleRejectionModal = sectionSelected => {
    setRejectionSectionSelected(sectionSelected);
    updateTransientProps({ showContactRejectionModal: true });
  };

  return (
    <>
      <ColumnView>
        <ColumnView>
          <ColumnView>
            <RowView justifyContent={'flex-start'}>
              <BlockTitle className="mb-10 text-md text-dark-gray">
                {I18n.t('profile:contactTab.homeAddress')}
              </BlockTitle>
            </RowView>
          </ColumnView>
          {!!rejectedContactArray.length && (
            <RowView
              justifyContent={'flex-start'}
              paddingHorizontal={16}
              onClick={() => handleRejectionModal('homeAddress')}>
              <div className="rejection-wrapper">
                <Warning />
                <div className="rejection-text">{I18n.t('profile:rejectionTitle')}</div>
              </div>
            </RowView>
          )}
          {COUNTRIES_ADDRESS.FINDER.includes(user.country) && (
            <ColumnView>
              <List className="mb-0 mt-0 w-full">
                <ListItem
                  link
                  disabled={sections.homeAddress.readOnly || isOffline}
                  title={I18n.t('profile:contactTab.addressFinder')}
                  onClick={NavigationService.navigate.bind(null, {
                    name: 'PostCodesList'
                  })}
                />
              </List>
              <div className="message-contact-tab mx-16">{I18n.t('profile:contactTab.postCodeMessage')}</div>
            </ColumnView>
          )}
          <RowView justifyContent={'flex-start'}>
            <List noHairlines className="mb-0 mt-8 w-full">
              {'email' in transient &&
                renderTextFields('homeAddress', transient, user, {
                  updateTransientProps,
                  updateTransientPropWithValidations,
                  updateUser,
                  user,
                  isReadOnly: sections.homeAddress.readOnly,
                  isRejectedField: field => isRejectedField(rejectedFields, field)
                })}
            </List>
          </RowView>
          {!REGIONS.UK.includes(user.country) && (
            <>
              <RowView justifyContent={'flex-start'}>
                <BlockTitle className="mb-10 text-md text-dark-gray">
                  {I18n.t('profile:contactTab.emergencyContact')}
                </BlockTitle>
              </RowView>
              {!!rejectedEmergencyArray.length && (
                <RowView
                  justifyContent={'flex-start'}
                  paddingHorizontal={16}
                  onClick={() => handleRejectionModal('emergencyContact')}>
                  <div className="rejection-wrapper">
                    <Warning />
                    <div className="rejection-text">{I18n.t('profile:rejectionTitle')}</div>
                  </div>
                </RowView>
              )}
              <RowView justifyContent={'flex-start'}>
                <List noHairlines className="w-full mt-8">
                  {'email' in transient &&
                    renderTextFields('emergencyContact', transient, user, {
                      updateTransientProps,
                      updateTransientPropWithValidations,
                      updateUser,
                      user,
                      isReadOnly: sections.emergencyContact.readOnly,
                      isRejectedField: field => isRejectedField(rejectedFields, field)
                    })}
                </List>
              </RowView>
            </>
          )}
        </ColumnView>
      </ColumnView>

      <RejectionReason
        isOpen={showContactRejectionModal}
        onClose={updateTransientProps.bind(null, { showContactRejectionModal: false })}
        rejectedFields={sections[rejectionSectionSelected]?.rejectedFields}
        rejectedReason={sections[rejectionSectionSelected]?.rejectedReason}
        fieldConfig={[profileTextFieldsConfig.contact[rejectionSectionSelected]]}
      />
    </>
  );
};

ContactTab.propTypes = {
  isOffline: PropTypes.bool,
  onboarding_profile: PropTypes.object,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object
};

ContactTab.defaultProps = {
  isOffline: false,
  onboarding_profile: {},
  transient: {},
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction,
  updateUser: mockFunction,
  user: {}
};

export default React.memo(ContactTab, areEqual);
