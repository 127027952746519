import { routes } from 'Core';
import { F7 } from 'Services';
import { store as generateStore } from 'ReduxStore';
import { Creators as ApplicationCreators } from 'Reducers/application';
import { Creators as TransientCreators } from 'Reducers/transient';

import { blacklist } from 'Helpers';

const NavigationService = {
  init: () => {
    const {
      store: { dispatch, getState }
    } = generateStore();
    NavigationService.dispatch = dispatch;
    NavigationService.getStoreState = getState;
    NavigationService.restrictedRoutes = {};
    for (const route of routes) {
      if (route.accessPermission) {
        NavigationService.restrictedRoutes[route.name] = route.accessPermission;
      }
    }
  },

  listenEvent: ({ view = 'main', eventName = 'routeChanged' }) => {
    F7.views[view].router.off(eventName).on(eventName, (newRoute, previousRoute) => {
      NavigationService.dispatch(ApplicationCreators[eventName.toLowerCase()](newRoute, previousRoute));
    });
  },

  silenceEvent: ({ view = 'main', eventName = 'routeChanged' }) => {
    F7.views[view].router.off(eventName);
  },

  goBack: (eventAndParams = {}) => {
    const { view = 'main', name = '' } = eventAndParams;
    if (!blacklist.transientReset.includes(name)) {
      NavigationService.dispatch(TransientCreators.reset());
    }
    NavigationService.dispatch(ApplicationCreators.navigateBack());
    F7.views[view] && F7.views[view].router.back();
  },

  //TODO check this logic it doens't work like goBack for blacklisted items
  navigate: eventAndParams => {
    const { view = 'main', name = 'Login', ...paramsAndQuery } = eventAndParams;

    const {
      application: { routing, mode }
    } = NavigationService.getStoreState();
    if (
      !NavigationService.restrictedRoutes[name] ||
      (NavigationService.restrictedRoutes[name] && NavigationService.restrictedRoutes[name] === mode)
    ) {
      if (!blacklist.transientReset.includes(name)) {
        NavigationService.dispatch(TransientCreators.reset());
      }
      if (routing && routing.history && routing.history.slice(-1)[0] !== name) {
        NavigationService.dispatch(ApplicationCreators.navigateTo(eventAndParams));
        F7.views[view] &&
          F7.views[view].router.navigate({
            name,
            ...paramsAndQuery
          });
      }
    } else {
      NavigationService.dispatch(ApplicationCreators.brokenOrUnauthorizedAccess());
    }
  },

  clearURL: () => {
    window.location.replace('/');
  }
};

export default NavigationService;
