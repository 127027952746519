import React from 'react';
import PropTypes from 'prop-types';
import { Icon, ListInput as F7ListInput } from 'framework7-react';

import './style.scss';

const iconColorState = {
  blue: 'icon-valid',
  gray: 'icon-default',
  red: 'icon-error'
};

const TextField = ({ icon, iconColor, ...rest }) => (
  <>
    {icon ? (
      <F7ListInput {...rest} outline={true}>
        <Icon md={icon} slot="content-end" className={`icon-state ${iconColorState[iconColor]}`} />
      </F7ListInput>
    ) : (
      <F7ListInput {...rest} outline={true} />
    )}
  </>
);

TextField.propTypes = {
  icon: PropTypes.string || '',
  iconColor: PropTypes.string || 'gray'
};

export default TextField;
