import { connect } from 'react-redux';

import * as Selectors from 'Selectors';
import { selectedBadgeNumber } from 'Reducers/user';
import { currentFlowBadgeNumber } from 'Reducers/flow';
import { Creators as userActions } from 'Reducers/user';
import { Creators as flowActions } from 'Reducers/flow';
import { Creators as flowCreators } from 'Reducers/flow';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as applicationActions } from 'Reducers/application';

import Flow from './view';

export default connect(
  state => {
    const flow = state.flow.flows[state.flow.currentFlowIndex];
    const mode = state.application.mode;

    return {
      availablePages: state.flow.availablePages,
      components: flow?.flow_pages[state.flow.currentPageIndex]?.components,
      currentCheckInAdress: mode === 'agent' ? Selectors.checkInObject(state)?.address : state.postCodes.donorAddresses,
      currentFlowBadgeNumber: currentFlowBadgeNumber(state),
      currentFlowData: state.flow.currentFlowData,
      currentPage: state.flow.currentPage,
      currentPageIndex: state.flow.currentPageIndex,
      flow_signature: flow?.flow_signature,
      flow: state.flow,
      isOffline: state.application.isOffline,
      mode: state.application.mode,
      name: flow?.name,
      pageIsInvalidOnNext: state.flow.pageIsInvalidOnNext,
      pages: Selectors.flowPages(state),
      processing: state.flow.processing,
      referenced_products: flow?.referenced_products,
      selectedBadgeNumber: selectedBadgeNumber(state),
      selectedCampaignPublishedFlowId: Selectors.selectedCampaignPublishedFlowId(state),
      signature: state.flow.signature,
      transient: state.transient,
      user: state.user,
      userAvatar: state.user.avatar_url,
      userFullName: state.user.full_name,
      validatedPages: state.flow.validatedPages,
      validBadgeNumber: Selectors.validBadgeNumber(state)
    };
  },
  {
    getUKAddress: userActions.getUKAddress,
    initSubmitFlow: flowActions.initSubmitFlow,
    invalidateComponent: flowActions.invalidateComponent,
    logout: applicationActions.logout,
    nextFlowPage: flowCreators.nextFlowPage,
    previousFlowPage: flowCreators.previousFlowPage,
    updateAgreement: flowActions.updateAgreement,
    updateCurrentFlowData: flowActions.updateCurrentFlowData,
    updateFlowProps: flowActions.updateProps,
    updateSignature: flowActions.updateProp,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations,
    validateComponent: flowActions.validateComponent,
    validateOnlineBankAccount: flowActions.validateOnlineBankAccount
  }
)(Flow);
