import { connect } from 'react-redux';

import ThankYou from './view';

export default connect(
  state => ({
    mode: state.application.mode
  }),
  {}
)(ThankYou);
