import React from 'react';
import PropTypes from 'prop-types';

const PendingEye = ({ fillColor = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      //eslint-disable-next-line no-useless-escape, max-len
      d="M12.983 10A2.98 2.98 0 0 1 10 12.983 2.98 2.98 0 0 1 7.017 10 2.98 2.98 0 0 1 10 7.017 2.98 2.98 0 0 1 12.983 10Z"
    />
    <path
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      //eslint-disable-next-line no-useless-escape, max-len
      d="M10 16.891c2.942 0 5.683-1.733 7.592-4.733.75-1.175.75-3.15 0-4.325-1.909-3-4.65-4.733-7.592-4.733-2.942 0-5.683 1.733-7.592 4.733-.75 1.175-.75 3.15 0 4.325 1.909 3 4.65 4.733 7.592 4.733Z"
    />
  </svg>
);

PendingEye.propTypes = {
  fillColor: PropTypes.string
};

export default PendingEye;
