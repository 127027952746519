import React from 'react';

const Offline = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
    <path
      fill="#444"
      stroke="#444"
      //eslint-disable-next-line no-useless-escape, max-len
      d="m12.419 12.242.971-.972c.474.273.911.613 1.296 1.014a.656.656 0 0 1-.947.91 4.495 4.495 0 0 0-1.32-.952Zm2.838-2.84.94-.938c.404.305.785.643 1.141 1.014a.654.654 0 0 1-.02.928.656.656 0 0 1-.928-.02 8.214 8.214 0 0 0-1.133-.983Zm-5.99-1.435a8.135 8.135 0 0 0-4.62 2.382.656.656 0 0 1-.942-.915 9.449 9.449 0 0 1 6.968-2.87L9.268 7.967Zm8.758-1.331.931-.932c.36.302.704.625 1.032.966a.656.656 0 0 1-.946.908c-.32-.333-.66-.648-1.017-.942Zm-4.997-2.428c-.83-.18-1.678-.271-2.528-.27a11.8 11.8 0 0 0-8.49 3.588.656.656 0 1 1-.942-.915A13.112 13.112 0 0 1 10.5 2.625c1.239 0 2.452.172 3.61.501l-1.082 1.082ZM3.38 18.375a.656.656 0 0 1-.928-.928L18.567 1.504a.657.657 0 0 1 .929.93L3.38 18.374Z"
    />
    <circle cx="10.5" cy="15.5" r=".5" fill="#444" stroke="#444" />
  </svg>
);

export default Offline;
