const validateProp = (state, { prop }) => {
  const newState = { ...state };

  switch (prop) {
    case 'enableDonorLogin':
    case 'enableAgentLogin':
      newState[prop] =
        (prop === 'enableAgentLogin' && state.email && state.email?.length > 0 && state.password?.length > 0) ||
        (prop === 'enableDonorLogin' && state.badge_number?.length > 0);
      break;
  }

  return newState;
};

export default validateProp;
