import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';

import ForgotPassword from './view';

export default connect(
  state => ({
    transient: state.transient
  }),
  {
    requestChangeUserPassword: userActions.requestChangeUserPassword,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations
  }
)(ForgotPassword);
