import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Icon, List, Page, Popup } from 'framework7-react';

import { I18n } from 'Locales';
import { Warning } from 'Icons';
import { mockFunction } from 'Helpers';
import { ONBOARDING_FIELDS } from 'Constants';
import { ColumnView, RowView } from 'Containers';
import { Avatar, BadgeIcon, RejectionReason } from 'Components';
import { BadgeIconPlaceholder, PlaceholderCampaign } from 'Icons';
import { isRejectedField } from 'Pages/session/Profile/utils/helpers';
import TextFieldComponent from 'Pages/session/Profile/ProfileTextField';
import { profileTextFieldsConfig } from 'Pages/session/Profile/utils/config';

import './style.scss';

const areEqual = (prevProps, nextProps) =>
  prevProps.transient === nextProps.transient &&
  prevProps.isOffline === nextProps.isOffline &&
  prevProps.user.avatar_url === nextProps.user.avatar_url &&
  Object.keys(prevProps.transient).every(key => prevProps.transient[key] === nextProps.transient[key]);

const renderTextFields = (sectionConfig, transient, user, otherProps) =>
  profileTextFieldsConfig?.generalDetails?.map((config, index) =>
    !config.countrySpecificField || config.countrySpecificField.includes(user.country) ? (
      <TextFieldComponent
        key={index}
        config={config}
        section={sectionConfig}
        transient={transient}
        user={user}
        {...otherProps}
      />
    ) : null
  );

const renderBadgeModalInfo = (item, index) => (
  <RowView key={index} justifyContent="flex-start" width={'100%'}>
    <div className="text-xs text-gray line-height-normal">{`${item.label}:`}</div>
    <div className="text-sm line-height-normal pl-4">{item.value}</div>
  </RowView>
);

const ProfileTab = ({
  campaign,
  isOffline,
  onboarding_profile,
  selectedBadgeNumber,
  transient,
  updateTransientProps,
  updateTransientPropWithValidations,
  updateUser,
  updateUserAvatar,
  user
}) => {
  const rejectedFields = user?.rejected_fields || [];
  const userCampaigns = transient?.user_campaigns?.campaigns;
  const userSelectedCampaign = transient?.user_campaigns?.selectedCampaign?.name;
  const rejectedProfileArray = rejectedFields.filter(field => ONBOARDING_FIELDS.PROFILE.includes(field));

  const { rejected_sections: rejectedSections, accepted_sections: acceptedSections } = onboarding_profile;
  const { showBadgeModal = false, showProfileRejectionModal = false, updateUserAvatarLoading = false } = transient;

  const sections = {
    generalDetails: {
      readOnly:
        user.soft_deactivated || (acceptedSections?.by_mc_admin?.general_details && user.isApplicant) || isOffline,
      rejectedReason: rejectedSections?.general_details?.reason
    }
  };

  const badgeModalInfo = [
    { label: I18n.t('profile:profileTab.badgeNumber'), value: selectedBadgeNumber },
    { label: I18n.t('profile:profileTab.campaign'), value: userSelectedCampaign },
    { label: I18n.t('profile:profileTab.marketingCompany'), value: user.mc_names }
  ];

  useEffect(() => {
    updateTransientProps({ user_campaigns: campaign[user.id] });
  }, []);

  return (
    <>
      <ColumnView width={'100%'} id={'profile-tab'}>
        <RowView paddingTop={30} paddingBottom={16}>
          <Avatar
            badge={user?.badges?.length ? true : false}
            editable
            size={90}
            source={user?.avatar_url}
            transient={transient}
            updateTransientProps={updateTransientProps}
            updateUserAvatar={updateUserAvatar}
            updateUserAvatarLoading={updateUserAvatarLoading}
            user={user}
            isOffline={isOffline}
            userAvatar
          />
        </RowView>

        {!!rejectedProfileArray.length && (
          <RowView
            justifyContent={'flex-start'}
            paddingHorizontal={16}
            marginTop={32}
            onClick={updateTransientProps.bind(null, { showProfileRejectionModal: true })}>
            <div className="rejection-wrapper">
              <Warning />
              <div className="rejection-text">{I18n.t('profile:rejectionTitle')}</div>
            </div>
          </RowView>
        )}
        <RowView justifyContent={'flex-start'} marginTop={rejectedProfileArray.length ? 0 : 32}>
          <List className="w-full">
            {'email' in transient &&
              renderTextFields('generalDetails', transient, user, {
                updateTransientProps,
                updateTransientPropWithValidations,
                updateUser,
                isReadOnly: sections.generalDetails.readOnly,
                isRejectedField: field => isRejectedField(rejectedFields, field)
              })}
          </List>
        </RowView>
      </ColumnView>
      <Popup
        opened={showBadgeModal}
        onPopupClosed={updateTransientProps.bind(null, { showBadgeModal: false })}
        id={'profile-tab'}>
        <Page>
          <ColumnView
            justifyContent="space-between"
            height={'100%'}
            paddingHorizontal={24}
            paddingTop={20}
            paddingBottom={10}
            gap={24}>
            <ColumnView justifyContent="flex-start">
              <RowView
                justifyContent="flex-end"
                height={24}
                onClick={updateTransientProps.bind(null, { showBadgeModal: false })}>
                <Icon className="justify-end" md={'material:close'} />
              </RowView>
              <ColumnView>
                <Avatar
                  avatarInitials
                  size={90}
                  source={user?.avatar_url}
                  transient={transient}
                  updateTransientProps={updateTransientProps}
                  updateUserAvatar={updateUserAvatar}
                  user={user}
                  userAvatar
                />
                <div className="text-md font-medium line-height-24 pt-8">{`${user.first_name} ${user.last_name}`}</div>
                <div className="text-xs text-gray line-height-24">{I18n.t('profile:profileTab.brandAmbassador')}</div>
              </ColumnView>
            </ColumnView>
            <ColumnView gap={16}>{badgeModalInfo.map(renderBadgeModalInfo)}</ColumnView>
            <div
              className={`badge-scroll no-scrollbars ${
                userCampaigns?.length > 2 ? 'w-full justify-start' : 'w-auto justify-center'
              }`}>
              {userCampaigns?.map(campaign => (
                <ColumnView
                  marginBottom={'auto'}
                  key={campaign.id}
                  alignSelf="start"
                  onClick={updateTransientProps.bind(null, {
                    user_campaigns: {
                      ...transient.user_campaigns,
                      selectedCampaign: campaign
                    }
                  })}>
                  <BadgeIcon
                    badgeColor={campaign.id === transient.user_campaigns?.selectedCampaign?.id ? 'blue' : 'gray'}>
                    <img
                      src={campaign?.icon_logo_url !== null ? campaign?.icon_logo_url : BadgeIconPlaceholder}
                      className={campaign?.icon_logo_url !== null && 'badge-icon-size '}
                    />
                  </BadgeIcon>
                  <RowView marginBottom={20}>
                    <div className="text-center text-xxs">{campaign?.name}</div>
                  </RowView>
                </ColumnView>
              ))}
              <img src={PlaceholderCampaign} />
            </div>
          </ColumnView>
        </Page>
      </Popup>

      <RejectionReason
        isOpen={showProfileRejectionModal}
        onClose={updateTransientProps.bind(null, { showProfileRejectionModal: false })}
        rejectedFields={{ fields: rejectedProfileArray }}
        rejectedReason={sections.generalDetails?.rejectedReason}
        fieldConfig={[profileTextFieldsConfig.generalDetails]}
      />
    </>
  );
};

ProfileTab.propTypes = {
  campaign: PropTypes.object,
  handleOnChangeWithValidations: PropTypes.func,
  isOffline: PropTypes.bool,
  onboarding_profile: PropTypes.object,
  selectedBadgeNumber: PropTypes.string,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func,
  updateUserAvatar: PropTypes.func,
  updateUserAvatarLoading: PropTypes.bool,
  user: PropTypes.object,
  userBadge: PropTypes.array,
  userFullName: PropTypes.string
};

ProfileTab.defaultProps = {
  campaign: {},
  handleOnChangeWithValidations: mockFunction,
  isOffline: false,
  onboarding_profile: {},
  selectedBadgeNumber: '',
  transient: {},
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction,
  updateUser: mockFunction,
  updateUserAvatar: mockFunction,
  updateUserAvatarLoading: false,
  user: {},
  userBadge: [],
  userFullName: ''
};

export default React.memo(ProfileTab, areEqual);
