import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

export const storeConfig = {
  key: 'databyte-pwa-persist',
  storage,
  timeout: null,
  //blacklist: ['growl', 'actionsheetandroid'],
  transforms: [
    createBlacklistFilter('application', ['initialised', 'isOffline', 'routing', 'warnedSecurityLogout'])
    //   createBlacklistFilter('search', ['filtersVisible']),
    //   createBlacklistFilter('search', ['showFiltersIcon'])
  ]
};

export default { storeConfig };
