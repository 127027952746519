import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './style.scss';
import BankAccountFR from './FR';
import BankAccountUK from './UK';

class BankAccount extends Component {
  basedByCountry = (country, props) => {
    switch (country) {
      case 'UK':
        return <BankAccountUK {...props} />;
      default:
        return <BankAccountFR {...props} />;
    }
  };

  render = () => {
    const { country } = this.props;
    return this.basedByCountry(country, this.props);
  };
}

BankAccount.propTypes = {
  country: PropTypes.string
};

export default BankAccount;
