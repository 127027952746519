import { connect } from 'react-redux';

import Settings from './view';

import { Creators as userActions } from 'Reducers/user';
import { Creators as resultActions } from 'Reducers/result';

export default connect(
  state => ({
    isOffline: state.application.isOffline,
    user: state.user
  }),
  {
    getCountries: userActions.getCountries,
    performTestResults: resultActions.performTestResults,
    updateUser: userActions.updateApiModel
  }
)(Settings);
