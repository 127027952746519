import React, { Component, Fragment, useState, useEffect } from 'react';
import { Link, PageContent, Toolbar, Sheet } from 'framework7-react';
import CanvasDraw from 'react-canvas-draw';
import PropType from 'prop-types';

import { I18n } from 'Locales';
import { Button } from 'Components';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';

import style from './style';

class SignatureCanvas extends Component {
  render = () => {
    const { width } = this.props;

    return (
      <PageContent>
        <CanvasDraw
          ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
          brushRadius={2}
          lazyRadius={0}
          canvasWidth={width}
          canvasHeight={width / 2}
          hideGrid={true}
        />
      </PageContent>
    );
  };
}

SignatureCanvas.propTypes = {
  width: PropType.number
};

const Signature = props => {
  const { currentPageIndex, parentRef, setSheetVisible, sheetVisible, shouldShowError, updateSignature, value } = props;

  const [canvasRef, attachRef] = useState({});
  const [width, setWidth] = useState(400);

  useEffect(() => {
    setWidth(parentRef.current.offsetWidth ? parentRef.current.offsetWidth : 400);
  }, [currentPageIndex]);

  return (
    <div className="h-full w-full">
      <ColumnView flex={1} alignSelf={'flex-start'} onClick={setSheetVisible.bind(null, true)}>
        <img
          className="flex w-full"
          slot="media"
          src={
            value ||
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAQAAABeK7' +
              'cBAAAADUlEQVR42mP8X8/AAAAFgwGANbMDjQAAAABJRU5ErkJggg=='
          }
          style={{ ...style.blankImage, ...(shouldShowError && style.redBorder) }}
        />
      </ColumnView>

      <Sheet opened={sheetVisible} onSheetClosed={setSheetVisible.bind(null, false)} style={style.singatureSheetStyle}>
        {sheetVisible && (
          <Fragment>
            <Toolbar>
              <div className="left">
                <Button.Transparent onClick={canvasRef?.saveableCanvas?.clear || mockFunction}>
                  {I18n.t('general:clear')}
                </Button.Transparent>
              </div>
              <div className="right">
                <Link sheetClose onClick={doneEvent.bind(null, canvasRef?.saveableCanvas, updateSignature)}>
                  {I18n.t('general:done')}
                </Link>
              </div>
            </Toolbar>

            <SignatureCanvas width={width} ref={attachRef} />
          </Fragment>
        )}
      </Sheet>
    </div>
  );
};

const doneEvent = (saveableCanvas, updateSignature) => {
  if (saveableCanvas) {
    const data = saveableCanvas.getSaveData();

    if (JSON.parse(data).lines.length > 0) {
      updateSignature(saveableCanvas.canvasContainer.children[1].toDataURL());
    }
  }
};

Signature.propTypes = {
  currentPageIndex: PropType.number,
  mode: PropType.string,
  parentRef: PropType.any,
  setSheetVisible: PropType.func,
  sheetVisible: PropType.bool,
  shouldShowError: PropType.bool,
  updateAgreement: PropType.func,
  updateSignature: PropType.func,
  userFullName: PropType.string,
  value: PropType.oneOfType([PropType.string, PropType.object])
};

export default Signature;
