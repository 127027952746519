import { createActions, createReducer } from 'reduxsauce';

import { setInitial, updateProp } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    get: null,
    getError: null,
    set: ['payload', 'userId'],
    setCampaignsAndDefault: ['payload'],
    setSelectedCampaign: ['payload'],
    setSortedCampaigns: ['userId', 'from', 'to'],
    setToggledCampaigns: ['payload'],
    toggleCampaign: ['payload'],
    updateProp: ['key', 'value']
  },
  { prefix: 'campaign/' }
);

const initialState = {};

const initialUserState = {
  campaigns: [],
  toggleStatus: {}
};

export const campaigns = state => state.campaign;

export const set = (state, { userId, payload }) => {
  const newState = { ...state };
  if (!state[userId]) {
    newState[userId] = initialUserState;
  }

  payload.forEach(newItem => {
    const campaign = newState[userId]?.campaigns.find(item => item.id === newItem.id);
    if (campaign && campaign.icon_logo_url !== newItem.icon_logo_url) {
      campaign.icon_logo_url = newItem.icon_logo_url;
    }
  });

  payload?.forEach(({ id }) => {
    if (!newState[userId]?.toggleStatus[id]) {
      newState[userId].toggleStatus[id] = { toggled: true };
    }
  });

  const sortedCampaignsIds = newState[userId].campaigns?.reduce((store, item) => {
    store.push(item.id);
    return store;
  }, []);

  const unSortedCampaignsIds = payload.reduce((store, item) => {
    store.push(item.id);
    return store;
  }, []);

  const addedCampaignIds = unSortedCampaignsIds?.filter(item => !sortedCampaignsIds?.includes(item));
  const removedCampaignIds = sortedCampaignsIds?.filter(item => !unSortedCampaignsIds?.includes(item));

  if (addedCampaignIds?.length) {
    addedCampaignIds.forEach(id => sortedCampaignsIds?.push(id));
    payload.forEach(item => {
      if (addedCampaignIds.includes(item.id)) newState[userId]?.campaigns?.push(item);
    });
  }

  if (removedCampaignIds?.length) {
    removedCampaignIds.forEach(id => sortedCampaignsIds.splice(sortedCampaignsIds.indexOf(id), 1));
    newState[userId].campaigns = newState[userId]?.campaigns.filter(item => sortedCampaignsIds.includes(item.id));
  }

  return newState;
};

export const setSelectedCampaign = (state, { userId, payload }) => {
  const newState = setInitial(state, userId, initialState);
  newState[userId]['selectedCampaign'] = payload;
  return newState;
};

export const toggleCampaign = (state, { userId, payload }) => {
  const newState = { ...state };
  const { id, toggled } = payload;

  if (newState[userId]?.toggleStatus) {
    newState[userId].toggleStatus[id] = { toggled };
  }

  return newState;
};

export const setSortedCampaigns = (state, { userId, from, to }) => {
  const newState = { ...state };
  if (from !== to) {
    const updatedSortedCampaigns = [...state[userId]?.campaigns];
    const [element] = updatedSortedCampaigns.splice(from, 1);
    updatedSortedCampaigns.splice(to, 0, element);

    state[userId].campaigns = updatedSortedCampaigns;
  }
  return newState;
};

export default createReducer(initialState, {
  [Types.UPDATE_PROP]: updateProp,
  [Types.SET]: set,
  [Types.SET_SELECTED_CAMPAIGN]: setSelectedCampaign,
  [Types.SET_SORTED_CAMPAIGNS]: setSortedCampaigns,
  [Types.TOGGLE_CAMPAIGN]: toggleCampaign
});
