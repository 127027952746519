import React from 'react';
import { I18n } from 'Locales';
import PropTypes from 'prop-types';
import { Preloader } from 'framework7-react';

import { colors, f7Colors } from 'Theme';
import { ColumnView } from 'Containers';

const SplashScreen = ({ orientation }) => (
  <ColumnView height={'100%'} backgroundColor={colors.standard}>
    <img src={'/static/images/data-byte-logo.png'} />
    {orientation === 'portrait' && <Preloader size={20} color={f7Colors.multi} />}
    {orientation === 'landscape' && `${I18n.t('general:portraitOnly')}`}
  </ColumnView>
);

SplashScreen.propTypes = {
  orientation: PropTypes.string
};

export default SplashScreen;
