import { I18n } from 'Locales';

const ACCEPTED_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'heif', 'heic'];

const APP_MODES = {
  AGENT: 'agent',
  DONOR: 'donor'
};

const NEEDS_LEURKAART = ['Brussels-Capital', 'Wallonia'];

const COUNTRIES = { BE: 'BE', DE: 'DE', FR: 'FR', IE: 'IE', UK: 'UK' };

const COUNTRIES_ADDRESS = {
  ALL: ['BE', 'DE', 'FR', 'IE', 'UK'],
  AUTOCOMPLETE: ['BE', 'FR'],
  FINDER: ['BE', 'FR', 'UK']
};

const DOCUMENT_STATES = {
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
  PENDING_READ_ONLY: 'pendingReadOnly',
  PENDING: 'pending',
  READ: 'read',
  REJECTED: 'rejected',
  SIGNED: 'signed'
};

const DOCUMENT_TYPES = {
  MANDATORY: 'Mandatory',
  MC_ADMIN_TIME_LEASHED: 'Mc-admin-time-leashed',
  OPTIONAL: 'Optional',
  READ_ONLY: 'Read-only',
  TIME_LEASHED: 'Time-leashed'
};

const PAGE_NAMES = {
  CAMPAIGNS: 'Campaigns',
  CHECKIN: 'CheckIn',
  FEEDBACK: 'Feedback',
  PROFILE: 'Profile',
  REPORTS: 'Reports',
  SETTINGS: 'Settings',
  SIGNUP: 'SignUp',
  TOOLS: 'Tools'
};

const POSITION = {
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  top: 'top'
};

const PROFILE_STATUS_COLORS = {
  GRAY: 'gray',
  GREEN: 'green',
  ORANGE: 'orange',
  RED: 'red'
};

const EMPTY_TABS = {
  account_holder_name: 'bank_details',
  account_number: 'bank_details',
  additional_bank_info: 'bank_details',
  address_line1: 'contact',
  address_line2: 'contact',
  address_line3: 'contact',
  birth_department: 'identity',
  birth_location: 'identity',
  city: 'contact',
  country_of_birth: 'identity',
  country_of_citizenship: 'identity',
  country: 'contact',
  cpr_number: 'identity',
  date_of_birth: 'identity',
  email: 'profile',
  emergency_name: 'contact',
  emergency_phone_number: 'contact',
  first_name: 'profile',
  gender: 'identity',
  id_expiry_date: 'identity',
  last_name: 'profile',
  national_insurance_number: 'identity',
  nationality: 'identity',
  phone_number: 'profile',
  postcode: 'contact',
  regions: 'contact',
  siret_number: 'identity',
  ssn: 'identity',
  street_name: 'contact',
  street_number: 'contact'
};

const FILE_SIZE_LIMITS = {
  ATTACHMENTS: 10 * 1024 * 1024 // 10MB
};

const ICON_COLOR = {
  BLUE: '#0d8de7',
  GRAY: '#747474',
  LIGHT_GRAY: '#c1c1c1',
  RED: '#E15A55',
  WHITE: '#ffffff'
};

const REGIONS = {
  BE: ['BE', 'DE'],
  UK: ['IE', 'UK']
};

const RESULT_STATUS_TYPES = {
  INACTIVE: 'inactive',
  INCOMPLETE: 'incomplete',
  OFFLINE: 'offline'
};

const SIGNATURE_COLORS = { ERROR: '#E15A55' };

const SIGNING_TYPES = {
  READ_ONLY: 'Read Only',
  SIGN: 'Sign Document',
  UPLOAD: 'Upload Document'
};

const TABS = {
  BANK_DETAILS: 'bank_details',
  CONTACT: 'contact',
  DOCUMENTS: 'documents',
  IDENTITY: 'identity',
  PROFILE: 'profile'
};

const TEXT_FIELD_ICONS = {
  CHECKMARK: 'material:check'
};

const ONBOARDING_FIELDS = {
  BANK_DETAILS: ['account_holder_name', 'account_number', 'bank_name', 'bank_sort_code'],
  CONTACT: ['address_line1', 'city', 'country', 'street_name', 'street_number', 'postcode'],
  EMERGENCY: ['emergency_name', 'emergency_phone_number'],
  IDENTITY: [
    'birth_department',
    'birth_location',
    'country_of_birth',
    'country_of_citizenship',
    'cpr_number',
    'date_of_birth',
    'gender',
    'national_insurance_number',
    'nationality',
    'siret_number',
    'id_expiry_date',
    'ssn'
  ],
  PROFILE: ['email', 'first_name', 'last_name', 'phone_number']
};

const PROFILE_FIELDS_DETAILS = {
  BANK_DETAILS: 'bank_details',
  CONTACT: ['home_address', 'emergency'],
  IDENTITY: 'general_details',
  PROFILE: 'general_details'
};

const ALERT_TYPES = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success'
};

const ALERT_COLORS = {
  ERROR: '#F6CCCA',
  INFO: '#FDEAB0',
  SUCCESS: '#C0E7D9'
};

const ADDRESS_FLOW_COMPONENT = {
  KEYS: [
    'City',
    'ExtraInfo',
    'ExtraInfo2',
    'Letter',
    'Number',
    'PostCode',
    'PostalDistrict',
    'StreetName',
    'search_address'
  ]
};

const USER_LANGUAGES = {
  LANGUAGES: [
    { label: I18n.t('settings:english'), value: 'en' },
    { label: I18n.t('settings:french'), value: 'fr' },
    { label: I18n.t('settings:dutch'), value: 'nl' }
  ]
};

export {
  ACCEPTED_IMAGE_TYPES,
  ADDRESS_FLOW_COMPONENT,
  ALERT_COLORS,
  ALERT_TYPES,
  APP_MODES,
  COUNTRIES_ADDRESS,
  COUNTRIES,
  DOCUMENT_STATES,
  DOCUMENT_TYPES,
  EMPTY_TABS,
  FILE_SIZE_LIMITS,
  ICON_COLOR,
  NEEDS_LEURKAART,
  ONBOARDING_FIELDS,
  PAGE_NAMES,
  POSITION,
  PROFILE_FIELDS_DETAILS,
  PROFILE_STATUS_COLORS,
  REGIONS,
  RESULT_STATUS_TYPES,
  SIGNATURE_COLORS,
  SIGNING_TYPES,
  TABS,
  TEXT_FIELD_ICONS,
  USER_LANGUAGES
};
