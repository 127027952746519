import React from 'react';
import PropTypes from 'prop-types';

const Logout = ({ fillColor = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
    <path
      // eslint-disable-next-line max-len
      d="M14 15.375L18.375 11M18.375 11L14 6.625M18.375 11H7.875M7.875 3.125H6.825C5.35486 3.125 4.61979 3.125 4.05828 3.41111C3.56435 3.66278 3.16278 4.06435 2.91111 4.55827C2.625 5.11979 2.625 5.85486 2.625 7.325V14.675C2.625 16.1451 2.625 16.8802 2.91111 17.4417C3.16278 17.9357 3.56435 18.3372 4.05828 18.5889C4.61979 18.875 5.35486 18.875 6.825 18.875H7.875"
      stroke={fillColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Logout.propTypes = {
  fillColor: PropTypes.string
};

export default Logout;
