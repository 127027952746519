import React from 'react';
import PropTypes from 'prop-types';

import { RowView } from 'Containers';
import { colors } from 'Theme';

const ToolBar = ({ children, ...props }) => (
  <RowView minHeight={48} justifyContent={'space-between'} backgroundColor={colors.white} {...props}>
    {children}
  </RowView>
);

ToolBar.propTypes = {
  children: PropTypes.any,
  paddingHorizontal: PropTypes.number
};

ToolBar.defaultProps = {
  children: null,
  paddingHorizontal: 15
};

export default ToolBar;
