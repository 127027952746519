import React from 'react';
import PropTypes from 'prop-types';

import style from './style';

const SafeAreaView = props => (
  <div
    style={{
      ...style.safeArea,
      backgroundColor: props.backgroundColor,
      ...(props.top && style.safeTop),
      ...(props.bottom && style.safeBottom)
    }}>
    {props.children}
  </div>
);

SafeAreaView.defaultProps = {
  backgroundColor: 'transparent',
  bottom: true,
  children: null,
  top: true
};

SafeAreaView.propTypes = {
  backgroundColor: PropTypes.string,
  bottom: PropTypes.bool,
  children: PropTypes.node,
  top: PropTypes.bool
};

export default SafeAreaView;
