import PropTypes from 'prop-types';

export const propTypes = {
  component_key: PropTypes.any,
  country: PropTypes.string,
  currentCheckInAdress: PropTypes.array,
  name: PropTypes.string,
  updateValue: PropTypes.func,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
  setValues: PropTypes.func,
  shouldShowError: PropTypes.bool
};
