import React from 'react';
import PropTypes from 'prop-types';

const ArrowBack = ({ fillColor = '#D9D9D9' }) => (
  <svg width="24" height="24" fill="none">
    <mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" masktype="alpha">
      <path fill={fillColor} d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#444"
        // eslint-disable-next-line max-len
        d="M6.996 11.237h11.377c.213 0 .391.072.535.216a.726.726 0 0 1 .215.534.726.726 0 0 1-.215.535.726.726 0 0 1-.535.215H6.996l5.17 5.17a.705.705 0 0 1 .22.522.743.743 0 0 1-.236.532.784.784 0 0 1-.527.225.7.7 0 0 1-.527-.226l-6.34-6.34a.829.829 0 0 1-.256-.633.829.829 0 0 1 .256-.633l6.34-6.34a.715.715 0 0 1 .515-.212.748.748 0 0 1 .539.212.735.735 0 0 1 .233.535c0 .201-.078.38-.233.535l-5.154 5.153Z"
      />
    </g>
  </svg>
);

export default ArrowBack;

ArrowBack.propTypes = {
  fillColor: PropTypes.string
};
