export default {
  safeArea: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  safeTop: {
    paddingTop: 'env(safe-area-inset-top)'
  },
  safeBottom: {
    paddingBottom: 'env(safe-area-inset-bottom)'
  }
};
