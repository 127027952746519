// ATTENTION
// This component handles DE and BE as they are similar countries
import React from 'react';

import { I18n } from 'Locales';
import { TextField } from 'Components';
import { ColumnView } from 'Containers';
import { FormattedLabel } from 'Helpers';

import '../style.scss';
import { propTypes } from './shared';
import { getFieldProp, getIconColor } from '../../../shared';

const DE = props => {
  const { fields, required, setValues, shouldShowError, value } = props;

  let addressValues = value;
  if (!addressValues) {
    addressValues = {
      StreetName: '',
      PostCode: '',
      City: '',
      Number: '',
      ExtraInfo: ''
    };
  }

  const numberProp = getFieldProp(fields, 'Number');
  const streetNameProp = getFieldProp(fields, 'StreetName');
  const extraInfoProp = getFieldProp(fields, 'ExtraInfo');
  const postCodeProp = getFieldProp(fields, 'PostCode');
  const cityProp = getFieldProp(fields, 'City');

  const { Number, StreetName, PostCode, City, ExtraInfo } = addressValues;
  return (
    <ColumnView>
      <div className="w-full">
        <TextField
          label={I18n.t('flow:components.address.numberLabel')}
          type={'number'}
          placeholder={numberProp?.placeholder}
          value={Number}
          onChange={e => setValues(value, { Number: e.target.value })}
          icon="material:check"
          iconColor={getIconColor(false, Number)}
        />

        <TextField
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.streetNameLabel') })}
          type={'text'}
          placeholder={streetNameProp?.placeholder}
          value={StreetName}
          onChange={e => setValues(value, { StreetName: e.target.value })}
          errorMessageForce={shouldShowError && StreetName === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && StreetName === '', StreetName)}
        />

        <TextField
          label={I18n.t('flow:components.address.extraInfoLabel')}
          type={'text'}
          placeholder={extraInfoProp?.placeholder}
          value={ExtraInfo}
          onChange={e => setValues(value, { ExtraInfo: e.target.value })}
          icon="material:check"
          iconColor={getIconColor(false, ExtraInfo)}
        />

        <TextField
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.postalCodeLabel') })}
          type={'text'}
          placeholder={postCodeProp?.placeholder}
          value={PostCode}
          onChange={e => setValues(value, { PostCode: e.target.value })}
          errorMessageForce={shouldShowError && PostCode === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && PostCode === '', PostCode)}
        />

        <TextField
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.cityLabel') })}
          type={'text'}
          placeholder={cityProp?.placeholder}
          value={City}
          onChange={e => setValues(value, { City: e.target.value })}
          errorMessageForce={shouldShowError && City === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && City === '', City)}
        />
      </div>
    </ColumnView>
  );
};

DE.propTypes = propTypes;

export default DE;
