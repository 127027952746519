import React from 'react';
import PropTypes from 'prop-types';

const CameraCheck = ({ fillColor = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      //eslint-disable-next-line no-useless-escape, max-len
      d="M18.333 9.583v2.584c0 1.867 0 2.8-.363 3.513a3.334 3.334 0 0 1-1.457 1.457c-.713.363-1.646.363-3.513.363H7c-1.867 0-2.8 0-3.513-.363A3.333 3.333 0 0 1 2.03 15.68c-.363-.713-.363-1.646-.363-3.513V7.833c0-1.867 0-2.8.363-3.513.32-.627.83-1.137 1.457-1.457C4.2 2.5 5.133 2.5 7 2.5h3.417m2.916 7.5a3.333 3.333 0 1 1-6.666 0 3.333 3.333 0 0 1 6.666 0ZM14 4.5 15.667 6 19 3"
    />
  </svg>
);

CameraCheck.propTypes = {
  fillColor: PropTypes.string
};

export default CameraCheck;
