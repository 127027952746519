import React from 'react';
import PropTypes from 'prop-types';
import { Button as F7Button, Preloader } from 'framework7-react';

import { f7Colors } from 'Theme';
import { mockFunction } from 'Helpers';

const createButtonClass = (baseClass, className) => `${baseClass} ${className || ''}`;

const Button = ({ children, disabled, iconLeft, iconRight, onClick, processing, width, ...props }) => (
  <F7Button {...props} disabled={disabled} {...(!processing && { onClick: onClick })} style={{ width }}>
    {iconLeft}
    {processing && <Preloader size={20} color={f7Colors.multi} />}
    {/* TODO: Pass and handle color prop for loading based on button state */}
    {!processing && children}
    {iconRight && <img className="pl-4" src={iconRight} />}
  </F7Button>
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  onClick: PropTypes.func,
  processing: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Button.defaultProps = {
  children: '',
  className: '',
  disabled: false,
  iconLeft: undefined,
  iconRight: undefined,
  onClick: mockFunction,
  processing: false,
  width: 'auto'
};

const buttonVariantPropTypes = {
  className: PropTypes.string
};

const Outline = props => (
  <Button {...props} className={createButtonClass('btn-secondary', props.className)} round outline largeMd />
);
Outline.propTypes = buttonVariantPropTypes;

const Primary = props => (
  <Button {...props} className={createButtonClass('btn-primary', props.className)} fill round largeMd />
);
Primary.propTypes = buttonVariantPropTypes;

const PrimarySmall = props => (
  <Button {...props} className={createButtonClass('btn-primary small', props.className)} fill round />
);
PrimarySmall.propTypes = buttonVariantPropTypes;

const OutlineSmall = props => (
  <Button {...props} className={createButtonClass('btn-secondary small', props.className)} round outline />
);
OutlineSmall.propTypes = buttonVariantPropTypes;

const Tertiary = props => (
  <Button {...props} className={createButtonClass('btn-tertiary', props.className)} round outline />
);
Tertiary.propTypes = buttonVariantPropTypes;

const Transparent = props => <Button {...props} className={createButtonClass('', props.className)} />;
Transparent.propTypes = buttonVariantPropTypes;

Button.Outline = Outline;
Button.Primary = Primary;
Button.Tertiary = Tertiary;
Button.PrimarySmall = PrimarySmall;
Button.OutlineSmall = OutlineSmall;
Button.Transparent = Transparent;

export default Button;
