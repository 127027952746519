import React from 'react';
import PropTypes from 'prop-types';
import { BlockTitle, List, ListItem, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { PAGE_NAMES } from 'Constants';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { mockFunction, onFieldChange } from 'Helpers';
import { AppHeader, Button, TextField } from 'Components';

import './style.scss';

const handleSendFeedback = ({ decodedFeedbackMessage, navigateToSettings, sendFeedback, transient }) => {
  sendFeedback(
    {
      feedback_type: transient.feedback_type,
      feedback_message: decodedFeedbackMessage
    },
    navigateToSettings
  );
};

const navigateToSettings = () => {
  NavigationService.navigate({
    name: PAGE_NAMES.TOOLS
  });
};

const Feedback = ({ sendFeedback, transient, updateTransientProps }) => {
  const decodedFeedbackMessage = new DOMParser().parseFromString(transient.feedback_message, 'text/html').body
    .textContent;

  const disableButton =
    !transient.feedback_type || !transient.feedback_message || decodedFeedbackMessage.trim().length < 2;

  return (
    <Page id="feedback-page">
      <AppHeader
        user
        goBack
        title={I18n.t('general:feedback')}
        handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.TOOLS })}
      />
      <ColumnView minHeight={'calc(100% - 74px)'} alignItems={'flex-start'} justifyContent={'flex-start'}>
        <BlockTitle className="mb-32 text-md text-dark-gray">{I18n.t('general:message')}</BlockTitle>
        <List noHairlines className="w-full mb-20">
          <TextField
            label={I18n.t('general:feedbackPlaceholder')}
            onTextEditorChange={onFieldChange.bind(null, updateTransientProps, 'feedback_message')}
            outline={true}
            resizable
            type="texteditor"
            value={transient.feedback_message}
            textEditorParams={{
              buttons: []
            }}
          />
        </List>
        <List noHairlines className="w-full mb-20 mt-0">
          <ListItem
            name={'feedback_type'}
            radio
            radioIcon={'start'}
            title={I18n.t('general:bug')}
            onChange={updateTransientProps.bind(null, { feedback_type: 'Bug' })}
          />
          <ListItem
            name={'feedback_type'}
            radio
            radioIcon={'start'}
            title={I18n.t('general:enhancement')}
            onChange={updateTransientProps.bind(null, { feedback_type: 'Enhancement' })}
          />
        </List>
        <RowView gap={24} paddingHorizontal={20} paddingBottom={8} marginTop={'auto'}>
          <Button.Outline width={'100%'} onClick={NavigationService.goBack.bind(null, {})}>
            {I18n.t('general:cancel')}
          </Button.Outline>
          <Button.Primary
            width={'100%'}
            disabled={disableButton}
            onClick={handleSendFeedback.bind(null, {
              decodedFeedbackMessage,
              sendFeedback,
              transient,
              navigateToSettings
            })}>
            {I18n.t('general:submit')}
          </Button.Primary>
        </RowView>
      </ColumnView>
    </Page>
  );
};

Feedback.propTypes = {
  sendFeedback: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func
};

Feedback.defaultProps = {
  sendFeedback: mockFunction,
  transient: {},
  updateTransientProps: mockFunction
};

export default Feedback;
