import React from 'react';

import PropTypes from 'prop-types';

const Contact = ({ fillColor = '#1C1B1F' }) => (
  <svg width="24" height="24" fill="none">
    <mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" masktype="alpha">
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill={fillColor}
        // eslint-disable-next-line max-len
        d="M4.308 19.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.308ZM20 7.442l-7.514 4.81a1.18 1.18 0 0 1-.238.103.848.848 0 0 1-.496 0 1.18 1.18 0 0 1-.239-.103L4 7.442v10.25a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087h15.385a.3.3 0 0 0 .221-.087.3.3 0 0 0 .087-.22V7.442ZM12 11l7.846-5H4.154L12 11ZM4 7.673V6.53v.03-.032 1.145Z"
      />
    </g>
  </svg>
);

export default Contact;

Contact.propTypes = {
  fillColor: PropTypes.string
};
