import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Block, List, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { REGIONS } from 'Constants';
import { NavigationService } from 'Services';
import { Button, TextField } from 'Components';
import { RowView, ColumnView } from 'Containers';
import { capitalize, handleOnChangeWithValidations, mockFunction, validations } from 'Helpers';

const navigateToBirthday = () => {
  NavigationService.navigate({
    name: 'Birthday'
  });
};

const updateUserFullName = ({ first_name, last_name, locale, updateUser }) => {
  const newUser = {
    data: {
      attributes: {
        first_name,
        last_name,
        locale
      }
    }
  };
  updateUser(newUser, navigateToBirthday);
};

const FullName = ({ transient, updateTransientProps, updateTransientPropWithValidations, updateUser, user }) => {
  const { country, locale } = user;
  const { first_name_has_error, first_name = '', last_name_has_error, last_name = '' } = transient;

  const disabled = REGIONS.UK.includes(country);

  const disableButton =
    (!user.first_name && !user.last_name) || !first_name || !last_name || first_name_has_error || last_name_has_error;

  useEffect(() => {
    updateTransientProps({ first_name: user?.first_name, last_name: user?.last_name });
  }, [user?.first_name, user?.last_name]);

  return (
    <Page>
      <ColumnView height={'100%'}>
        <ColumnView className="align-center" marginTop={60}>
          <RowView justifyContent={'center'} paddingHorizontal={16}>
            <div className="swiper-title">{I18n.t('general:whatsYourName')}</div>
          </RowView>
          <RowView>
            <ColumnView>
              <List form className="w-full">
                <TextField
                  disabled={disabled}
                  label={I18n.t('inputs:firstName.label')}
                  type="text"
                  placeholder={I18n.t('inputs:firstName.placeholder')}
                  value={capitalize(first_name)}
                  onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'first_name', [
                    validations.isRequired,
                    validations.lettersOnly
                  ])}
                  errorMessageForce={transient?.first_name_has_error}
                  errorMessage={I18n.t(transient?.first_name_error_message_key)}
                  autofocus
                  validate
                  validateOnBlur
                />
                <TextField
                  disabled={disabled}
                  label={I18n.t('inputs:lastName.label')}
                  type="text"
                  placeholder={I18n.t('inputs:lastName.placeholder')}
                  value={capitalize(last_name)}
                  onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'last_name', [
                    validations.isRequired,
                    validations.lettersOnly
                  ])}
                  errorMessageForce={transient?.last_name_has_error}
                  errorMessage={I18n.t(transient?.last_name_error_message_key)}
                  validate
                  validateOnBlur
                />
              </List>
            </ColumnView>
          </RowView>
        </ColumnView>
        <ColumnView marginTop={'auto'} marginBottom={90}>
          <Block className="w-full">
            <Button.Primary
              width={'100%'}
              disabled={disableButton}
              onClick={updateUserFullName.bind(null, {
                first_name,
                last_name,
                locale,
                updateUser
              })}>
              {I18n.t('general:next')}
            </Button.Primary>
          </Block>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

FullName.propTypes = {
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object
};

FullName.defaultProps = {
  transient: {},
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction,
  updateUser: mockFunction,
  user: {}
};

export default FullName;
