import { connect } from 'react-redux';

import { Creators as checkInActions } from 'Reducers/checkIn';
import { Creators as postcodesActions } from 'Reducers/postCodes';
import { Creators as transientActions } from 'Reducers/transient';

import CheckInList from './view';

export default connect(
  state => ({
    postCodes: state.postCodes.list,
    transient: state.transient
  }),
  {
    checkIn: checkInActions.checkIn,
    updateTransientProps: transientActions.updateProps,
    setSelectedPostcodes: postcodesActions.updateProp
  }
)(CheckInList);
