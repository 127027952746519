import { connect } from 'react-redux';

import Reports from './view';

import * as Selectors from 'Selectors';
import { Creators as resultActions } from 'Reducers/result';
import { Creators as transientActions } from 'Reducers/transient';

export default connect(
  state => ({
    campaigns: Selectors.userCampaigns(state),
    isOffline: state.application.isOffline,
    offlineResults: Selectors.offlineFlows(state),
    recentResults: Selectors.recentResults(state),
    results: state.result,
    transient: state.transient,
    user: state.user
  }),
  {
    resendLinkToDonor: resultActions.resendLinkToDonor,
    reset: transientActions.reset,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations,
    uploadResultPicture: resultActions.uploadResultPicture
  }
)(Reports);
