import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { TEXT_FIELD_ICONS } from 'Constants';
import { LabelWarning, TextField } from 'Components';
import { getNestedValue, handleOnChangeWithValidations, phoneNumberSanitization, validations } from 'Helpers';
import {
  getDateRange,
  getFormattedValue,
  getIconColor,
  handleChangeBankAccount,
  isDisabled,
  renderSelectOptions,
  updateUserChanges
} from 'Pages/session/Profile/utils/helpers';

const onChangeHandlerMap = {
  handleChangeBankAccount: handleChangeBankAccount,
  handleOnChangeWithValidations: handleOnChangeWithValidations
};

const TextFieldComponent = ({
  bankAccountCheckFR,
  bankAccountCheckUK,
  config,
  isReadOnly,
  isRejectedField,
  transient,
  updateTransientProps,
  updateTransientPropWithValidations,
  updateUser,
  user
}) => {
  const validationFuncs = config.validations.map(v => validations[v]);
  const hasError = transient?.[`${config.fieldName}_has_error`] || false;
  const fieldValue = transient?.[config.fieldName];
  const userValue = user?.[config.fieldName];
  const isUK = config?.countrySpecificField?.some(field => field === 'UK');
  const bankAccountCheck = isUK ? bankAccountCheckUK : bankAccountCheckFR;
  const isFieldChanged =
    config.fieldName === 'account_number'
      ? userValue?.replace(/\s+/g, '') !== fieldValue?.replace(/\s+/g, '') && !hasError
      : userValue !== fieldValue && !hasError;

  const onChange = config.onChangeHandler
    ? onChangeHandlerMap[config.onChangeHandler].bind(null, bankAccountCheck, config.fieldName)
    : handleOnChangeWithValidations.bind(this, updateTransientPropWithValidations, config.fieldName, validationFuncs);

  return (
    <TextField
      key={config.fieldName}
      label={
        <LabelWarning
          label={I18n.t(config.label)}
          warning={isRejectedField(config.fieldName)}
          isRequired={config.validations.includes('isRequired')}
        />
      }
      disabled={isDisabled(config.isReadOnly, isReadOnly)}
      type={config.type}
      value={
        config.value ? getNestedValue(transient, config.value, '') : getFormattedValue(config, fieldValue, transient)
      }
      valueProperty={config.valueProperty ? getNestedValue(transient, config.fieldName, '') : undefined}
      placeholder={config.placeholder ? I18n.t(config.placeholder) : undefined}
      defaultValue={config.hasDefaultValue ? transient?.[config.fieldName] : undefined}
      className={config.className ? config.className : undefined}
      validate={true}
      onInput={config.sanitization && phoneNumberSanitization}
      validateOnBlur={true}
      onChange={onChange}
      onBlur={isFieldChanged ? updateUserChanges.bind(null, transient, updateTransientProps, updateUser) : undefined}
      errorMessageForce={hasError}
      errorMessage={I18n.t(transient?.[`${config.fieldName}_error_message_key`])}
      inputId={config.inputId ?? undefined}
      icon={config.hasIcon ? TEXT_FIELD_ICONS[config.icon] : undefined}
      iconColor={getIconColor(hasError, fieldValue)}
      max={config.type === 'date' ? getDateRange(config).max : undefined}
      min={config.type === 'date' ? getDateRange(config).min : undefined}>
      {config.type === 'select' &&
        config.fieldName === 'gender' &&
        renderSelectOptions(user?.gender_options || [], config.selectTranslationKey, config.fieldName)}
      {config.type === 'select' &&
        config.fieldName === 'company_registration_number_status' &&
        renderSelectOptions(user?.company_registration_number_status_options || [], config.selectTranslationKey)}
    </TextField>
  );
};

TextFieldComponent.propTypes = {
  bankAccountCheckFR: PropTypes.func,
  bankAccountCheckUK: PropTypes.func,
  config: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  isRejectedField: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object
};

export default TextFieldComponent;
