import React from 'react';
import PropTypes from 'prop-types';
import { BlockTitle } from 'framework7-react';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { ArrowRightIn } from 'Icons';
import { mockFunction } from 'Helpers';
import { Button, Card } from 'Components';
import { NavigationService } from 'Services';
import { COUNTRIES, COUNTRIES_ADDRESS, PAGE_NAMES, TABS } from 'Constants';

const handleCheckIn = ({
  checkIn,
  checkInObject,
  checkOut,
  getCampaigns,
  isCheckedIn,
  NavigationService,
  updateTransientProps,
  user
}) => {
  if (!user.tc_accepted) {
    updateTransientProps({ tc_modal: true });
  } else if (isCheckedIn) {
    checkOut(checkInObject.id);
    updateTransientProps({ processing: false });
  } else if (!COUNTRIES_ADDRESS.AUTOCOMPLETE.includes(user.country)) {
    checkIn();
  } else {
    getCampaigns();
    NavigationService.navigate({
      name: PAGE_NAMES.CHECKIN
    });
  }
};

export const Onboarded = ({
  campaign = {},
  checkIn = mockFunction,
  checkInObject = {},
  checkOut = mockFunction,
  getCampaigns = false,
  handleNavigate = mockFunction,
  hasValidBadge = false,
  isCheckedIn = false,
  isOffline = false,
  transient = {},
  updateApplicationProp = mockFunction,
  updateTransientProps = mockFunction,
  user = {}
}) => {
  const { active_badges = [] } = user;
  const { new_published_flow = false } = transient;

  const userHaveCampaigns = !!campaign[user.id]?.campaigns.length;
  const isOnboarded = userHaveCampaigns;
  const documentsCount = user?.needs_attention_documents?.length;
  const neededForCheckIn = !user.needed_for_check_in?.length && isOnboarded;
  const noPublishedFlows = !campaign[user.id]?.selectedCampaign?.published_flows?.length;
  const disabledCheckIn = !hasValidBadge || isOffline || noPublishedFlows || !!user.needed_for_check_in?.length;

  const getMessage = () => {
    if (isCheckedIn) {
      return user.country === COUNTRIES.UK
        ? I18n.t('campaign:checkInNoPostCode')
        : `${I18n.t('campaign:checkInMessage')} ${checkInObject.selected_postcodes}`;
    }

    if (isOffline) return I18n.t('campaign:checkInOfflineMessage');

    if (hasValidBadge)
      return `${I18n.t('campaign:cardMessage.notCheckedInMessage')} ${
        new_published_flow ? I18n.t('growl:success.updatedFlow.title') : ''
      }`;

    if (noPublishedFlows && !isOffline) return I18n.t('campaign:cardMessage.noPublishedFlow');

    if (user?.onboarding_profile?.state === 'profile_incomplete') return I18n.t('campaign:cardMessage.noBadgeAssigned');

    if (active_badges.length === 0 && !isOffline && user?.onboarding_profile?.state !== 'profile_in_progress')
      return I18n.t('campaign:allSet');

    return I18n.t('campaign:cardMessage.noBadgeAssigned');
  };

  const renderMessage = () => <div>{getMessage()}</div>;

  return (
    <>
      {!!documentsCount && isOnboarded && (
        <>
          <BlockTitle>{I18n.t('campaign:onboarding')}</BlockTitle>
          <div className="px-20">
            <Card description={I18n.t('campaign:cardMessage.neededForCheckIn')} />
            <div className="mt-16">
              <Card
                buttonLabel={I18n.t('campaign:documentsCard.open')}
                buttonPosition={'flex-end'}
                buttonVariant={'transparet'}
                buttonWidth={'auto'}
                className="documents-card"
                color={'red'}
                description={I18n.t('campaign:documentsCard.documentDescription', { count: documentsCount })}
                number={documentsCount}
                onClick={handleNavigate.bind(null, updateApplicationProp, TABS.DOCUMENTS)}
                title={I18n.t('campaign:documentsCard.documents')}
              />
            </div>
          </div>
        </>
      )}

      {((neededForCheckIn && campaign[user.id]?.campaigns?.length) || (!!documentsCount && isOnboarded)) && (
        <>
          <BlockTitle>{I18n.t('campaign:activity')}</BlockTitle>
          <div className="px-20 mb-90">
            <Card
              title={campaign[user?.id]?.selectedCampaign?.name}
              description={renderMessage()}
              onClick={handleCheckIn.bind(null, {
                checkIn,
                checkInObject,
                checkOut,
                getCampaigns,
                isCheckedIn,
                NavigationService,
                updateTransientProps,
                user
              })}
              buttonCardDisabled={disabledCheckIn || transient?.processing}
              {...(!isCheckedIn && { buttonLabel: I18n.t('campaign:checkIn') })}
              iconButtonLeft={<img src={ArrowRightIn} className="pb-2 pr-4" />}
              buttonWidth={'auto'}
            />
            {isCheckedIn && (
              <RowView justifyContent="flex-end" paddingTop={16}>
                <Button.OutlineSmall
                  width={'30%'}
                  disabled={isOffline}
                  processing={transient?.checkOutLoading}
                  onClick={handleCheckIn.bind(null, {
                    checkIn,
                    checkInObject,
                    checkOut,
                    isCheckedIn,
                    NavigationService,
                    updateTransientProps,
                    user
                  })}>
                  {I18n.t('campaign:checkOut')}
                </Button.OutlineSmall>
              </RowView>
            )}
          </div>
        </>
      )}
    </>
  );
};

Onboarded.propTypes = {
  campaign: PropTypes.object,
  checkIn: PropTypes.func,
  checkInObject: PropTypes.object,
  checkOut: PropTypes.func,
  flowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flowReady: PropTypes.bool,
  getCampaigns: PropTypes.func,
  getPublishedFlows: PropTypes.func,
  handleNavigate: PropTypes.func,
  handleVersionUpdateConsent: PropTypes.func,
  handleVersionUpdateModalClose: PropTypes.func,
  hasValidBadge: PropTypes.bool,
  isCheckedIn: PropTypes.bool,
  isOffline: PropTypes.bool,
  transient: PropTypes.object,
  updateApplicationProp: PropTypes.func,
  updateTransientProps: PropTypes.func,
  user: PropTypes.object
};

export default Onboarded;
