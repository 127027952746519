import React from 'react';
import PropTypes from 'prop-types';

import { AlertCircle } from 'Icons';
import { ColumnView } from 'Containers';
import { ALERT_COLORS } from 'Constants';

import './style.scss';

const Banner = ({ type, title, content }) => {
  const backgroundColor = ALERT_COLORS[type?.toUpperCase()] || '#FFFFFF';

  return (
    <div id="banner" style={{ backgroundColor }}>
      <div>
        <AlertCircle />
      </div>
      <ColumnView alignItems="flex-start">
        <div className="banner-title">{title}</div>
        <div className="banner-content">{content}</div>
      </ColumnView>
    </div>
  );
};

Banner.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default Banner;
