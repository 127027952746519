import PropTypes from 'prop-types';
import { getDevice } from 'framework7';
import React, { useEffect } from 'react';
import { Icon, Page, Popup } from 'framework7-react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { Button } from 'Components';
import { mockFunction } from 'Helpers';
import { ColumnView, RowView } from 'Containers';
import { DataByteIconLogo, FinanceGuy } from 'Icons';
import { NavigationService, PwaService } from 'Services';

import './style.scss';

const handleInstallClick = updateTransientProps => {
  PwaService.showPrompt();
  updateTransientProps({ install_modal: false });
};

const Finish = ({ transient = {}, updateTransientProps = mockFunction }) => {
  const device = getDevice();
  const { install_modal = false } = transient;

  const determineInstallModal = (userDevice, updateTransientProps) => {
    if (userDevice.android) {
      updateTransientProps({ install_modal: true });
    }
    if (userDevice.ios || userDevice.ipad) {
      alert(I18n.t('general:installIos'));
    }
  };

  useEffect(() => {
    determineInstallModal(device, updateTransientProps);
  }, []);

  return (
    <Page>
      <ColumnView height={'100%'} justifyContent={'center'} paddingHorizontal={20}>
        <ColumnView height={'70%'} paddingBottom={32}>
          <RowView>
            <ColumnView>
              <img src={FinanceGuy} className="mb-32" />
              <div className="swiper-title">{I18n.t('general:readyToRock')}</div>
              <div className="swiper-subtitle pt-24">{I18n.t('general:subtitleReadyToRock')}</div>
            </ColumnView>
          </RowView>
        </ColumnView>
        <RowView>
          <Button.Primary width={'100%'} onClick={NavigationService.clearURL}>
            {I18n.t('general:goToProfile')}
          </Button.Primary>
        </RowView>
      </ColumnView>
      <Popup
        opened={install_modal}
        onPopupClosed={updateTransientProps.bind(null, { install_modal: false })}
        id={'finish'}>
        <Page>
          <ColumnView justifyContent={'space-between'} height={'100%'}>
            <ColumnView gap={8} paddingVertical={8}>
              <RowView paddingHorizontal={8}>
                <RowView justifyContent="center" paddingLeft={22}>
                  <div className="text-md font-bold">{I18n.t('general:addToHomeScreen')}</div>
                </RowView>
                <RowView
                  justifyContent="flex-end"
                  width={16}
                  onClick={updateTransientProps.bind(null, { install_modal: false })}>
                  <Icon className="justify-end" md={'material:close'} />
                </RowView>
              </RowView>
              <RowView backgroundColor={colors.darkGrey} height={1} />
              <ColumnView gap={8} paddingVertical={8} onClick={handleInstallClick.bind(null, updateTransientProps)}>
                <img src={DataByteIconLogo} />
                <RowView>
                  <div className="text-sm font-light subtitle">{I18n.t('general:installDataByte')}</div>
                </RowView>
                <Icon f7={'arrow_down_to_line'} />
              </ColumnView>
            </ColumnView>
          </ColumnView>
        </Page>
      </Popup>
    </Page>
  );
};

Finish.propTypes = {
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func
};

export default Finish;
