import React from 'react';
import PropTypes from 'prop-types';

const Send = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="share-07">
      <path
        id="Icon"
        // eslint-disable-next-line max-len
        d="M8.18275 12.3213L14.159 15.8038M14.1503 6.19625L8.18275 9.67875M19.0415 4.875C19.0415 6.32475 17.8663 7.5 16.4165 7.5C14.9668 7.5 13.7915 6.32475 13.7915 4.875C13.7915 3.42525 14.9668 2.25 16.4165 2.25C17.8663 2.25 19.0415 3.42525 19.0415 4.875ZM8.5415 11C8.5415 12.4497 7.36625 13.625 5.9165 13.625C4.46676 13.625 3.2915 12.4497 3.2915 11C3.2915 9.55025 4.46676 8.375 5.9165 8.375C7.36625 8.375 8.5415 9.55025 8.5415 11ZM19.0415 17.125C19.0415 18.5747 17.8663 19.75 16.4165 19.75C14.9668 19.75 13.7915 18.5747 13.7915 17.125C13.7915 15.6753 14.9668 14.5 16.4165 14.5C17.8663 14.5 19.0415 15.6753 19.0415 17.125Z"
        stroke="#747474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

Send.propTypes = {
  fillColor: PropTypes.string
};

export default Send;
