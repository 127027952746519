import React from 'react';
import { ListItem } from 'framework7-react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { idFilter } from 'Helpers';
import { RowView } from 'Containers';

import './style.scss';
import { componentPropTypes } from '../../shared';

const checkValue = (choice, value, type) => {
  if (type === 'radio') {
    return choice == value;
  }

  return value.indexOf(choice) >= 0;
};

const CheckBoxRadio = props => {
  const { component_type, fields, referenced_products, required, shouldShowError, type, value } = props;

  const isRadio = type === 'radio';
  const isCheckBox = type === 'checkbox';
  const isProduct = component_type === 'product';
  let choices = fields[0].choices;

  if (component_type === 'product') {
    choices = referenced_products.map(rf => rf.id);
  }

  return (
    <div id="check-radio-box" className={shouldShowError ? 'input-error' : ''}>
      <RowView justifyContent={'flex-start'} paddingLeft={16}>
        <div className="item-title item-label">{props.name}</div>
        {required && <div className="text-fail text-md pl-4">*</div>}
      </RowView>

      {choices.map(renderChoices.bind(this, isCheckBox, isRadio, isProduct, referenced_products, value, type, props))}

      {shouldShowError && (
        <div className="error-message" style={{ color: colors.error }}>
          {I18n.t('flow:generalError')}
        </div>
      )}
    </div>
  );
};

const renderChoices = (isCheckBox, isRadio, isProduct, referenced_products, value, type, props, choice) => (
  <ListItem
    radio={isRadio}
    checkbox={isCheckBox}
    key={choice}
    title={!isProduct ? choice : referenced_products.filter(idFilter.bind(this, choice))[0].name}
    checked={checkValue(choice, value, type)}
    value={choice}
    onChange={update.bind(this, props, isProduct, referenced_products)}
  />
);

const update = (props, isProduct, referenced_products, e) => {
  const { updateValue, id, component_type: component, type, value, max_length } = props;
  const { value: targetValue } = e.target;
  const data = {
    value,
    id,
    component
  };

  if (type === 'checkbox') {
    if (!data.value) data.value = [];

    if (e.target.checked && (max_length === null || data.value.length + 1 <= max_length)) {
      data.value = [...data.value, targetValue];
    } else {
      data.value = data.value.filter(c => c !== targetValue);
      if (data.value.length === 0) {
        data.value = null;
      }
    }
  }

  if (type === 'radio') {
    data.value = targetValue;
  }

  if (isProduct) {
    data.isProduct = true;
    data.productValue = referenced_products.filter(idFilter.bind(this, parseInt(data.value)))[0].id;
  }

  updateValue(data);
};

CheckBoxRadio.propTypes = {
  ...componentPropTypes
};

export default CheckBoxRadio;
