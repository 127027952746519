import { connect } from 'react-redux';

import PostCodesList from './view';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';

export default connect(state => ({ ...state.transient, country: state.user.country }), {
  getPostCodes: userActions.getPostCodes,
  getUKAddress: userActions.getUKAddress,
  updateTransientProps: transientActions.updateProps,
  updateUser: userActions.updateApiModel
})(PostCodesList);
