import PropTypes from 'prop-types';
import { Gauge } from 'framework7-react';
import React, { useEffect, useState } from 'react';

import { StateActive, StateDisabled } from 'Icons';

import './style.scss';

const TabIcon = ({ icon: IconComponent, fillColor, percentage, hasGauge, checkMarkStatus }) => {
  const [gaugeValues, setGaugeValues] = useState({ finalPercentage: 0, color: '#1C1B1F' });

  useEffect(() => {
    const applyGaugeRules = percentage => {
      const rules = [
        { max: 25, color: '#E15A55', finalPercentage: 25 },
        { max: 50, color: '#F9BA00', finalPercentage: 50 },
        { max: 100, color: '#F9BA00', finalPercentage: 75 },
        { max: Infinity, color: '#2EA076', finalPercentage: 100 }
      ];

      const { color, finalPercentage } = rules.find(rule => percentage < rule.max);

      return { finalPercentage, color };
    };

    setGaugeValues(applyGaugeRules(percentage));
  }, [percentage]);

  const { finalPercentage, color } = gaugeValues;

  return (
    <div className={`tab-icon-container ${hasGauge ? 'showGauge' : 'showStatus'}`}>
      <IconComponent fillColor={fillColor} />
      {hasGauge ? (
        <Gauge
          type="circle"
          value={finalPercentage / 100}
          size={50}
          borderColor={color}
          borderWidth={3}
          valueTextColor="#2EA076"
          borderBgColor="#F6F6F6"
          customClass="custom-gauge-class"
        />
      ) : (
        <img src={checkMarkStatus === 'active' ? StateActive : StateDisabled} alt="Status Icon" />
      )}
    </div>
  );
};

TabIcon.propTypes = {
  icon: PropTypes.elementType.isRequired,
  fillColor: PropTypes.string,
  percentage: PropTypes.number,
  hasGauge: PropTypes.bool,
  checkMarkStatus: PropTypes.oneOf(['active', 'disabled'])
};

TabIcon.defaultProps = {
  fillColor: '#1C1B1F',
  percentage: 0,
  hasGauge: false,
  checkMarkStatus: 'disabled'
};

export default TabIcon;
