import React from 'react';
import { I18n } from 'Locales';
import PropTypes from 'prop-types';
import { Page, List, ListItem, Toggle } from 'framework7-react';

import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { NavigationService } from 'Services';
import { AppHeader, Button } from 'Components';
import { PAGE_NAMES, USER_LANGUAGES } from 'Constants';

import './style.scss';

const updateUserTestMode = ({ performTestResults, user }) => {
  performTestResults(user);
};

const updateUserLanguage = ({ getUserCountries, language, updateUser }) => {
  const newUser = { data: { attributes: { locale: language.value } } };
  updateUser(newUser, getUserCountries);
};

const renderLanguageButton = (user, updateUser, getUserCountries, language) => {
  const LanguageButton = user?.locale === language.value ? Button.Primary : Button.Outline;

  return (
    <LanguageButton
      smallMd
      key={language.value}
      onClick={updateUserLanguage.bind(null, { getUserCountries, language, updateUser })}>
      {I18n.t(`settings:${language.label.toLowerCase()}`)}
    </LanguageButton>
  );
};

const Settings = ({ getCountries, performTestResults, isOffline, updateUser, user }) => (
  <Page id="settings">
    <AppHeader
      user
      goBack
      title={I18n.t('general:settings')}
      handleClick={NavigationService.navigate.bind(null, {
        name: PAGE_NAMES.TOOLS
      })}
    />
    <List>
      <ListItem
        link
        title={I18n.t('settings:changePassword')}
        onClick={NavigationService.navigate.bind(null, {
          name: 'ChangePassword'
        })}
        className="settings-item"
      />
      <ListItem disabled={isOffline} title={I18n.t('settings:languagePreferences')} className="settings-item language">
        <RowView justifyContent={'flex-start'} gap={22}>
          {USER_LANGUAGES.LANGUAGES.map(renderLanguageButton.bind(null, user, updateUser, getCountries))}
        </RowView>
      </ListItem>
      <ListItem disabled={isOffline} title={I18n.t('settings:testMode')} className="settings-item">
        <Toggle checked={user.test_mode} onChange={updateUserTestMode.bind(null, { performTestResults, user })} />
      </ListItem>
    </List>
  </Page>
);

Settings.propTypes = {
  getCountries: PropTypes.func,
  isOffline: PropTypes.bool,
  performTestResults: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object
};

Settings.defaultProps = {
  getCountries: mockFunction,
  isOffline: false,
  performTestResults: mockFunction,
  updateUser: mockFunction,
  user: {}
};

export default Settings;
