import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Link, Toolbar as ToolbarF7 } from 'framework7-react';

import { I18n } from 'Locales';
import { Refresh } from 'Icons';
import { PAGE_NAMES } from 'Constants';
import { mockFunction } from 'Helpers';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';

const handleClick = ({ checkIn, setSelectedPostcodes, transient }) => {
  setSelectedPostcodes('selectedPostcodes', transient.selectedPostcodes);
  NavigationService.navigate({
    name: PAGE_NAMES.CAMPAIGNS
  });
  checkIn();
};

const CheckInBottomBar = ({ checkIn, setSelectedPostcodes, transient, updateTransientProps }) => {
  const { selectedPostcodes = [] } = transient;

  return (
    <ToolbarF7 position={'bottom'} tabbar noShadow noHairLine scrollable id={'bottom-bar'}>
      <RowView gap={130}>
        <ColumnView height={74}>
          <Link onClick={updateTransientProps.bind(null, { selectedPostcodes: [] })}>
            <img src={Refresh} />
            <div className="color-gray mt-2">{I18n.t('general:clear')}</div>
          </Link>
        </ColumnView>
        <ColumnView height={74}>
          <Link
            className={!selectedPostcodes?.length && 'disabled'}
            onClick={
              selectedPostcodes?.length
                ? handleClick.bind(null, { checkIn, setSelectedPostcodes, transient })
                : mockFunction
            }>
            <Icon
              slot="media"
              md={'material:check'}
              className={selectedPostcodes?.length ? 'color-blue' : 'color-gray'}
            />
            <div className={selectedPostcodes?.length ? 'color-blue' : 'color-gray'}>{I18n.t('general:finish')}</div>
          </Link>
        </ColumnView>
      </RowView>
    </ToolbarF7>
  );
};

CheckInBottomBar.propTypes = {
  checkIn: PropTypes.func,
  setSelectedPostcodes: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func
};

CheckInBottomBar.defaultProps = {
  checkIn: mockFunction,
  setSelectedPostcodes: mockFunction,
  transient: {},
  updateTransientProps: mockFunction
};

export default CheckInBottomBar;
