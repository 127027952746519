import { connect } from 'react-redux';

import * as Selectors from 'Selectors';

import { Creators as applicationActions } from 'Reducers/application';

import Tools from './view';

export default connect(
  state => ({
    isOffline: state.application.isOffline,
    offlineResults: Selectors.offlineFlows(state)
  }),
  {
    logout: applicationActions.logout
  }
)(Tools);
