import moment from 'moment';
import { createActions, createReducer } from 'reduxsauce';

import { setInitial, updateProp } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    addMediaUrlForOfflineResult: ['userId', 'data'],
    addOfflineResult: ['userId', 'offlineResult'],
    deleteOfflinePictures: ['userId', 'offlinePictureObject'],
    deleteDuplicatedOfflineResult: ['userId', 'sessionId'],
    get: ['callback'],
    getError: null,

    getResultsSuccess: ['payload', 'callback'],
    performTestResults: ['user'],
    performTestResultsFail: ['payload'],
    performTestResultsSuccess: null,
    removeUnsplicedOfflineResults: ['userId'],
    resendLinkToDonor: ['data', 'id'],
    resendLinkToDonorFail: ['error'],
    resendLinkToDonorSuccess: null,
    set: ['payload', 'userId'],
    spliceOfflineFlowById: ['userId', 'offlineFlow'],
    updateProp: ['key', 'value'],
    uploadOfflineResultPictureSuccess: ['data'],
    uploadResultPicture: ['data'],
    uploadResultPictureFail: ['payload'],
    uploadResultPictureSuccess: ['data']
  },
  { prefix: 'result/' }
);

const initialState = {};

const initialUserState = {
  results: [],
  offline: [],
  offlinePictures: []
};

export const offlineResultsImages = state => state.result[state.user.id]?.offlinePictures;
export const offlineResults = state => {
  const userId = Object.keys(state?.result).map(result => result);
  return state?.result[userId]?.offline;
};

const filterByDate = result => ({
  ...result,
  today: moment(result.submitted_at).isSame(moment(), 'day'),
  thisWeek: moment(result.submitted_at).isSame(moment(), 'isoWeek'),
  lastWeek: moment(result.submitted_at).isSame(moment().subtract(1, 'week'), 'isoWeek')
});

export const addOfflineResult = (state, { userId, offlineResult }) => {
  let newState = { ...state };

  if (!newState[userId]?.offline) {
    newState = setInitial(state, userId, initialUserState);
  }

  newState[userId]['offline'] = [...newState[userId]['offline'], offlineResult].map(filterByDate);

  return { ...newState };
};

export const set = (state, { userId, payload }) => {
  const newState = setInitial(state, userId, initialUserState);

  if (state[userId]?.offline?.length) newState[userId]['offline'] = state[userId]?.offline?.map(filterByDate);
  newState[userId]['results'] = payload?.recent_results?.map(filterByDate);
  return { ...newState };
};

export const spliceOfflineFlowById = (state, { userId, offlineFlow }) => {
  const newArray = state[userId].offline.filter(item => item.id !== offlineFlow.id);

  return {
    ...state,
    [userId]: {
      ...state[userId],
      offline: [...newArray]
    }
  };
};

export const removeUnsplicedOfflineResults = (state, { userId }) => {
  const submittedOfflineIds = state[userId].results.map(result => result.offline_id);
  const newArray = state[userId].offline.filter(result => !submittedOfflineIds.includes(result.offline_id));

  return {
    ...state,
    [userId]: {
      ...state[userId],
      offline: [...newArray]
    }
  };
};

export const addMediaUrlForOfflineResult = (state, { userId, data }) => {
  const { id, file } = data;
  const offlinePictures = state[userId].offlinePictures;

  const key = data.id?.includes(userId) ? 'offline_id' : 'result_id';
  const offlinePictureObject = { [key]: data.id, file };

  const existingIndex = offlinePictures.findIndex(item => item.result_id === id || item.offline_id === id);

  const newState = { ...state };

  if (existingIndex > -1) {
    offlinePictures[existingIndex].file = file;
  } else {
    newState[userId].offlinePictures = [...offlinePictures, offlinePictureObject];
  }

  return newState;
};

export const deleteOfflinePictures = (state, { userId, offlinePictureObject }) => {
  const newState = { ...state };
  const { id } = offlinePictureObject;

  newState[userId].offlinePictures = newState[userId].offlinePictures.filter(
    picture => picture?.offline_id !== id && picture?.result_id !== id
  );

  return newState;
};

export const deleteDuplicatedOfflineResult = (state, { userId, sessionId }) => {
  const newState = { ...state };

  newState[userId].offline = newState[userId].offline?.filter(result => result.session_id !== sessionId);

  return newState;
};

export default createReducer(initialState, {
  [Types.ADD_MEDIA_URL_FOR_OFFLINE_RESULT]: addMediaUrlForOfflineResult,
  [Types.ADD_OFFLINE_RESULT]: addOfflineResult,
  [Types.DELETE_DUPLICATED_OFFLINE_RESULT]: deleteDuplicatedOfflineResult,
  [Types.DELETE_OFFLINE_PICTURES]: deleteOfflinePictures,
  [Types.REMOVE_UNSPLICED_OFFLINE_RESULTS]: removeUnsplicedOfflineResults,
  [Types.SET]: set,
  [Types.SPLICE_OFFLINE_FLOW_BY_ID]: spliceOfflineFlowById,
  [Types.UPDATE_PROP]: updateProp
});
